<template>

  <div class="fixed z-40 inset-0 overflow-y-auto">
    <div class="flex items-center justify-center min-h-screen pt-4 px-4 pb-20 text-center sm:block sm:p-0">

      <div class="fixed inset-0 transition-opacity z-40">
        <div @click="$emit('close')"
            class="absolute inset-0 bg-gray-500 opacity-75 z-40"></div>
      </div>

      <!-- This element is to trick the browser into centering the modal contents. -->
      <span class="hidden sm:inline-block sm:align-middle sm:h-screen"></span>
      <div
          class="inline-block align-bottom bg-blueGray-100 rounded-lg text-left overflow-hidden shadow-xl transform transition-all sm:align-middle relative z-50"
          role="dialog" aria-modal="true" aria-labelledby="modal-headline">

        <!-- Heading -->
        <div class="border-b px-4 py-2 flex justify-between items-center">
          <h3 class="font-semibold text-lg">
            <slot name="title"></slot>
          </h3>
          <button class="text-black close-modal" @click="$emit('close')">
            <svg class="h-6 w-6" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke="currentColor">
              <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M6 18L18 6M6 6l12 12" />
            </svg>
          </button>
        </div>

        <!-- Body -->
        <div class="bg-white px-4 pt-5 pb-4 sm:p-6 sm:pb-4">
          <slot name="content"></slot>
        </div>

        <!-- Footer -->
        <div class="bg-blueGray-100 px-4 py-3 sm:px-6 sm:flex sm:flex-row-reverse">
        <span class="flex w-full rounded-md shadow-sm sm:ml-3 sm:w-auto">
          <button type="button"
                  @click="$emit('action')"
                  class="inline-flex font-semibold justify-center w-full rounded-md border border-transparent px-4 py-2 bg-blue-600 text-base leading-6 font-medium text-white shadow-sm hover:bg-blue-500 transition ease-in-out duration-150 sm:text-sm sm:leading-5">
            <slot name="action"></slot>
          </button>
        </span>
          <span class="mt-3 flex w-full sm:mt-0 sm:w-auto">
          <button type="button"
                  @click="$emit('close')"
                  class="inline-flex justify-center w-full px-4 py-2 bg-blueGray-100 text-base leading-6 font-medium text-gray-700 hover:text-gray-500 sm:text-sm sm:leading-5">
            Close
          </button>
        </span>
        </div>
      </div>
    </div>
  </div>

</template>

<script>
export default {
  name: "Modal",
  props: ['showModal'],
}
</script>

<style scoped>

</style>
