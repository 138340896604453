<template>
  <div class="">
    <h2 class="block font-medium text-gray-700">{{ title }}</h2>
    <div class="-mr-6">
      <GChart
        type="ScatterChart"
        :data="chartData"
        :options="chartOptions"
        :resizeDebounce="1"
      ></GChart>
    </div>
  </div>
</template>

<script>
import { GChart } from "vue-google-charts";

export default {
  name: "ScatterChart",
  components: {
    GChart
  },
  props: {
    title: String,
    chartData: Array,
  },
  data() {
    return {
      chartOptions: {
        hAxis: {title: 'Sale Value', minValue: 0, maxValue: 15},
        vAxis: {title: 'Refund Value', minValue: 0, maxValue: 15},
        chart: {
        },
        chartArea: {
          height: "auto"
        },
        legend: "none"
      }
    };
  }
};
</script>

<style scoped></style>
