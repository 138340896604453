<template>
  <div>
    <h2 class="text-lg font-semibold mb-2">Filters</h2>
    <div class="col-span-12 grid grid-cols-5 gap-6">
      <div class="col-span-1">
        <label for="" class="mb-1 block text-sm font-medium text-gray-700"
        >Store</label
        >
        <v-select :options="sites" :value="dashFilter.site" @input="updateSite"></v-select>
      </div>
      <div class="col-span-1">
        <label for="" class="mb-1 block text-sm font-medium text-gray-700"
        >Team</label
        >
        <v-select :options="teams" :value="dashFilter.team" @input="updateTeam"></v-select>
      </div>
      <div class="col-span-1">
        <label for="" class="mb-1 block text-sm font-medium text-gray-700"
        >Employee</label
        >
        <v-select :options="agents" :value="dashFilter.agent" @input="updateAgent"></v-select>
      </div>
      <div class="col-span-1">
        <label for="" class="mb-1 block text-sm font-medium text-gray-700"
        >Date From</label>
        <input type="date" value="2020-10-26" class="p-1 block w-full border border-blueGray-300 rounded-md bg-blueGray-100 focus:outline-none"/>
      </div>
      <div class="col-span-1">
        <label for="" class="mb-1 block text-sm font-medium text-gray-700"
        >Date To</label>
        <input type="date" value="2020-12-28" class="p-1 block w-full border border-blueGray-300 rounded-md bg-blueGray-100 focus:outline-none"/>
      </div>
    </div>
  </div>
</template>

<script>
import {mapGetters, mapState} from "vuex";

export default {
  name: "Filters",
  methods: {
    updateSite(val) {
      this.$store.commit('SET_SITE', val)
      this.$store.commit('SET_TEAM', null)
      this.$store.commit('SET_AGENT', null)
    },
    updateTeam(val) {
      this.$store.commit('SET_TEAM', val)
      this.$store.commit('SET_AGENT', null)
    },
    updateAgent(val) {
      this.$store.commit('SET_AGENT', val)
    },
  },
  computed: {
    ...
        mapState(['dashFilter']),
    ...
        mapGetters(['sites', 'teams', 'agents']),

  }
}
</script>

<style scoped>

</style>
