<template>
  <div class="">
    <h2 class="block font-medium text-gray-700">{{ title }}</h2>
    <div class="-mr-6">
      <GChart
        type="ColumnChart"
        :data="chartData"
        :options="chartOptions"
        :resizeDebounce="1"
      ></GChart>
    </div>
  </div>
</template>

<script>
import { GChart } from "vue-google-charts";

export default {
  name: "BarChart",
  components: {
    GChart
  },
  props: {
    title: String,
    chartData: Array,
  },
  data() {
    return {
      // Array will be automatically processed with visualization.arrayToDataTable function
      chartOptions: {
        chart: {
          width: "100%"
        },
        vAxis: {baseline: 0},
        legend: "none",
        chartArea: { width: "75%"}
      }
    };
  }
};
</script>

<style scoped></style>
