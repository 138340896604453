import Vue from "vue";
import VueRouter from "vue-router";
import Dashboard from "@/views/Dashboard";
import Incidents from "@/views/Incident/Incidents";
import CaseManagementIndex from "@/views/CaseManagement/CaseManagementIndex";
import CaseManagementShow from "@/views/CaseManagement/CaseManagementShow";
import TransactionReport from "@/views/TransactionReport";

Vue.use(VueRouter);

const routes = [
    {
        path: "/",
        name: "Dashboard",
        component: Dashboard,
        meta: {
            title: "Performance Management - Loss Prevention",
            breadcrumb: 'Performance Management'
        }
    },
    {
        path: "/incidents",
        name: "Incidents",
        component: Incidents,
        meta: {
            title: "Incidents  - Loss Prevention",
            breadcrumb: {
                label: 'Incidents',
                parent: 'Dashboard'
            }
        }
    },
    {
        path: "/case-management",
        name: "CaseManagementIndex",
        component: CaseManagementIndex,
        meta: {
            title: "Case Management - Loss Prevention",
            breadcrumb: {
                label: 'Case Management',
                parent: 'Dashboard'
            }
        }
    },
    {
        path: "/case-management/:index",
        name: "CaseManagementShow",
        component: CaseManagementShow,
        meta: {
            title: "Case File - Loss Prevention",
           breadcrumb(routeParams) {
                return {
                    label: 'Case ' + routeParams.index,
                    parent: 'CaseManagementIndex'
                };
            }
        }
    },
    {
        path: "/transaction-report",
        name: "TransactionReport",
        component: TransactionReport,
        meta: {
            title: "Case File - Transaction Report",
            breadcrumb: {
                label: 'Transaction Reports',
                parent: 'Dashboard'
            }
        }
    },

];




const router = new VueRouter({
    routes
});

router.beforeEach((toRoute, fromRoute, next) => {
    window.document.title =
        toRoute.meta && toRoute.meta.title ? toRoute.meta.title : "Loss Prevention";

    next();
});

export default router;
