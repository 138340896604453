<template>
  <Modal @close="$emit('close')" @action="saveInvestigation(caseForReview)"
         v-if="showCreateInvestigationModal">
    <template #title>
      Create a case for review
    </template>
    <template #content>
      <div class="min-w-5xl max-w-5xl">
        <div class="grid grid-rows-2 grid-flow-col gap-6 mb-12">
          <div class="col-span-3">
            <label class="block text-sm font-medium text-gray-700">Case Owner</label>
            <input type="text" value="Paul Banks"
                   class="shadow-sm mt-1 block w-full sm:text-sm border border-gray-300 rounded-md"/>
          </div>
          <div class="col-span-3">
            <label class="mb-1 block text-sm font-medium text-gray-700">Case Investigator</label>
            <v-select :options="caseInvestigatorLevels" :value="caseForReview.caseInvestigatorLevel"
                      @input="updateCaseForReviewInvestigatorLevel"></v-select>
          </div>
          <div class="col-span-3">
            <label class="mb-1 block text-sm font-medium text-gray-700">Date & Time</label>
            <input type="datetime-local" :value="caseForReview.dateTime" @input="updateCaseForReviewDateTime"
                   class="shadow-sm mt-1 block w-full sm:text-sm border border-gray-300 rounded-md"/>
          </div>
          <div class="col-span-3">
            <label class="mb-1 block text-sm font-medium text-gray-700">Investigation Type</label>
            <v-select :options="investigationTypes" :value="caseForReview.investigationType"
                      @input="updateCaseForReviewInvestigationType"></v-select>
          </div>
          <div class="col-span-3">
            <label class="block text-sm font-medium text-gray-700">
              Response Due
            </label>
            <input type="date" :value="caseForReview.responseDueDate" @input="updateCaseForReviewDueDate"
                   class="shadow-sm mt-1 block w-full sm:text-sm border border-gray-300 rounded-md"/>
          </div>
          <div class="col-span-3">
            <label class="block text-sm font-medium text-gray-700 mb-1">
              Status
            </label>
            <v-select :options="OpenStatuses" :value="caseForReview.status"
                      @input="updateCaseForReviewStatus"></v-select>
          </div>
          <div class="col-span-3">
            <label class="block text-sm font-medium text-gray-700">
              Follow Up
            </label>
            <input type="date" :value="caseForReview.followUpDate" @input="updateCaseForReviewFollowUpDate"
                   class="shadow-sm mt-1 block w-full sm:text-sm border border-gray-300 rounded-md"/>
          </div>
          <div class="col-span-3">
            <label class="block text-sm font-medium text-gray-700">
              View Tx
            </label>
            <button class="mt-1 inline-flex font-semibold justify-center w-full rounded-md border border-transparent px-4 py-2 bg-blue-600 text-base leading-6 font-medium text-white shadow-sm hover:bg-blue-500 transition ease-in-out duration-150 sm:text-sm sm:leading-5"
                    @click="updateShowTransactionModal(true)">
              View Tx
            </button>
          </div>
        </div>
        <div class="shadow border-b border-gray-200 sm:rounded-lg col-span-12 mb-12">
          <table class="lp-table">
            <thead class="">
            <tr>
              <th scope="col">Date</th>
              <th scope="col">Region</th>
              <th scope="col">Area</th>
              <th scope="col">Store</th>
              <th scope="col">Employee</th>
              <th scope="col">TX Type</th>
              <th scope="col">Receipt Number</th>
              <th scope="col">Value</th>
              <th scope="col">Probability</th>
              <th scope="col">Proposed Action</th>
            </tr>
            </thead>
            <tbody>
            <tr>
              <td>{{ caseForReview.incident.date }}</td>
              <td>{{ caseForReview.incident.region }}</td>
              <td>{{ caseForReview.incident.area }}</td>
              <td>{{ caseForReview.incident.store }}</td>
              <td>{{ caseForReview.incident.employee }}</td>
              <td>{{ caseForReview.incident.txType }}</td>
              <td>{{ caseForReview.incident.receiptNumber }}</td>
              <td>£{{ caseForReview.incident.value / 100 }}</td>
              <td class="text-center">
              <span :class="ProbabilityLevel(caseForReview.incident.probability)"
                    class="px-3 py-1 inline-flex text-xs leading-5 font-semibold rounded-full bg-red-100 text-red-800">
                  {{ caseForReview.incident.probability * 100 }}%
                </span>
              </td>
              <td>{{ caseForReview.incident.proposedAction }}</td>
            </tr>
            </tbody>
          </table>
        </div>
        <div class="grid grid-cols-12 col-span-12 gap-6 mb-6">
          <div class="col-span-8">
            <label class="block text-sm font-medium text-gray-700">Case Notes</label>
            <textarea rows=9 :value="caseForReview.notes[0].note" @input="updateCaseForReviewNote"
                      class="shadow-sm mt-1 block w-full sm:text-sm border border-gray-300 rounded-md"></textarea>
          </div>
          <div class="col-span-4">
            <label class="block text-sm font-medium text-gray-700">Compliance Notes</label>
            <div class="h-48 shadow-sm mt-1 block border border-gray-300 rounded-md col-span-4 p-4">
              <p class="text-base font-semibold text-center m-0">
                Allows the system to recommend evidence required based on type of transaction and selected
                investigation type, based both on HR advice / Case history
                (Dynamic text, not editable)
              </p>
            </div>
          </div>
        </div>
      </div>
    </template>
    <template #action>
      Create
    </template>
  </Modal>
</template>

<script>
import Modal from "@/components/Modal";
import {mapState} from "vuex";

export default {
  name: "IncidentCreate",
  components: {
    Modal,
  },
  props: [
    'showCreateInvestigationModal'
  ],
  methods: {
    updateShowTransactionModal(val) {
      this.$store.commit('SET_SHOW_TRANSACTION_MODAL', val)
    },
    saveInvestigation(caseForReview) {
      this.$store.dispatch('saveInvestigation', caseForReview)
      this.$emit('close')
    },
    ProbabilityLevel(score) {
      if (score >= 0.9) {
        return "bg-red-200 text-red-900"
      }
      if (score >= 0.6) {
        return "bg-orange-200 text-orange-900"
      }
      if (score >= 0.4) {
        return "bg-yellow-200 text-yellow-900"
      }
      return "";
    },
    updateCaseForReviewDateTime(el) {
      this.$store.commit('SET_CASE_FOR_REVIEW_DATE_TIME', el.target.value)
    },
    updateCaseForReviewInvestigationType(val) {
      this.$store.commit('SET_CASE_FOR_REVIEW_INVESTIGATION_TYPE', val)
    },
    updateCaseForReviewInvestigatorLevel(val) {
      this.$store.commit('SET_CASE_FOR_REVIEW_INVESTIGATOR_LEVEL', val)
    },
    updateCaseForReviewStatus(val) {
      this.$store.commit('SET_CASE_FOR_REVIEW_STATUS', val)
    },
    updateCaseForReviewDueDate(el) {
      this.$store.commit('SET_CASE_FOR_REVIEW_REVIEW_DATE', el.target.value)
    },
    updateCaseForReviewFollowUpDate(el) {
      this.$store.commit('SET_CASE_FOR_REVIEW_FOLLOW_UP_DATE', el.target.value)
    },
    updateCaseForReviewNote(el) {
      let note = {
        author: "Paul Banks",
        dateTime: this.dateTimeNow,
        note: el.target.value,
      };

      this.$store.commit('SET_CASE_FOR_REVIEW_NOTE', note)

    }
  },
  computed: {
    ...mapState(['caseForReview', 'investigationTypes', 'caseInvestigatorLevels', 'statuses'])
  },
  data() {
    return {
      dateTimeNow: new Date().toISOString(),
      note: '',
      OpenStatuses: [
        'Open',
        'Awaiting Evidence',
        'Awaiting Update'
      ],
    }
  },
}
</script>

<style scoped>

</style>
