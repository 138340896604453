import Vue from "vue";
import Vuex from "vuex";
import _ from "lodash";

Vue.use(Vuex);

export default new Vuex.Store({
    state: {
        investigationTypes: ["Prosecution", "Formal", "Informal"],
        caseInvestigatorLevels: ["LP Manager", "Store Manager", "Line Manager"],
        showTransactionModal: false,
        statuses: [
            'Open',
            'Awaiting Evidence',
            'Awaiting Update',
            'Closed Success: Prosecution',
            'Closed Success: Formal Reprimand',
            'Closed Success: Informal Reprimand',
            'Closed Success: Informal Conversation',
            'Closed Failed: Insufficient Evidence',
            'Closed Failed: Inaccurate allegation',
            'Closed Failed: Disproved',
            'Closed Failed: Failure to follow procedure',
            'Closed Failed: Other',

        ],
        dashFilter: {
            site: null,
            team: null,
            agent: null,
            dateFrom: null,
            dateTo: null
        },
        caseFiles: [
            {
                incident: {
                    id: 1,
                    date: "01/12/2020",
                    region: "North East",
                    area: 1,
                    store: 1233,
                    employee: 4752,
                    txType: "Refund",
                    receiptNumber: 1,
                    value: 67300,
                    probability: 0.81,
                    proposedAction: "Investigate",
                    actionProbability: 0.75
                },
                id: 1,
                caseOwner: "Paul Banks",
                caseInvestigatorLevel: "LP Manager",
                dateTime: "2020-12-10T13:00",
                investigationType: "Prosecution",
                notes: [
                    {
                        author: "Paul Banks",
                        dateTime: "2020-12-10T13:12",
                        note: "Lorem ipsum dolor sit amet, consectetur adipiscing elit. Quisque tempus, mi non posuere pulvinar, leo nisl tincidunt justo, in accumsan sem nunc sit amet urna. Etiam tincidunt pellentesque varius. Curabitur euismod blandit dapibus. Donec dapibus auctor arcu. Morbi ut purus eget neque euismod elementum. Ut scelerisque vitae dui et efficitur. Morbi ac luctus erat, in tincidunt ex. Vestibulum orci est, suscipit id justo ac, sodales commodo orci.",
                    },
                    {
                        author: "Paul Banks",
                        dateTime: "2020-12-11T10:34",
                        note: "Donec gravida sodales sem, ac egestas augue vehicula in. Mauris laoreet sodales mi, sed varius est laoreet vitae. Ut fermentum lorem et vestibulum volutpat. Duis augue mauris, aliquet sed condimentum sit amet, malesuada eget velit. Pellentesque vehicula laoreet massa id gravida."
                    },
                    {
                        author: "Paul Banks",
                        dateTime: "2020-12-11T11:01",
                        note: "Nullam sagittis lorem sed turpis iaculis, vel bibendum metus posuere. Fusce bibendum, nibh scelerisque vulputate luctus, augue augue mattis dui, eu porta neque lectus sed sem. Pellentesque habitant morbi tristique senectus et netus et malesuada fames ac turpis egestas. Aenean eget orci euismod, eleifend quam ut, bibendum dolor."
                    },
                ],
                responseDueDate: "2020-12-17",
                followUpDate: "2020-12-17",
                status: "Open",
                processManagement: [
                    {
                        title: 'Review Footage',
                        checked: true,
                        note: 'Incident caught on footage'
                    }, {
                        title: 'Review Transactions',
                        checked: true,
                        note: 'Confirmed Transaction'
                    }, {
                        title: 'Fill out Investigation Pack',
                        checked: true,
                        note: ''
                    }, {
                        title: 'Collect Statements (Witnesses)',
                        checked: false,
                        note: 'In progress'
                    }, {
                        title: 'Collect Statement (Accused)',
                        checked: false,
                        note: ''
                    }, {
                        title: 'Obtain Other Evidence',
                        checked: false,
                        note: ''
                    }, {
                        title: 'Obtain CCTV',
                        checked: true,
                        note: ''
                    }, {
                        title: 'Formal Investigation',
                        checked: false,
                        note: ''
                    }, {
                        title: 'Contact Police',
                        checked: false,
                        note: ''
                    }, {
                        title: 'Security Policies',
                        checked: false,
                        note: ''
                    },
                ],
            },
            {
                incident: {
                    id: 2,
                    date: "02/12/2020",
                    region: "Midlands",
                    area: 1,
                    store: 1468,
                    employee: 8855,
                    txType: "Line Void",
                    receiptNumber: 2,
                    value: 55500,
                    probability: 0.89,
                    proposedAction: "Investigate",
                    actionProbability: 0.82
                },
                id: 2,
                caseOwner: "Paul Banks",
                caseInvestigatorLevel: "LP Manager",
                dateTime: "2020-12-10T13:00",
                investigationType: "Prosecution",
                notes: [
                    {
                        author: "Paul Banks",
                        dateTime: "2020-12-10T13:12",
                        note: "Lorem ipsum dolor sit amet, consectetur adipiscing elit. Quisque tempus, mi non posuere pulvinar, leo nisl tincidunt justo, in accumsan sem nunc sit amet urna. Etiam tincidunt pellentesque varius. Curabitur euismod blandit dapibus. Donec dapibus auctor arcu. Morbi ut purus eget neque euismod elementum. Ut scelerisque vitae dui et efficitur. Morbi ac luctus erat, in tincidunt ex. Vestibulum orci est, suscipit id justo ac, sodales commodo orci.",
                    },
                    {
                        author: "Paul Banks",
                        dateTime: "2020-12-11T10:34",
                        note: "Donec gravida sodales sem, ac egestas augue vehicula in. Mauris laoreet sodales mi, sed varius est laoreet vitae. Ut fermentum lorem et vestibulum volutpat. Duis augue mauris, aliquet sed condimentum sit amet, malesuada eget velit. Pellentesque vehicula laoreet massa id gravida."
                    },
                    {
                        author: "Paul Banks",
                        dateTime: "2020-12-11T11:01",
                        note: "Nullam sagittis lorem sed turpis iaculis, vel bibendum metus posuere. Fusce bibendum, nibh scelerisque vulputate luctus, augue augue mattis dui, eu porta neque lectus sed sem. Pellentesque habitant morbi tristique senectus et netus et malesuada fames ac turpis egestas. Aenean eget orci euismod, eleifend quam ut, bibendum dolor."
                    },
                ],
                responseDueDate: "2020-12-17",
                followUpDate: "2020-12-17",
                status: "Open",
                processManagement: [
                    {
                        title: 'Review Footage',
                        checked: false,
                        note: ''
                    }, {
                        title: 'Review Transactions',
                        checked: false,
                        note: ''
                    }, {
                        title: 'Fill out Investigation Pack',
                        checked: false,
                        note: ''
                    }, {
                        title: 'Collect Statements (Witnesses)',
                        checked: false,
                        note: ''
                    }, {
                        title: 'Collect Statement (Accused)',
                        checked: false,
                        note: ''
                    }, {
                        title: 'Obtain Other Evidence',
                        checked: false,
                        note: ''
                    }, {
                        title: 'Obtain CCTV',
                        checked: false,
                        note: ''
                    }, {
                        title: 'Formal Investigation',
                        checked: false,
                        note: ''
                    }, {
                        title: 'Contact Police',
                        checked: false,
                        note: ''
                    }, {
                        title: 'Security Policies',
                        checked: false,
                        note: ''
                    },
                ],
            },
            {
                incident: {
                    id: 3,
                    date: "03/12/2020",
                    region: "South West",
                    area: 1,
                    store: 2259,
                    employee: 4807,
                    txType: "Receipt Void",
                    receiptNumber: 3,
                    value: 39300,
                    probability: 0.71,
                    proposedAction: "Informal",
                    actionProbability: 0.79
                },
                id: 3,
                caseOwner: "Paul Banks",
                caseInvestigatorLevel: "LP Manager",
                dateTime: "2020-12-10T13:00",
                investigationType: "Prosecution",
                notes: [
                    {
                        author: "Paul Banks",
                        dateTime: "2020-12-10T13:12",
                        note: "Lorem ipsum dolor sit amet, consectetur adipiscing elit. Quisque tempus, mi non posuere pulvinar, leo nisl tincidunt justo, in accumsan sem nunc sit amet urna. Etiam tincidunt pellentesque varius. Curabitur euismod blandit dapibus. Donec dapibus auctor arcu. Morbi ut purus eget neque euismod elementum. Ut scelerisque vitae dui et efficitur. Morbi ac luctus erat, in tincidunt ex. Vestibulum orci est, suscipit id justo ac, sodales commodo orci.",
                    },
                    {
                        author: "Paul Banks",
                        dateTime: "2020-12-11T10:34",
                        note: "Donec gravida sodales sem, ac egestas augue vehicula in. Mauris laoreet sodales mi, sed varius est laoreet vitae. Ut fermentum lorem et vestibulum volutpat. Duis augue mauris, aliquet sed condimentum sit amet, malesuada eget velit. Pellentesque vehicula laoreet massa id gravida."
                    },
                    {
                        author: "Paul Banks",
                        dateTime: "2020-12-11T11:01",
                        note: "Nullam sagittis lorem sed turpis iaculis, vel bibendum metus posuere. Fusce bibendum, nibh scelerisque vulputate luctus, augue augue mattis dui, eu porta neque lectus sed sem. Pellentesque habitant morbi tristique senectus et netus et malesuada fames ac turpis egestas. Aenean eget orci euismod, eleifend quam ut, bibendum dolor."
                    },
                ],
                responseDueDate: "2020-12-17",
                followUpDate: "2020-12-17",
                status: "Open",
                processManagement: [
                    {
                        title: 'Review Footage',
                        checked: false,
                        note: ''
                    }, {
                        title: 'Review Transactions',
                        checked: false,
                        note: ''
                    }, {
                        title: 'Fill out Investigation Pack',
                        checked: false,
                        note: ''
                    }, {
                        title: 'Collect Statements (Witnesses)',
                        checked: false,
                        note: ''
                    }, {
                        title: 'Collect Statement (Accused)',
                        checked: false,
                        note: ''
                    }, {
                        title: 'Obtain Other Evidence',
                        checked: false,
                        note: ''
                    }, {
                        title: 'Obtain CCTV',
                        checked: false,
                        note: ''
                    }, {
                        title: 'Formal Investigation',
                        checked: false,
                        note: ''
                    }, {
                        title: 'Contact Police',
                        checked: false,
                        note: ''
                    }, {
                        title: 'Security Policies',
                        checked: false,
                        note: ''
                    },
                ],
            }
        ],
        // Object for caseManagement.show
        caseFile: {
            incident: {},
            caseOwner: null,
            caseInvestigatorLevel: null,
            dateTime: null,
            investigationType: null,
            notes: [],
            responseDueDate: null,
            followUpDate: null,
            status: null,
            processManagement: []
        },
        // object for incident.create
        caseForReview: {
            incident: {},
            caseOwner: null,
            caseInvestigatorLevel: null,
            dateTime: null,
            investigationType: null,
            notes: [{
                author: null,
                dateTime: null,
                note: null
            }],
            responseDueDate: null,
            followUpDate: null,
            status: null,
            processManagement: [
                {
                    title: 'Review Footage',
                    checked: false,
                    note: ''
                }, {
                    title: 'Review Transactions',
                    checked: false,
                    note: ''
                }, {
                    title: 'Fill out Investigation Pack',
                    checked: false,
                    note: ''
                }, {
                    title: 'Collect Statements (Witnesses)',
                    checked: false,
                    note: ''
                }, {
                    title: 'Collect Statement (Accused)',
                    checked: false,
                    note: ''
                }, {
                    title: 'Obtain Other Evidence',
                    checked: false,
                    note: ''
                }, {
                    title: 'Obtain CCTV',
                    checked: false,
                    note: ''
                }, {
                    title: 'Formal Investigation',
                    checked: false,
                    note: ''
                }, {
                    title: 'Contact Police',
                    checked: false,
                    note: ''
                }, {
                    title: 'Security Policies',
                    checked: false,
                    note: ''
                },
            ],
        },
        incidents: [
            {
                id: 1,
                date: "01/12/2020",
                region: "North East",
                area: 1,
                store: 1233,
                employee: 4752,
                txType: "Refund",
                receiptNumber: 1,
                value: 67300,
                probability: 0.81,
                proposedAction: "Investigate",
                actionProbability: 0.75
            },
            {
                id: 2,
                date: "02/12/2020",
                region: "Midlands",
                area: 1,
                store: 1468,
                employee: 8855,
                txType: "Line Void",
                receiptNumber: 2,
                value: 55500,
                probability: 0.89,
                proposedAction: "Investigate",
                actionProbability: 0.82
            },
            {
                id: 3,
                date: "03/12/2020",
                region: "South West",
                area: 1,
                store: 2259,
                employee: 4807,
                txType: "Receipt Void",
                receiptNumber: 3,
                value: 39300,
                probability: 0.71,
                proposedAction: "Informal",
                actionProbability: 0.79
            },
            {
                id: 4,
                date: "04/12/2020",
                region: "North East",
                area: 1,
                store: 1233,
                employee: 4615,
                txType: "Receipt Void",
                receiptNumber: 4,
                value: 71100,
                probability: 0.78,
                proposedAction: "Informal",
                actionProbability: 0.83
            },
            {
                id: 5,
                date: "05/12/2020",
                region: "Midlands",
                area: 1,
                store: 1468,
                employee: 6407,
                txType: "Line Void",
                receiptNumber: 5,
                value: 66000,
                probability: 0.76,
                proposedAction: "Informal",
                actionProbability: 0.93
            },
            {
                id: 6,
                date: "06/12/2020",
                region: "South West",
                area: 1,
                store: 2259,
                employee: 5140,
                txType: "Line Void",
                receiptNumber: 6,
                value: 66800,
                probability: 0.71,
                proposedAction: "Informal",
                actionProbability: 0.92
            },
            {
                id: 7,
                date: "07/12/2020",
                region: "North East",
                area: 1,
                store: 1233,
                employee: 1282,
                txType: "Receipt Void",
                receiptNumber: 7,
                value: 52900,
                probability: 0.65,
                proposedAction: "Informal",
                actionProbability: 0.67
            },
            {
                id: 8,
                date: "08/12/2020",
                region: "Midlands",
                area: 1,
                store: 1468,
                employee: 2920,
                txType: "Receipt Void",
                receiptNumber: 8,
                value: 72800,
                probability: 0.88,
                proposedAction: "Investigate",
                actionProbability: 0.71
            },
            {
                id: 9,
                date: "09/12/2020",
                region: "South West",
                area: 1,
                store: 2259,
                employee: 7462,
                txType: "Line Void",
                receiptNumber: 9,
                value: 74500,
                probability: 0.87,
                proposedAction: "Investigate",
                actionProbability: 0.81
            },
            {
                id: 10,
                date: "10/12/2020",
                region: "North East",
                area: 1,
                store: 1233,
                employee: 7083,
                txType: "Refund",
                receiptNumber: 10,
                value: 25300,
                probability: 0.9,
                proposedAction: "Investigate",
                actionProbability: 0.82
            },
            {
                id: 11,
                date: "11/12/2020",
                region: "Midlands",
                area: 1,
                store: 1468,
                employee: 1345,
                txType: "Line Void",
                receiptNumber: 11,
                value: 63000,
                probability: 0.72,
                proposedAction: "Informal",
                actionProbability: 0.92
            },
            {
                id: 12,
                date: "12/12/2020",
                region: "South West",
                area: 2,
                store: 1144,
                employee: 1811,
                txType: "Receipt Void",
                receiptNumber: 12,
                value: 49100,
                probability: 0.78,
                proposedAction: "Informal",
                actionProbability: 0.75
            },
            {
                id: 13,
                date: "13/12/2020",
                region: "North East",
                area: 2,
                store: 1221,
                employee: 6755,
                txType: "Receipt Void",
                receiptNumber: 13,
                value: 33700,
                probability: 0.71,
                proposedAction: "Informal",
                actionProbability: 0.95
            },
            {
                id: 14,
                date: "14/12/2020",
                region: "Midlands",
                area: 2,
                store: 5443,
                employee: 1189,
                txType: "Line Void",
                receiptNumber: 14,
                value: 42900,
                probability: 0.92,
                proposedAction: "Investigate",
                actionProbability: 0.89
            },
            {
                id: 15,
                date: "15/12/2020",
                region: "South West",
                area: 2,
                store: 1144,
                employee: 1465,
                txType: "Line Void",
                receiptNumber: 15,
                value: 41900,
                probability: 0.8,
                proposedAction: "Investigate",
                actionProbability: 0.76
            },
            {
                id: 16,
                date: "16/12/2020",
                region: "North East",
                area: 2,
                store: 1221,
                employee: 7039,
                txType: "Receipt Void",
                receiptNumber: 16,
                value: 52400,
                probability: 0.71,
                proposedAction: "Informal",
                actionProbability: 0.92
            },
            {
                id: 17,
                date: "17/12/2020",
                region: "Midlands",
                area: 2,
                store: 5443,
                employee: 4964,
                txType: "Receipt Void",
                receiptNumber: 17,
                value: 15500,
                probability: 0.93,
                proposedAction: "Investigate",
                actionProbability: 0.76
            },
            {
                id: 18,
                date: "18/12/2020",
                region: "South West",
                area: 3,
                store: 1200,
                employee: 8350,
                txType: "Line Void",
                receiptNumber: 18,
                value: 38100,
                probability: 0.87,
                proposedAction: "Investigate",
                actionProbability: 0.69
            },
            {
                id: 19,
                date: "19/12/2020",
                region: "North East",
                area: 3,
                store: 9777,
                employee: 6521,
                txType: "Refund",
                receiptNumber: 19,
                value: 70700,
                probability: 0.78,
                proposedAction: "Informal",
                actionProbability: 0.66
            },
            {
                id: 20,
                date: "20/12/2020",
                region: "Midlands",
                area: 3,
                store: 5545,
                employee: 3563,
                txType: "Refund",
                receiptNumber: 20,
                value: 17000,
                probability: 0.84,
                proposedAction: "Investigate",
                actionProbability: 0.65
            }
        ],
        dash: [
            {
                "site": 1233,
                "team": "Red",
                "agent": 12330001,
                "date": "26/10/2020",
                "ScanSpeed": 86,
                "TotalSales": 102,
                "AverageLinesPerSale": 5,
                "TotalLines": 510,
                "LineVoidRate": 0.0099,
                "ReceiptVoidRate": 0.0212,
                "LinesVoided": 6,
                "ReceiptsVoided": 3,
                "RefundValue": 24848,
                "AverageReceiptCost": 354,
                "AverageLineCost": 246,
                "TotalSaleAmount": 36108
            },
            {
                "site": 1233,
                "team": "Red",
                "agent": 12330001,
                "date": "02/11/2020",
                "ScanSpeed": 65,
                "TotalSales": 30,
                "AverageLinesPerSale": 8,
                "TotalLines": 240,
                "LineVoidRate": 0.0241,
                "ReceiptVoidRate": 0.0086,
                "LinesVoided": 6,
                "ReceiptsVoided": 1,
                "RefundValue": 5270,
                "AverageReceiptCost": 1953,
                "AverageLineCost": 127,
                "TotalSaleAmount": 58590
            },
            {
                "site": 1233,
                "team": "Red",
                "agent": 12330001,
                "date": "09/11/2020",
                "ScanSpeed": 88,
                "TotalSales": 174,
                "AverageLinesPerSale": 10,
                "TotalLines": 1740,
                "LineVoidRate": 0.0248,
                "ReceiptVoidRate": 0.0175,
                "LinesVoided": 44,
                "ReceiptsVoided": 4,
                "RefundValue": 19274,
                "AverageReceiptCost": 2761,
                "AverageLineCost": 478,
                "TotalSaleAmount": 480414
            },
            {
                "site": 1233,
                "team": "Red",
                "agent": 12330001,
                "date": "16/11/2020",
                "ScanSpeed": 86,
                "TotalSales": 87,
                "AverageLinesPerSale": 3,
                "TotalLines": 261,
                "LineVoidRate": 0.0004,
                "ReceiptVoidRate": 0.018,
                "LinesVoided": 1,
                "ReceiptsVoided": 2,
                "RefundValue": 1192500,
                "AverageReceiptCost": 2674,
                "AverageLineCost": 477,
                "TotalSaleAmount": 232638
            },
            {
                "site": 1233,
                "team": "Red",
                "agent": 12330001,
                "date": "23/11/2020",
                "ScanSpeed": 23,
                "TotalSales": 157,
                "AverageLinesPerSale": 3,
                "TotalLines": 471,
                "LineVoidRate": 0.0683,
                "ReceiptVoidRate": 0.0246,
                "LinesVoided": 33,
                "ReceiptsVoided": 4,
                "RefundValue": 2225,
                "AverageReceiptCost": 3731,
                "AverageLineCost": 152,
                "TotalSaleAmount": 585767
            },
            {
                "site": 1233,
                "team": "Red",
                "agent": 12330001,
                "date": "30/11/2020",
                "ScanSpeed": 33,
                "TotalSales": 24,
                "AverageLinesPerSale": 9,
                "TotalLines": 216,
                "LineVoidRate": 0.026,
                "ReceiptVoidRate": 0.0078,
                "LinesVoided": 6,
                "ReceiptsVoided": 1,
                "RefundValue": 8385,
                "AverageReceiptCost": 3992,
                "AverageLineCost": 218,
                "TotalSaleAmount": 95808
            },
            {
                "site": 1233,
                "team": "Red",
                "agent": 12330001,
                "date": "07/12/2020",
                "ScanSpeed": 23,
                "TotalSales": 55,
                "AverageLinesPerSale": 7,
                "TotalLines": 385,
                "LineVoidRate": 0.0034,
                "ReceiptVoidRate": 0.015,
                "LinesVoided": 2,
                "ReceiptsVoided": 1,
                "RefundValue": 115000,
                "AverageReceiptCost": 4215,
                "AverageLineCost": 391,
                "TotalSaleAmount": 231825
            },
            {
                "site": 1233,
                "team": "Red",
                "agent": 12330001,
                "date": "14/12/2020",
                "ScanSpeed": 12,
                "TotalSales": 48,
                "AverageLinesPerSale": 3,
                "TotalLines": 144,
                "LineVoidRate": 0.0502,
                "ReceiptVoidRate": 0.0264,
                "LinesVoided": 8,
                "ReceiptsVoided": 2,
                "RefundValue": 5817,
                "AverageReceiptCost": 2460,
                "AverageLineCost": 292,
                "TotalSaleAmount": 118080
            },
            {
                "site": 1233,
                "team": "Red",
                "agent": 12330001,
                "date": "21/12/2020",
                "ScanSpeed": 87,
                "TotalSales": 142,
                "AverageLinesPerSale": 8,
                "TotalLines": 1136,
                "LineVoidRate": 0.0402,
                "ReceiptVoidRate": 0.0377,
                "LinesVoided": 46,
                "ReceiptsVoided": 6,
                "RefundValue": 9527,
                "AverageReceiptCost": 3877,
                "AverageLineCost": 383,
                "TotalSaleAmount": 550534
            },
            {
                "site": 1233,
                "team": "Red",
                "agent": 12330001,
                "date": "28/12/2020",
                "ScanSpeed": 63,
                "TotalSales": 57,
                "AverageLinesPerSale": 3,
                "TotalLines": 171,
                "LineVoidRate": 0.0378,
                "ReceiptVoidRate": 0.0079,
                "LinesVoided": 7,
                "ReceiptsVoided": 1,
                "RefundValue": 9815,
                "AverageReceiptCost": 3270,
                "AverageLineCost": 371,
                "TotalSaleAmount": 186390
            },
            {
                "site": 1233,
                "team": "Red",
                "agent": 12330002,
                "date": "26/10/2020",
                "ScanSpeed": 17,
                "TotalSales": 173,
                "AverageLinesPerSale": 6,
                "TotalLines": 1038,
                "LineVoidRate": 0.032,
                "ReceiptVoidRate": 0.0248,
                "LinesVoided": 34,
                "ReceiptsVoided": 5,
                "RefundValue": 6500,
                "AverageReceiptCost": 978,
                "AverageLineCost": 208,
                "TotalSaleAmount": 169194
            },
            {
                "site": 1233,
                "team": "Red",
                "agent": 12330002,
                "date": "02/11/2020",
                "ScanSpeed": 18,
                "TotalSales": 38,
                "AverageLinesPerSale": 8,
                "TotalLines": 304,
                "LineVoidRate": 0.0524,
                "ReceiptVoidRate": 0.0053,
                "LinesVoided": 16,
                "ReceiptsVoided": 1,
                "RefundValue": 1622,
                "AverageReceiptCost": 978,
                "AverageLineCost": 85,
                "TotalSaleAmount": 37164
            },
            {
                "site": 1233,
                "team": "Red",
                "agent": 12330002,
                "date": "09/11/2020",
                "ScanSpeed": 45,
                "TotalSales": 101,
                "AverageLinesPerSale": 6,
                "TotalLines": 606,
                "LineVoidRate": 0.0163,
                "ReceiptVoidRate": 0.0069,
                "LinesVoided": 10,
                "ReceiptsVoided": 1,
                "RefundValue": 9202,
                "AverageReceiptCost": 3684,
                "AverageLineCost": 150,
                "TotalSaleAmount": 372084
            },
            {
                "site": 1233,
                "team": "Red",
                "agent": 12330002,
                "date": "16/11/2020",
                "ScanSpeed": 70,
                "TotalSales": 44,
                "AverageLinesPerSale": 4,
                "TotalLines": 176,
                "LineVoidRate": 0.0506,
                "ReceiptVoidRate": 0.0374,
                "LinesVoided": 9,
                "ReceiptsVoided": 2,
                "RefundValue": 6996,
                "AverageReceiptCost": 622,
                "AverageLineCost": 354,
                "TotalSaleAmount": 27368
            },
            {
                "site": 1233,
                "team": "Red",
                "agent": 12330002,
                "date": "23/11/2020",
                "ScanSpeed": 61,
                "TotalSales": 102,
                "AverageLinesPerSale": 5,
                "TotalLines": 510,
                "LineVoidRate": 0.0381,
                "ReceiptVoidRate": 0.0225,
                "LinesVoided": 20,
                "ReceiptsVoided": 3,
                "RefundValue": 5748,
                "AverageReceiptCost": 4305,
                "AverageLineCost": 219,
                "TotalSaleAmount": 439110
            },
            {
                "site": 1233,
                "team": "Red",
                "agent": 12330002,
                "date": "30/11/2020",
                "ScanSpeed": 54,
                "TotalSales": 28,
                "AverageLinesPerSale": 10,
                "TotalLines": 280,
                "LineVoidRate": 0.0433,
                "ReceiptVoidRate": 0.0127,
                "LinesVoided": 13,
                "ReceiptsVoided": 1,
                "RefundValue": 9584,
                "AverageReceiptCost": 1839,
                "AverageLineCost": 415,
                "TotalSaleAmount": 51492
            },
            {
                "site": 1233,
                "team": "Red",
                "agent": 12330002,
                "date": "07/12/2020",
                "ScanSpeed": 33,
                "TotalSales": 106,
                "AverageLinesPerSale": 6,
                "TotalLines": 636,
                "LineVoidRate": 0.0238,
                "ReceiptVoidRate": 0.0043,
                "LinesVoided": 16,
                "ReceiptsVoided": 1,
                "RefundValue": 17227,
                "AverageReceiptCost": 2511,
                "AverageLineCost": 410,
                "TotalSaleAmount": 266166
            },
            {
                "site": 1233,
                "team": "Red",
                "agent": 12330002,
                "date": "14/12/2020",
                "ScanSpeed": 82,
                "TotalSales": 50,
                "AverageLinesPerSale": 5,
                "TotalLines": 250,
                "LineVoidRate": 0.0251,
                "ReceiptVoidRate": 0.0282,
                "LinesVoided": 7,
                "ReceiptsVoided": 2,
                "RefundValue": 19641,
                "AverageReceiptCost": 3934,
                "AverageLineCost": 493,
                "TotalSaleAmount": 196700
            },
            {
                "site": 1233,
                "team": "Red",
                "agent": 12330002,
                "date": "21/12/2020",
                "ScanSpeed": 48,
                "TotalSales": 162,
                "AverageLinesPerSale": 10,
                "TotalLines": 1620,
                "LineVoidRate": 0.0635,
                "ReceiptVoidRate": 0.0279,
                "LinesVoided": 103,
                "ReceiptsVoided": 5,
                "RefundValue": 5323,
                "AverageReceiptCost": 812,
                "AverageLineCost": 338,
                "TotalSaleAmount": 131544
            },
            {
                "site": 1233,
                "team": "Red",
                "agent": 12330002,
                "date": "28/12/2020",
                "ScanSpeed": 33,
                "TotalSales": 175,
                "AverageLinesPerSale": 4,
                "TotalLines": 700,
                "LineVoidRate": 0.0463,
                "ReceiptVoidRate": 0.0248,
                "LinesVoided": 33,
                "ReceiptsVoided": 5,
                "RefundValue": 1253,
                "AverageReceiptCost": 2198,
                "AverageLineCost": 58,
                "TotalSaleAmount": 384650
            },
            {
                "site": 1233,
                "team": "Red",
                "agent": 12330003,
                "date": "26/10/2020",
                "ScanSpeed": 26,
                "TotalSales": 88,
                "AverageLinesPerSale": 3,
                "TotalLines": 264,
                "LineVoidRate": 0.0027,
                "ReceiptVoidRate": 0.035,
                "LinesVoided": 1,
                "ReceiptsVoided": 4,
                "RefundValue": 91481,
                "AverageReceiptCost": 676,
                "AverageLineCost": 247,
                "TotalSaleAmount": 59488
            },
            {
                "site": 1233,
                "team": "Red",
                "agent": 12330003,
                "date": "02/11/2020",
                "ScanSpeed": 77,
                "TotalSales": 109,
                "AverageLinesPerSale": 3,
                "TotalLines": 327,
                "LineVoidRate": 0.0195,
                "ReceiptVoidRate": 0.0243,
                "LinesVoided": 7,
                "ReceiptsVoided": 3,
                "RefundValue": 13897,
                "AverageReceiptCost": 4143,
                "AverageLineCost": 271,
                "TotalSaleAmount": 451587
            },
            {
                "site": 1233,
                "team": "Red",
                "agent": 12330003,
                "date": "09/11/2020",
                "ScanSpeed": 39,
                "TotalSales": 85,
                "AverageLinesPerSale": 4,
                "TotalLines": 340,
                "LineVoidRate": 0.0421,
                "ReceiptVoidRate": 0.0006,
                "LinesVoided": 15,
                "ReceiptsVoided": 1,
                "RefundValue": 11876,
                "AverageReceiptCost": 3527,
                "AverageLineCost": 500,
                "TotalSaleAmount": 299795
            },
            {
                "site": 1233,
                "team": "Red",
                "agent": 12330003,
                "date": "16/11/2020",
                "ScanSpeed": 20,
                "TotalSales": 98,
                "AverageLinesPerSale": 10,
                "TotalLines": 980,
                "LineVoidRate": 0.0246,
                "ReceiptVoidRate": 0.0188,
                "LinesVoided": 25,
                "ReceiptsVoided": 2,
                "RefundValue": 10081,
                "AverageReceiptCost": 3212,
                "AverageLineCost": 248,
                "TotalSaleAmount": 314776
            },
            {
                "site": 1233,
                "team": "Red",
                "agent": 12330003,
                "date": "23/11/2020",
                "ScanSpeed": 12,
                "TotalSales": 85,
                "AverageLinesPerSale": 5,
                "TotalLines": 425,
                "LineVoidRate": 0.0322,
                "ReceiptVoidRate": 0.0133,
                "LinesVoided": 14,
                "ReceiptsVoided": 2,
                "RefundValue": 9286,
                "AverageReceiptCost": 649,
                "AverageLineCost": 299,
                "TotalSaleAmount": 55165
            },
            {
                "site": 1233,
                "team": "Red",
                "agent": 12330003,
                "date": "30/11/2020",
                "ScanSpeed": 84,
                "TotalSales": 135,
                "AverageLinesPerSale": 3,
                "TotalLines": 405,
                "LineVoidRate": 0.039,
                "ReceiptVoidRate": 0.0024,
                "LinesVoided": 16,
                "ReceiptsVoided": 1,
                "RefundValue": 11615,
                "AverageReceiptCost": 662,
                "AverageLineCost": 453,
                "TotalSaleAmount": 89370
            },
            {
                "site": 1233,
                "team": "Red",
                "agent": 12330003,
                "date": "07/12/2020",
                "ScanSpeed": 78,
                "TotalSales": 35,
                "AverageLinesPerSale": 3,
                "TotalLines": 105,
                "LineVoidRate": 0.0675,
                "ReceiptVoidRate": 0.0143,
                "LinesVoided": 8,
                "ReceiptsVoided": 1,
                "RefundValue": 5956,
                "AverageReceiptCost": 2570,
                "AverageLineCost": 402,
                "TotalSaleAmount": 89950
            },
            {
                "site": 1233,
                "team": "Red",
                "agent": 12330003,
                "date": "14/12/2020",
                "ScanSpeed": 30,
                "TotalSales": 171,
                "AverageLinesPerSale": 7,
                "TotalLines": 1197,
                "LineVoidRate": 0.043,
                "ReceiptVoidRate": 0.015,
                "LinesVoided": 52,
                "ReceiptsVoided": 3,
                "RefundValue": 1930,
                "AverageReceiptCost": 2673,
                "AverageLineCost": 83,
                "TotalSaleAmount": 457083
            },
            {
                "site": 1233,
                "team": "Red",
                "agent": 12330003,
                "date": "21/12/2020",
                "ScanSpeed": 24,
                "TotalSales": 33,
                "AverageLinesPerSale": 8,
                "TotalLines": 264,
                "LineVoidRate": 0.017,
                "ReceiptVoidRate": 0.0157,
                "LinesVoided": 5,
                "ReceiptsVoided": 1,
                "RefundValue": 28118,
                "AverageReceiptCost": 812,
                "AverageLineCost": 478,
                "TotalSaleAmount": 26796
            },
            {
                "site": 1233,
                "team": "Red",
                "agent": 12330003,
                "date": "28/12/2020",
                "ScanSpeed": 89,
                "TotalSales": 171,
                "AverageLinesPerSale": 8,
                "TotalLines": 1368,
                "LineVoidRate": 0.0312,
                "ReceiptVoidRate": 0.0285,
                "LinesVoided": 43,
                "ReceiptsVoided": 5,
                "RefundValue": 1635,
                "AverageReceiptCost": 2326,
                "AverageLineCost": 51,
                "TotalSaleAmount": 397746
            },
            {
                "site": 1233,
                "team": "Red",
                "agent": 12330004,
                "date": "26/10/2020",
                "ScanSpeed": 76,
                "TotalSales": 124,
                "AverageLinesPerSale": 4,
                "TotalLines": 496,
                "LineVoidRate": 0.0197,
                "ReceiptVoidRate": 0.024,
                "LinesVoided": 10,
                "ReceiptsVoided": 3,
                "RefundValue": 17919,
                "AverageReceiptCost": 4344,
                "AverageLineCost": 353,
                "TotalSaleAmount": 538656
            },
            {
                "site": 1233,
                "team": "Red",
                "agent": 12330004,
                "date": "02/11/2020",
                "ScanSpeed": 41,
                "TotalSales": 46,
                "AverageLinesPerSale": 7,
                "TotalLines": 322,
                "LineVoidRate": 0.0141,
                "ReceiptVoidRate": 0.0019,
                "LinesVoided": 5,
                "ReceiptsVoided": 1,
                "RefundValue": 27021,
                "AverageReceiptCost": 3357,
                "AverageLineCost": 381,
                "TotalSaleAmount": 154422
            },
            {
                "site": 1233,
                "team": "Red",
                "agent": 12330004,
                "date": "09/11/2020",
                "ScanSpeed": 83,
                "TotalSales": 159,
                "AverageLinesPerSale": 4,
                "TotalLines": 636,
                "LineVoidRate": 0.0625,
                "ReceiptVoidRate": 0.0393,
                "LinesVoided": 40,
                "ReceiptsVoided": 7,
                "RefundValue": 5344,
                "AverageReceiptCost": 3287,
                "AverageLineCost": 334,
                "TotalSaleAmount": 522633
            },
            {
                "site": 1233,
                "team": "Red",
                "agent": 12330004,
                "date": "16/11/2020",
                "ScanSpeed": 27,
                "TotalSales": 78,
                "AverageLinesPerSale": 11,
                "TotalLines": 858,
                "LineVoidRate": 0.0121,
                "ReceiptVoidRate": 0.0372,
                "LinesVoided": 11,
                "ReceiptsVoided": 3,
                "RefundValue": 30083,
                "AverageReceiptCost": 840,
                "AverageLineCost": 364,
                "TotalSaleAmount": 65520
            },
            {
                "site": 1233,
                "team": "Red",
                "agent": 12330004,
                "date": "23/11/2020",
                "ScanSpeed": 64,
                "TotalSales": 20,
                "AverageLinesPerSale": 8,
                "TotalLines": 160,
                "LineVoidRate": 0.0333,
                "ReceiptVoidRate": 0.0049,
                "LinesVoided": 6,
                "ReceiptsVoided": 1,
                "RefundValue": 7237,
                "AverageReceiptCost": 2898,
                "AverageLineCost": 241,
                "TotalSaleAmount": 57960
            },
            {
                "site": 1233,
                "team": "Red",
                "agent": 12330004,
                "date": "30/11/2020",
                "ScanSpeed": 19,
                "TotalSales": 129,
                "AverageLinesPerSale": 11,
                "TotalLines": 1419,
                "LineVoidRate": 0.0656,
                "ReceiptVoidRate": 0.0038,
                "LinesVoided": 94,
                "ReceiptsVoided": 1,
                "RefundValue": 4878,
                "AverageReceiptCost": 2894,
                "AverageLineCost": 320,
                "TotalSaleAmount": 373326
            },
            {
                "site": 1233,
                "team": "Red",
                "agent": 12330004,
                "date": "07/12/2020",
                "ScanSpeed": 33,
                "TotalSales": 138,
                "AverageLinesPerSale": 6,
                "TotalLines": 828,
                "LineVoidRate": 0.0163,
                "ReceiptVoidRate": 0.037,
                "LinesVoided": 14,
                "ReceiptsVoided": 6,
                "RefundValue": 18344,
                "AverageReceiptCost": 3113,
                "AverageLineCost": 299,
                "TotalSaleAmount": 429594
            },
            {
                "site": 1233,
                "team": "Red",
                "agent": 12330004,
                "date": "14/12/2020",
                "ScanSpeed": 63,
                "TotalSales": 15,
                "AverageLinesPerSale": 9,
                "TotalLines": 135,
                "LineVoidRate": 0.0149,
                "ReceiptVoidRate": 0.0156,
                "LinesVoided": 3,
                "ReceiptsVoided": 1,
                "RefundValue": 7987,
                "AverageReceiptCost": 3410,
                "AverageLineCost": 119,
                "TotalSaleAmount": 51150
            },
            {
                "site": 1233,
                "team": "Red",
                "agent": 12330004,
                "date": "21/12/2020",
                "ScanSpeed": 87,
                "TotalSales": 117,
                "AverageLinesPerSale": 8,
                "TotalLines": 936,
                "LineVoidRate": 0.0313,
                "ReceiptVoidRate": 0.0028,
                "LinesVoided": 30,
                "ReceiptsVoided": 1,
                "RefundValue": 7636,
                "AverageReceiptCost": 3026,
                "AverageLineCost": 239,
                "TotalSaleAmount": 354042
            },
            {
                "site": 1233,
                "team": "Red",
                "agent": 12330004,
                "date": "28/12/2020",
                "ScanSpeed": 34,
                "TotalSales": 162,
                "AverageLinesPerSale": 7,
                "TotalLines": 1134,
                "LineVoidRate": 0.0435,
                "ReceiptVoidRate": 0.0102,
                "LinesVoided": 50,
                "ReceiptsVoided": 2,
                "RefundValue": 5195,
                "AverageReceiptCost": 3741,
                "AverageLineCost": 226,
                "TotalSaleAmount": 606042
            },
            {
                "site": 1233,
                "team": "Red",
                "agent": 12330005,
                "date": "26/10/2020",
                "ScanSpeed": 88,
                "TotalSales": 119,
                "AverageLinesPerSale": 3,
                "TotalLines": 357,
                "LineVoidRate": 0.0697,
                "ReceiptVoidRate": 0.032,
                "LinesVoided": 25,
                "ReceiptsVoided": 4,
                "RefundValue": 5782,
                "AverageReceiptCost": 2580,
                "AverageLineCost": 403,
                "TotalSaleAmount": 307020
            },
            {
                "site": 1233,
                "team": "Red",
                "agent": 12330005,
                "date": "02/11/2020",
                "ScanSpeed": 53,
                "TotalSales": 160,
                "AverageLinesPerSale": 11,
                "TotalLines": 1760,
                "LineVoidRate": 0.012,
                "ReceiptVoidRate": 0.0234,
                "LinesVoided": 22,
                "ReceiptsVoided": 4,
                "RefundValue": 17000,
                "AverageReceiptCost": 4385,
                "AverageLineCost": 204,
                "TotalSaleAmount": 701600
            },
            {
                "site": 1233,
                "team": "Red",
                "agent": 12330005,
                "date": "09/11/2020",
                "ScanSpeed": 49,
                "TotalSales": 39,
                "AverageLinesPerSale": 8,
                "TotalLines": 312,
                "LineVoidRate": 0.0039,
                "ReceiptVoidRate": 0.0066,
                "LinesVoided": 2,
                "ReceiptsVoided": 1,
                "RefundValue": 18462,
                "AverageReceiptCost": 349,
                "AverageLineCost": 72,
                "TotalSaleAmount": 13611
            },
            {
                "site": 1233,
                "team": "Red",
                "agent": 12330005,
                "date": "16/11/2020",
                "ScanSpeed": 88,
                "TotalSales": 62,
                "AverageLinesPerSale": 9,
                "TotalLines": 558,
                "LineVoidRate": 0.0454,
                "ReceiptVoidRate": 0.0285,
                "LinesVoided": 26,
                "ReceiptsVoided": 2,
                "RefundValue": 6366,
                "AverageReceiptCost": 2687,
                "AverageLineCost": 289,
                "TotalSaleAmount": 166594
            },
            {
                "site": 1233,
                "team": "Red",
                "agent": 12330005,
                "date": "23/11/2020",
                "ScanSpeed": 72,
                "TotalSales": 56,
                "AverageLinesPerSale": 8,
                "TotalLines": 448,
                "LineVoidRate": 0.0592,
                "ReceiptVoidRate": 0.0273,
                "LinesVoided": 27,
                "ReceiptsVoided": 2,
                "RefundValue": 4291,
                "AverageReceiptCost": 3820,
                "AverageLineCost": 254,
                "TotalSaleAmount": 213920
            },
            {
                "site": 1233,
                "team": "Red",
                "agent": 12330005,
                "date": "30/11/2020",
                "ScanSpeed": 68,
                "TotalSales": 81,
                "AverageLinesPerSale": 10,
                "TotalLines": 810,
                "LineVoidRate": 0.0211,
                "ReceiptVoidRate": 0.025,
                "LinesVoided": 18,
                "ReceiptsVoided": 3,
                "RefundValue": 6919,
                "AverageReceiptCost": 491,
                "AverageLineCost": 146,
                "TotalSaleAmount": 39771
            },
            {
                "site": 1233,
                "team": "Red",
                "agent": 12330005,
                "date": "07/12/2020",
                "ScanSpeed": 87,
                "TotalSales": 170,
                "AverageLinesPerSale": 8,
                "TotalLines": 1360,
                "LineVoidRate": 0.0532,
                "ReceiptVoidRate": 0.0325,
                "LinesVoided": 73,
                "ReceiptsVoided": 6,
                "RefundValue": 7143,
                "AverageReceiptCost": 4238,
                "AverageLineCost": 380,
                "TotalSaleAmount": 720460
            },
            {
                "site": 1233,
                "team": "Red",
                "agent": 12330005,
                "date": "14/12/2020",
                "ScanSpeed": 30,
                "TotalSales": 141,
                "AverageLinesPerSale": 8,
                "TotalLines": 1128,
                "LineVoidRate": 0.0111,
                "ReceiptVoidRate": 0.0329,
                "LinesVoided": 13,
                "ReceiptsVoided": 5,
                "RefundValue": 30811,
                "AverageReceiptCost": 3206,
                "AverageLineCost": 342,
                "TotalSaleAmount": 452046
            },
            {
                "site": 1233,
                "team": "Red",
                "agent": 12330005,
                "date": "21/12/2020",
                "ScanSpeed": 19,
                "TotalSales": 144,
                "AverageLinesPerSale": 4,
                "TotalLines": 576,
                "LineVoidRate": 0.0667,
                "ReceiptVoidRate": 0.0015,
                "LinesVoided": 39,
                "ReceiptsVoided": 1,
                "RefundValue": 5427,
                "AverageReceiptCost": 1382,
                "AverageLineCost": 362,
                "TotalSaleAmount": 199008
            },
            {
                "site": 1233,
                "team": "Red",
                "agent": 12330005,
                "date": "28/12/2020",
                "ScanSpeed": 82,
                "TotalSales": 71,
                "AverageLinesPerSale": 8,
                "TotalLines": 568,
                "LineVoidRate": 0.0051,
                "ReceiptVoidRate": 0.0342,
                "LinesVoided": 3,
                "ReceiptsVoided": 3,
                "RefundValue": 20392,
                "AverageReceiptCost": 1129,
                "AverageLineCost": 104,
                "TotalSaleAmount": 80159
            },
            {
                "site": 1233,
                "team": "Blue",
                "agent": 12330006,
                "date": "26/10/2020",
                "ScanSpeed": 20,
                "TotalSales": 134,
                "AverageLinesPerSale": 8,
                "TotalLines": 1072,
                "LineVoidRate": 0.0417,
                "ReceiptVoidRate": 0.0132,
                "LinesVoided": 45,
                "ReceiptsVoided": 2,
                "RefundValue": 7482,
                "AverageReceiptCost": 1079,
                "AverageLineCost": 312,
                "TotalSaleAmount": 144586
            },
            {
                "site": 1233,
                "team": "Blue",
                "agent": 12330006,
                "date": "02/11/2020",
                "ScanSpeed": 23,
                "TotalSales": 19,
                "AverageLinesPerSale": 11,
                "TotalLines": 209,
                "LineVoidRate": 0.0082,
                "ReceiptVoidRate": 0.0382,
                "LinesVoided": 2,
                "ReceiptsVoided": 1,
                "RefundValue": 15976,
                "AverageReceiptCost": 408,
                "AverageLineCost": 131,
                "TotalSaleAmount": 7752
            },
            {
                "site": 1233,
                "team": "Blue",
                "agent": 12330006,
                "date": "09/11/2020",
                "ScanSpeed": 77,
                "TotalSales": 28,
                "AverageLinesPerSale": 11,
                "TotalLines": 308,
                "LineVoidRate": 0.0265,
                "ReceiptVoidRate": 0.0225,
                "LinesVoided": 9,
                "ReceiptsVoided": 1,
                "RefundValue": 10453,
                "AverageReceiptCost": 2153,
                "AverageLineCost": 277,
                "TotalSaleAmount": 60284
            },
            {
                "site": 1233,
                "team": "Blue",
                "agent": 12330006,
                "date": "16/11/2020",
                "ScanSpeed": 58,
                "TotalSales": 164,
                "AverageLinesPerSale": 3,
                "TotalLines": 492,
                "LineVoidRate": 0.0592,
                "ReceiptVoidRate": 0.0016,
                "LinesVoided": 30,
                "ReceiptsVoided": 1,
                "RefundValue": 2652,
                "AverageReceiptCost": 666,
                "AverageLineCost": 157,
                "TotalSaleAmount": 109224
            },
            {
                "site": 1233,
                "team": "Blue",
                "agent": 12330006,
                "date": "23/11/2020",
                "ScanSpeed": 15,
                "TotalSales": 128,
                "AverageLinesPerSale": 3,
                "TotalLines": 384,
                "LineVoidRate": 0.0463,
                "ReceiptVoidRate": 0.0347,
                "LinesVoided": 18,
                "ReceiptsVoided": 5,
                "RefundValue": 1771,
                "AverageReceiptCost": 3432,
                "AverageLineCost": 82,
                "TotalSaleAmount": 439296
            },
            {
                "site": 1233,
                "team": "Blue",
                "agent": 12330006,
                "date": "30/11/2020",
                "ScanSpeed": 88,
                "TotalSales": 101,
                "AverageLinesPerSale": 11,
                "TotalLines": 1111,
                "LineVoidRate": 0.043,
                "ReceiptVoidRate": 0.0218,
                "LinesVoided": 48,
                "ReceiptsVoided": 3,
                "RefundValue": 5977,
                "AverageReceiptCost": 3038,
                "AverageLineCost": 257,
                "TotalSaleAmount": 306838
            },
            {
                "site": 1233,
                "team": "Blue",
                "agent": 12330006,
                "date": "07/12/2020",
                "ScanSpeed": 41,
                "TotalSales": 170,
                "AverageLinesPerSale": 11,
                "TotalLines": 1870,
                "LineVoidRate": 0.062,
                "ReceiptVoidRate": 0.0379,
                "LinesVoided": 116,
                "ReceiptsVoided": 7,
                "RefundValue": 3661,
                "AverageReceiptCost": 2758,
                "AverageLineCost": 227,
                "TotalSaleAmount": 468860
            },
            {
                "site": 1233,
                "team": "Blue",
                "agent": 12330006,
                "date": "14/12/2020",
                "ScanSpeed": 45,
                "TotalSales": 129,
                "AverageLinesPerSale": 10,
                "TotalLines": 1290,
                "LineVoidRate": 0.058,
                "ReceiptVoidRate": 0.0175,
                "LinesVoided": 75,
                "ReceiptsVoided": 3,
                "RefundValue": 6345,
                "AverageReceiptCost": 922,
                "AverageLineCost": 368,
                "TotalSaleAmount": 118938
            },
            {
                "site": 1233,
                "team": "Blue",
                "agent": 12330006,
                "date": "21/12/2020",
                "ScanSpeed": 21,
                "TotalSales": 62,
                "AverageLinesPerSale": 9,
                "TotalLines": 558,
                "LineVoidRate": 0.0413,
                "ReceiptVoidRate": 0.0088,
                "LinesVoided": 24,
                "ReceiptsVoided": 1,
                "RefundValue": 4770,
                "AverageReceiptCost": 2810,
                "AverageLineCost": 197,
                "TotalSaleAmount": 174220
            },
            {
                "site": 1233,
                "team": "Blue",
                "agent": 12330006,
                "date": "28/12/2020",
                "ScanSpeed": 50,
                "TotalSales": 134,
                "AverageLinesPerSale": 11,
                "TotalLines": 1474,
                "LineVoidRate": 0.0383,
                "ReceiptVoidRate": 0.0362,
                "LinesVoided": 57,
                "ReceiptsVoided": 5,
                "RefundValue": 3551,
                "AverageReceiptCost": 690,
                "AverageLineCost": 136,
                "TotalSaleAmount": 92460
            },
            {
                "site": 1233,
                "team": "Blue",
                "agent": 12330007,
                "date": "26/10/2020",
                "ScanSpeed": 78,
                "TotalSales": 105,
                "AverageLinesPerSale": 8,
                "TotalLines": 840,
                "LineVoidRate": 0.0253,
                "ReceiptVoidRate": 0.0041,
                "LinesVoided": 22,
                "ReceiptsVoided": 1,
                "RefundValue": 6798,
                "AverageReceiptCost": 4011,
                "AverageLineCost": 172,
                "TotalSaleAmount": 421155
            },
            {
                "site": 1233,
                "team": "Blue",
                "agent": 12330007,
                "date": "02/11/2020",
                "ScanSpeed": 73,
                "TotalSales": 174,
                "AverageLinesPerSale": 8,
                "TotalLines": 1392,
                "LineVoidRate": 0.0602,
                "ReceiptVoidRate": 0.0244,
                "LinesVoided": 84,
                "ReceiptsVoided": 5,
                "RefundValue": 7309,
                "AverageReceiptCost": 4276,
                "AverageLineCost": 440,
                "TotalSaleAmount": 744024
            },
            {
                "site": 1233,
                "team": "Blue",
                "agent": 12330007,
                "date": "09/11/2020",
                "ScanSpeed": 55,
                "TotalSales": 20,
                "AverageLinesPerSale": 5,
                "TotalLines": 100,
                "LineVoidRate": 0.0291,
                "ReceiptVoidRate": 0.0341,
                "LinesVoided": 3,
                "ReceiptsVoided": 1,
                "RefundValue": 10206,
                "AverageReceiptCost": 4316,
                "AverageLineCost": 297,
                "TotalSaleAmount": 86320
            },
            {
                "site": 1233,
                "team": "Blue",
                "agent": 12330007,
                "date": "16/11/2020",
                "ScanSpeed": 67,
                "TotalSales": 107,
                "AverageLinesPerSale": 10,
                "TotalLines": 1070,
                "LineVoidRate": 0.0477,
                "ReceiptVoidRate": 0.0058,
                "LinesVoided": 52,
                "ReceiptsVoided": 1,
                "RefundValue": 6709,
                "AverageReceiptCost": 1843,
                "AverageLineCost": 320,
                "TotalSaleAmount": 197201
            },
            {
                "site": 1233,
                "team": "Blue",
                "agent": 12330007,
                "date": "23/11/2020",
                "ScanSpeed": 31,
                "TotalSales": 169,
                "AverageLinesPerSale": 11,
                "TotalLines": 1859,
                "LineVoidRate": 0.0441,
                "ReceiptVoidRate": 0.0026,
                "LinesVoided": 82,
                "ReceiptsVoided": 1,
                "RefundValue": 8753,
                "AverageReceiptCost": 1217,
                "AverageLineCost": 386,
                "TotalSaleAmount": 205673
            },
            {
                "site": 1233,
                "team": "Blue",
                "agent": 12330007,
                "date": "30/11/2020",
                "ScanSpeed": 72,
                "TotalSales": 172,
                "AverageLinesPerSale": 6,
                "TotalLines": 1032,
                "LineVoidRate": 0.0572,
                "ReceiptVoidRate": 0.0154,
                "LinesVoided": 60,
                "ReceiptsVoided": 3,
                "RefundValue": 8024,
                "AverageReceiptCost": 2226,
                "AverageLineCost": 459,
                "TotalSaleAmount": 382872
            },
            {
                "site": 1233,
                "team": "Blue",
                "agent": 12330007,
                "date": "07/12/2020",
                "ScanSpeed": 26,
                "TotalSales": 97,
                "AverageLinesPerSale": 6,
                "TotalLines": 582,
                "LineVoidRate": 0.029,
                "ReceiptVoidRate": 0.0075,
                "LinesVoided": 17,
                "ReceiptsVoided": 1,
                "RefundValue": 11724,
                "AverageReceiptCost": 1102,
                "AverageLineCost": 340,
                "TotalSaleAmount": 106894
            },
            {
                "site": 1233,
                "team": "Blue",
                "agent": 12330007,
                "date": "14/12/2020",
                "ScanSpeed": 20,
                "TotalSales": 165,
                "AverageLinesPerSale": 3,
                "TotalLines": 495,
                "LineVoidRate": 0.0118,
                "ReceiptVoidRate": 0.0204,
                "LinesVoided": 6,
                "ReceiptsVoided": 4,
                "RefundValue": 7373,
                "AverageReceiptCost": 3397,
                "AverageLineCost": 87,
                "TotalSaleAmount": 560505
            },
            {
                "site": 1233,
                "team": "Blue",
                "agent": 12330007,
                "date": "21/12/2020",
                "ScanSpeed": 21,
                "TotalSales": 103,
                "AverageLinesPerSale": 3,
                "TotalLines": 309,
                "LineVoidRate": 0.0524,
                "ReceiptVoidRate": 0.0318,
                "LinesVoided": 17,
                "ReceiptsVoided": 4,
                "RefundValue": 9466,
                "AverageReceiptCost": 3796,
                "AverageLineCost": 496,
                "TotalSaleAmount": 390988
            },
            {
                "site": 1233,
                "team": "Blue",
                "agent": 12330007,
                "date": "28/12/2020",
                "ScanSpeed": 35,
                "TotalSales": 67,
                "AverageLinesPerSale": 6,
                "TotalLines": 402,
                "LineVoidRate": 0.0048,
                "ReceiptVoidRate": 0.016,
                "LinesVoided": 2,
                "ReceiptsVoided": 2,
                "RefundValue": 50000,
                "AverageReceiptCost": 2627,
                "AverageLineCost": 240,
                "TotalSaleAmount": 176009
            },
            {
                "site": 1233,
                "team": "Blue",
                "agent": 12330008,
                "date": "26/10/2020",
                "ScanSpeed": 13,
                "TotalSales": 123,
                "AverageLinesPerSale": 7,
                "TotalLines": 861,
                "LineVoidRate": 0.0496,
                "ReceiptVoidRate": 0.029,
                "LinesVoided": 43,
                "ReceiptsVoided": 4,
                "RefundValue": 7056,
                "AverageReceiptCost": 3570,
                "AverageLineCost": 350,
                "TotalSaleAmount": 439110
            },
            {
                "site": 1233,
                "team": "Blue",
                "agent": 12330008,
                "date": "02/11/2020",
                "ScanSpeed": 53,
                "TotalSales": 161,
                "AverageLinesPerSale": 8,
                "TotalLines": 1288,
                "LineVoidRate": 0.001,
                "ReceiptVoidRate": 0.0119,
                "LinesVoided": 2,
                "ReceiptsVoided": 2,
                "RefundValue": 365000,
                "AverageReceiptCost": 3020,
                "AverageLineCost": 365,
                "TotalSaleAmount": 486220
            },
            {
                "site": 1233,
                "team": "Blue",
                "agent": 12330008,
                "date": "09/11/2020",
                "ScanSpeed": 83,
                "TotalSales": 115,
                "AverageLinesPerSale": 5,
                "TotalLines": 575,
                "LineVoidRate": 0.0197,
                "ReceiptVoidRate": 0.0163,
                "LinesVoided": 12,
                "ReceiptsVoided": 2,
                "RefundValue": 17665,
                "AverageReceiptCost": 3809,
                "AverageLineCost": 348,
                "TotalSaleAmount": 438035
            },
            {
                "site": 1233,
                "team": "Blue",
                "agent": 12330008,
                "date": "16/11/2020",
                "ScanSpeed": 21,
                "TotalSales": 164,
                "AverageLinesPerSale": 9,
                "TotalLines": 1476,
                "LineVoidRate": 0.0044,
                "ReceiptVoidRate": 0.0092,
                "LinesVoided": 7,
                "ReceiptsVoided": 2,
                "RefundValue": 35000,
                "AverageReceiptCost": 2410,
                "AverageLineCost": 154,
                "TotalSaleAmount": 395240
            },
            {
                "site": 1233,
                "team": "Blue",
                "agent": 12330008,
                "date": "23/11/2020",
                "ScanSpeed": 89,
                "TotalSales": 144,
                "AverageLinesPerSale": 6,
                "TotalLines": 864,
                "LineVoidRate": 0.0388,
                "ReceiptVoidRate": 0.0195,
                "LinesVoided": 34,
                "ReceiptsVoided": 3,
                "RefundValue": 11701,
                "AverageReceiptCost": 3196,
                "AverageLineCost": 454,
                "TotalSaleAmount": 460224
            },
            {
                "site": 1233,
                "team": "Blue",
                "agent": 12330008,
                "date": "30/11/2020",
                "ScanSpeed": 29,
                "TotalSales": 69,
                "AverageLinesPerSale": 6,
                "TotalLines": 414,
                "LineVoidRate": 0.0326,
                "ReceiptVoidRate": 0.0101,
                "LinesVoided": 14,
                "ReceiptsVoided": 1,
                "RefundValue": 12393,
                "AverageReceiptCost": 2317,
                "AverageLineCost": 404,
                "TotalSaleAmount": 159873
            },
            {
                "site": 1233,
                "team": "Blue",
                "agent": 12330008,
                "date": "07/12/2020",
                "ScanSpeed": 42,
                "TotalSales": 40,
                "AverageLinesPerSale": 7,
                "TotalLines": 280,
                "LineVoidRate": 0.07,
                "ReceiptVoidRate": 0.0212,
                "LinesVoided": 20,
                "ReceiptsVoided": 1,
                "RefundValue": 1357,
                "AverageReceiptCost": 3690,
                "AverageLineCost": 95,
                "TotalSaleAmount": 147600
            },
            {
                "site": 1233,
                "team": "Blue",
                "agent": 12330008,
                "date": "14/12/2020",
                "ScanSpeed": 16,
                "TotalSales": 115,
                "AverageLinesPerSale": 6,
                "TotalLines": 690,
                "LineVoidRate": 0.0394,
                "ReceiptVoidRate": 0.0254,
                "LinesVoided": 28,
                "ReceiptsVoided": 3,
                "RefundValue": 3832,
                "AverageReceiptCost": 740,
                "AverageLineCost": 151,
                "TotalSaleAmount": 85100
            },
            {
                "site": 1233,
                "team": "Blue",
                "agent": 12330008,
                "date": "21/12/2020",
                "ScanSpeed": 75,
                "TotalSales": 150,
                "AverageLinesPerSale": 4,
                "TotalLines": 600,
                "LineVoidRate": 0.0581,
                "ReceiptVoidRate": 0.0209,
                "LinesVoided": 35,
                "ReceiptsVoided": 4,
                "RefundValue": 7917,
                "AverageReceiptCost": 300,
                "AverageLineCost": 460,
                "TotalSaleAmount": 45000
            },
            {
                "site": 1233,
                "team": "Blue",
                "agent": 12330008,
                "date": "28/12/2020",
                "ScanSpeed": 45,
                "TotalSales": 91,
                "AverageLinesPerSale": 7,
                "TotalLines": 637,
                "LineVoidRate": 0.027,
                "ReceiptVoidRate": 0.0033,
                "LinesVoided": 18,
                "ReceiptsVoided": 1,
                "RefundValue": 6852,
                "AverageReceiptCost": 1766,
                "AverageLineCost": 185,
                "TotalSaleAmount": 160706
            },
            {
                "site": 1233,
                "team": "Blue",
                "agent": 12330009,
                "date": "26/10/2020",
                "ScanSpeed": 56,
                "TotalSales": 109,
                "AverageLinesPerSale": 9,
                "TotalLines": 981,
                "LineVoidRate": 0.0162,
                "ReceiptVoidRate": 0.0028,
                "LinesVoided": 16,
                "ReceiptsVoided": 1,
                "RefundValue": 5802,
                "AverageReceiptCost": 366,
                "AverageLineCost": 94,
                "TotalSaleAmount": 39894
            },
            {
                "site": 1233,
                "team": "Blue",
                "agent": 12330009,
                "date": "02/11/2020",
                "ScanSpeed": 28,
                "TotalSales": 19,
                "AverageLinesPerSale": 4,
                "TotalLines": 76,
                "LineVoidRate": 0.0399,
                "ReceiptVoidRate": 0.013,
                "LinesVoided": 4,
                "ReceiptsVoided": 1,
                "RefundValue": 8722,
                "AverageReceiptCost": 2382,
                "AverageLineCost": 348,
                "TotalSaleAmount": 45258
            },
            {
                "site": 1233,
                "team": "Blue",
                "agent": 12330009,
                "date": "09/11/2020",
                "ScanSpeed": 83,
                "TotalSales": 146,
                "AverageLinesPerSale": 11,
                "TotalLines": 1606,
                "LineVoidRate": 0.0073,
                "ReceiptVoidRate": 0.0348,
                "LinesVoided": 12,
                "ReceiptsVoided": 6,
                "RefundValue": 26164,
                "AverageReceiptCost": 3368,
                "AverageLineCost": 191,
                "TotalSaleAmount": 491728
            },
            {
                "site": 1233,
                "team": "Blue",
                "agent": 12330009,
                "date": "16/11/2020",
                "ScanSpeed": 62,
                "TotalSales": 66,
                "AverageLinesPerSale": 7,
                "TotalLines": 462,
                "LineVoidRate": 0.0044,
                "ReceiptVoidRate": 0.0021,
                "LinesVoided": 3,
                "ReceiptsVoided": 1,
                "RefundValue": 12045,
                "AverageReceiptCost": 1934,
                "AverageLineCost": 53,
                "TotalSaleAmount": 127644
            },
            {
                "site": 1233,
                "team": "Blue",
                "agent": 12330009,
                "date": "23/11/2020",
                "ScanSpeed": 17,
                "TotalSales": 21,
                "AverageLinesPerSale": 9,
                "TotalLines": 189,
                "LineVoidRate": 0.0302,
                "ReceiptVoidRate": 0.0394,
                "LinesVoided": 6,
                "ReceiptsVoided": 1,
                "RefundValue": 14305,
                "AverageReceiptCost": 1538,
                "AverageLineCost": 432,
                "TotalSaleAmount": 32298
            },
            {
                "site": 1233,
                "team": "Blue",
                "agent": 12330009,
                "date": "30/11/2020",
                "ScanSpeed": 35,
                "TotalSales": 51,
                "AverageLinesPerSale": 8,
                "TotalLines": 408,
                "LineVoidRate": 0.0156,
                "ReceiptVoidRate": 0.0155,
                "LinesVoided": 7,
                "ReceiptsVoided": 1,
                "RefundValue": 24038,
                "AverageReceiptCost": 1280,
                "AverageLineCost": 375,
                "TotalSaleAmount": 65280
            },
            {
                "site": 1233,
                "team": "Blue",
                "agent": 12330009,
                "date": "07/12/2020",
                "ScanSpeed": 43,
                "TotalSales": 34,
                "AverageLinesPerSale": 8,
                "TotalLines": 272,
                "LineVoidRate": 0.0497,
                "ReceiptVoidRate": 0.003,
                "LinesVoided": 14,
                "ReceiptsVoided": 1,
                "RefundValue": 8290,
                "AverageReceiptCost": 3924,
                "AverageLineCost": 412,
                "TotalSaleAmount": 133416
            },
            {
                "site": 1233,
                "team": "Blue",
                "agent": 12330009,
                "date": "14/12/2020",
                "ScanSpeed": 66,
                "TotalSales": 166,
                "AverageLinesPerSale": 5,
                "TotalLines": 830,
                "LineVoidRate": 0.0004,
                "ReceiptVoidRate": 0.0399,
                "LinesVoided": 1,
                "ReceiptsVoided": 7,
                "RefundValue": 1000000,
                "AverageReceiptCost": 3553,
                "AverageLineCost": 400,
                "TotalSaleAmount": 589798
            },
            {
                "site": 1233,
                "team": "Blue",
                "agent": 12330009,
                "date": "21/12/2020",
                "ScanSpeed": 51,
                "TotalSales": 59,
                "AverageLinesPerSale": 5,
                "TotalLines": 295,
                "LineVoidRate": 0.0165,
                "ReceiptVoidRate": 0.0098,
                "LinesVoided": 5,
                "ReceiptsVoided": 1,
                "RefundValue": 21576,
                "AverageReceiptCost": 2009,
                "AverageLineCost": 356,
                "TotalSaleAmount": 118531
            },
            {
                "site": 1233,
                "team": "Blue",
                "agent": 12330009,
                "date": "28/12/2020",
                "ScanSpeed": 62,
                "TotalSales": 108,
                "AverageLinesPerSale": 7,
                "TotalLines": 756,
                "LineVoidRate": 0.0617,
                "ReceiptVoidRate": 0.0258,
                "LinesVoided": 47,
                "ReceiptsVoided": 3,
                "RefundValue": 3501,
                "AverageReceiptCost": 2243,
                "AverageLineCost": 216,
                "TotalSaleAmount": 242244
            },
            {
                "site": 1233,
                "team": "Blue",
                "agent": 12330010,
                "date": "26/10/2020",
                "ScanSpeed": 71,
                "TotalSales": 115,
                "AverageLinesPerSale": 10,
                "TotalLines": 1150,
                "LineVoidRate": 0.0485,
                "ReceiptVoidRate": 0.0292,
                "LinesVoided": 56,
                "ReceiptsVoided": 4,
                "RefundValue": 8124,
                "AverageReceiptCost": 840,
                "AverageLineCost": 394,
                "TotalSaleAmount": 96600
            },
            {
                "site": 1233,
                "team": "Blue",
                "agent": 12330010,
                "date": "02/11/2020",
                "ScanSpeed": 73,
                "TotalSales": 153,
                "AverageLinesPerSale": 4,
                "TotalLines": 612,
                "LineVoidRate": 0.021,
                "ReceiptVoidRate": 0.0389,
                "LinesVoided": 13,
                "ReceiptsVoided": 6,
                "RefundValue": 18429,
                "AverageReceiptCost": 1475,
                "AverageLineCost": 387,
                "TotalSaleAmount": 225675
            },
            {
                "site": 1233,
                "team": "Blue",
                "agent": 12330010,
                "date": "09/11/2020",
                "ScanSpeed": 43,
                "TotalSales": 175,
                "AverageLinesPerSale": 4,
                "TotalLines": 700,
                "LineVoidRate": 0.023,
                "ReceiptVoidRate": 0.026,
                "LinesVoided": 17,
                "ReceiptsVoided": 5,
                "RefundValue": 13565,
                "AverageReceiptCost": 4182,
                "AverageLineCost": 312,
                "TotalSaleAmount": 731850
            },
            {
                "site": 1233,
                "team": "Blue",
                "agent": 12330010,
                "date": "16/11/2020",
                "ScanSpeed": 79,
                "TotalSales": 138,
                "AverageLinesPerSale": 10,
                "TotalLines": 1380,
                "LineVoidRate": 0.0135,
                "ReceiptVoidRate": 0.0321,
                "LinesVoided": 19,
                "ReceiptsVoided": 5,
                "RefundValue": 24222,
                "AverageReceiptCost": 3927,
                "AverageLineCost": 327,
                "TotalSaleAmount": 541926
            },
            {
                "site": 1233,
                "team": "Blue",
                "agent": 12330010,
                "date": "23/11/2020",
                "ScanSpeed": 16,
                "TotalSales": 56,
                "AverageLinesPerSale": 9,
                "TotalLines": 504,
                "LineVoidRate": 0.014,
                "ReceiptVoidRate": 0.0324,
                "LinesVoided": 8,
                "ReceiptsVoided": 2,
                "RefundValue": 31214,
                "AverageReceiptCost": 979,
                "AverageLineCost": 437,
                "TotalSaleAmount": 54824
            },
            {
                "site": 1233,
                "team": "Blue",
                "agent": 12330010,
                "date": "30/11/2020",
                "ScanSpeed": 86,
                "TotalSales": 58,
                "AverageLinesPerSale": 11,
                "TotalLines": 638,
                "LineVoidRate": 0.0055,
                "ReceiptVoidRate": 0.0292,
                "LinesVoided": 4,
                "ReceiptsVoided": 2,
                "RefundValue": 50727,
                "AverageReceiptCost": 2095,
                "AverageLineCost": 279,
                "TotalSaleAmount": 121510
            },
            {
                "site": 1233,
                "team": "Blue",
                "agent": 12330010,
                "date": "07/12/2020",
                "ScanSpeed": 88,
                "TotalSales": 159,
                "AverageLinesPerSale": 6,
                "TotalLines": 954,
                "LineVoidRate": 0.0477,
                "ReceiptVoidRate": 0.0186,
                "LinesVoided": 46,
                "ReceiptsVoided": 3,
                "RefundValue": 4088,
                "AverageReceiptCost": 3900,
                "AverageLineCost": 195,
                "TotalSaleAmount": 620100
            },
            {
                "site": 1233,
                "team": "Blue",
                "agent": 12330010,
                "date": "14/12/2020",
                "ScanSpeed": 88,
                "TotalSales": 98,
                "AverageLinesPerSale": 11,
                "TotalLines": 1078,
                "LineVoidRate": 0.068,
                "ReceiptVoidRate": 0.035,
                "LinesVoided": 74,
                "ReceiptsVoided": 4,
                "RefundValue": 5926,
                "AverageReceiptCost": 1040,
                "AverageLineCost": 403,
                "TotalSaleAmount": 101920
            },
            {
                "site": 1233,
                "team": "Blue",
                "agent": 12330010,
                "date": "21/12/2020",
                "ScanSpeed": 88,
                "TotalSales": 161,
                "AverageLinesPerSale": 4,
                "TotalLines": 644,
                "LineVoidRate": 0.034,
                "ReceiptVoidRate": 0.0197,
                "LinesVoided": 22,
                "ReceiptsVoided": 4,
                "RefundValue": 4118,
                "AverageReceiptCost": 4168,
                "AverageLineCost": 140,
                "TotalSaleAmount": 671048
            },
            {
                "site": 1233,
                "team": "Blue",
                "agent": 12330010,
                "date": "28/12/2020",
                "ScanSpeed": 40,
                "TotalSales": 117,
                "AverageLinesPerSale": 4,
                "TotalLines": 468,
                "LineVoidRate": 0.0363,
                "ReceiptVoidRate": 0.0234,
                "LinesVoided": 17,
                "ReceiptsVoided": 3,
                "RefundValue": 11956,
                "AverageReceiptCost": 622,
                "AverageLineCost": 434,
                "TotalSaleAmount": 72774
            },
            {
                "site": 1233,
                "team": "Blue",
                "agent": 12330011,
                "date": "26/10/2020",
                "ScanSpeed": 51,
                "TotalSales": 30,
                "AverageLinesPerSale": 10,
                "TotalLines": 300,
                "LineVoidRate": 0.0637,
                "ReceiptVoidRate": 0.0393,
                "LinesVoided": 20,
                "ReceiptsVoided": 2,
                "RefundValue": 6091,
                "AverageReceiptCost": 662,
                "AverageLineCost": 388,
                "TotalSaleAmount": 19860
            },
            {
                "site": 1233,
                "team": "Blue",
                "agent": 12330011,
                "date": "02/11/2020",
                "ScanSpeed": 73,
                "TotalSales": 145,
                "AverageLinesPerSale": 6,
                "TotalLines": 870,
                "LineVoidRate": 0.0204,
                "ReceiptVoidRate": 0.0251,
                "LinesVoided": 18,
                "ReceiptsVoided": 4,
                "RefundValue": 8039,
                "AverageReceiptCost": 3476,
                "AverageLineCost": 164,
                "TotalSaleAmount": 504020
            },
            {
                "site": 1233,
                "team": "Blue",
                "agent": 12330011,
                "date": "09/11/2020",
                "ScanSpeed": 24,
                "TotalSales": 99,
                "AverageLinesPerSale": 10,
                "TotalLines": 990,
                "LineVoidRate": 0.0578,
                "ReceiptVoidRate": 0.0085,
                "LinesVoided": 58,
                "ReceiptsVoided": 1,
                "RefundValue": 7803,
                "AverageReceiptCost": 667,
                "AverageLineCost": 451,
                "TotalSaleAmount": 66033
            },
            {
                "site": 1233,
                "team": "Blue",
                "agent": 12330011,
                "date": "16/11/2020",
                "ScanSpeed": 15,
                "TotalSales": 131,
                "AverageLinesPerSale": 9,
                "TotalLines": 1179,
                "LineVoidRate": 0.0086,
                "ReceiptVoidRate": 0.0222,
                "LinesVoided": 11,
                "ReceiptsVoided": 3,
                "RefundValue": 47442,
                "AverageReceiptCost": 1348,
                "AverageLineCost": 408,
                "TotalSaleAmount": 176588
            },
            {
                "site": 1233,
                "team": "Blue",
                "agent": 12330011,
                "date": "23/11/2020",
                "ScanSpeed": 76,
                "TotalSales": 107,
                "AverageLinesPerSale": 9,
                "TotalLines": 963,
                "LineVoidRate": 0.0229,
                "ReceiptVoidRate": 0.039,
                "LinesVoided": 23,
                "ReceiptsVoided": 5,
                "RefundValue": 12707,
                "AverageReceiptCost": 2729,
                "AverageLineCost": 291,
                "TotalSaleAmount": 292003
            },
            {
                "site": 1233,
                "team": "Blue",
                "agent": 12330011,
                "date": "30/11/2020",
                "ScanSpeed": 82,
                "TotalSales": 104,
                "AverageLinesPerSale": 7,
                "TotalLines": 728,
                "LineVoidRate": 0.0537,
                "ReceiptVoidRate": 0.0072,
                "LinesVoided": 40,
                "ReceiptsVoided": 1,
                "RefundValue": 4525,
                "AverageReceiptCost": 3828,
                "AverageLineCost": 243,
                "TotalSaleAmount": 398112
            },
            {
                "site": 1233,
                "team": "Blue",
                "agent": 12330011,
                "date": "07/12/2020",
                "ScanSpeed": 71,
                "TotalSales": 155,
                "AverageLinesPerSale": 11,
                "TotalLines": 1705,
                "LineVoidRate": 0.0213,
                "ReceiptVoidRate": 0.0336,
                "LinesVoided": 37,
                "ReceiptsVoided": 6,
                "RefundValue": 12864,
                "AverageReceiptCost": 2670,
                "AverageLineCost": 274,
                "TotalSaleAmount": 413850
            },
            {
                "site": 1233,
                "team": "Blue",
                "agent": 12330011,
                "date": "14/12/2020",
                "ScanSpeed": 67,
                "TotalSales": 15,
                "AverageLinesPerSale": 7,
                "TotalLines": 105,
                "LineVoidRate": 0.0021,
                "ReceiptVoidRate": 0.0092,
                "LinesVoided": 1,
                "ReceiptsVoided": 1,
                "RefundValue": 104286,
                "AverageReceiptCost": 1530,
                "AverageLineCost": 219,
                "TotalSaleAmount": 22950
            },
            {
                "site": 1233,
                "team": "Blue",
                "agent": 12330011,
                "date": "21/12/2020",
                "ScanSpeed": 23,
                "TotalSales": 86,
                "AverageLinesPerSale": 9,
                "TotalLines": 774,
                "LineVoidRate": 0.0398,
                "ReceiptVoidRate": 0.026,
                "LinesVoided": 31,
                "ReceiptsVoided": 3,
                "RefundValue": 11633,
                "AverageReceiptCost": 1685,
                "AverageLineCost": 463,
                "TotalSaleAmount": 144910
            },
            {
                "site": 1233,
                "team": "Blue",
                "agent": 12330011,
                "date": "28/12/2020",
                "ScanSpeed": 43,
                "TotalSales": 170,
                "AverageLinesPerSale": 7,
                "TotalLines": 1190,
                "LineVoidRate": 0.0532,
                "ReceiptVoidRate": 0.0234,
                "LinesVoided": 64,
                "ReceiptsVoided": 4,
                "RefundValue": 2951,
                "AverageReceiptCost": 2217,
                "AverageLineCost": 157,
                "TotalSaleAmount": 376890
            },
            {
                "site": 1233,
                "team": "Green",
                "agent": 12330012,
                "date": "26/10/2020",
                "ScanSpeed": 32,
                "TotalSales": 89,
                "AverageLinesPerSale": 5,
                "TotalLines": 445,
                "LineVoidRate": 0.0377,
                "ReceiptVoidRate": 0.0306,
                "LinesVoided": 17,
                "ReceiptsVoided": 3,
                "RefundValue": 4695,
                "AverageReceiptCost": 4042,
                "AverageLineCost": 177,
                "TotalSaleAmount": 359738
            },
            {
                "site": 1233,
                "team": "Green",
                "agent": 12330012,
                "date": "02/11/2020",
                "ScanSpeed": 51,
                "TotalSales": 18,
                "AverageLinesPerSale": 4,
                "TotalLines": 72,
                "LineVoidRate": 0.0113,
                "ReceiptVoidRate": 0.0228,
                "LinesVoided": 1,
                "ReceiptsVoided": 1,
                "RefundValue": 33628,
                "AverageReceiptCost": 2992,
                "AverageLineCost": 380,
                "TotalSaleAmount": 53856
            },
            {
                "site": 1233,
                "team": "Green",
                "agent": 12330012,
                "date": "09/11/2020",
                "ScanSpeed": 11,
                "TotalSales": 83,
                "AverageLinesPerSale": 3,
                "TotalLines": 249,
                "LineVoidRate": 0.0625,
                "ReceiptVoidRate": 0.0155,
                "LinesVoided": 16,
                "ReceiptsVoided": 2,
                "RefundValue": 6464,
                "AverageReceiptCost": 1305,
                "AverageLineCost": 404,
                "TotalSaleAmount": 108315
            },
            {
                "site": 1233,
                "team": "Green",
                "agent": 12330012,
                "date": "16/11/2020",
                "ScanSpeed": 49,
                "TotalSales": 56,
                "AverageLinesPerSale": 3,
                "TotalLines": 168,
                "LineVoidRate": 0.0375,
                "ReceiptVoidRate": 0.0221,
                "LinesVoided": 7,
                "ReceiptsVoided": 2,
                "RefundValue": 4933,
                "AverageReceiptCost": 1317,
                "AverageLineCost": 185,
                "TotalSaleAmount": 73752
            },
            {
                "site": 1233,
                "team": "Green",
                "agent": 12330012,
                "date": "23/11/2020",
                "ScanSpeed": 60,
                "TotalSales": 68,
                "AverageLinesPerSale": 6,
                "TotalLines": 408,
                "LineVoidRate": 0.0559,
                "ReceiptVoidRate": 0.0389,
                "LinesVoided": 23,
                "ReceiptsVoided": 3,
                "RefundValue": 7066,
                "AverageReceiptCost": 735,
                "AverageLineCost": 395,
                "TotalSaleAmount": 49980
            },
            {
                "site": 1233,
                "team": "Green",
                "agent": 12330012,
                "date": "30/11/2020",
                "ScanSpeed": 56,
                "TotalSales": 98,
                "AverageLinesPerSale": 3,
                "TotalLines": 294,
                "LineVoidRate": 0.0203,
                "ReceiptVoidRate": 0.0053,
                "LinesVoided": 6,
                "ReceiptsVoided": 1,
                "RefundValue": 13153,
                "AverageReceiptCost": 2391,
                "AverageLineCost": 267,
                "TotalSaleAmount": 234318
            },
            {
                "site": 1233,
                "team": "Green",
                "agent": 12330012,
                "date": "07/12/2020",
                "ScanSpeed": 72,
                "TotalSales": 112,
                "AverageLinesPerSale": 4,
                "TotalLines": 448,
                "LineVoidRate": 0.0157,
                "ReceiptVoidRate": 0.0176,
                "LinesVoided": 8,
                "ReceiptsVoided": 2,
                "RefundValue": 12229,
                "AverageReceiptCost": 1836,
                "AverageLineCost": 192,
                "TotalSaleAmount": 205632
            },
            {
                "site": 1233,
                "team": "Green",
                "agent": 12330012,
                "date": "14/12/2020",
                "ScanSpeed": 59,
                "TotalSales": 68,
                "AverageLinesPerSale": 4,
                "TotalLines": 272,
                "LineVoidRate": 0.0065,
                "ReceiptVoidRate": 0.0229,
                "LinesVoided": 2,
                "ReceiptsVoided": 2,
                "RefundValue": 43385,
                "AverageReceiptCost": 3858,
                "AverageLineCost": 282,
                "TotalSaleAmount": 262344
            },
            {
                "site": 1233,
                "team": "Green",
                "agent": 12330012,
                "date": "21/12/2020",
                "ScanSpeed": 61,
                "TotalSales": 144,
                "AverageLinesPerSale": 10,
                "TotalLines": 1440,
                "LineVoidRate": 0.0209,
                "ReceiptVoidRate": 0.0382,
                "LinesVoided": 31,
                "ReceiptsVoided": 6,
                "RefundValue": 20622,
                "AverageReceiptCost": 3966,
                "AverageLineCost": 431,
                "TotalSaleAmount": 571104
            },
            {
                "site": 1233,
                "team": "Green",
                "agent": 12330012,
                "date": "28/12/2020",
                "ScanSpeed": 16,
                "TotalSales": 175,
                "AverageLinesPerSale": 3,
                "TotalLines": 525,
                "LineVoidRate": 0.0228,
                "ReceiptVoidRate": 0.0165,
                "LinesVoided": 12,
                "ReceiptsVoided": 3,
                "RefundValue": 11316,
                "AverageReceiptCost": 952,
                "AverageLineCost": 258,
                "TotalSaleAmount": 166600
            },
            {
                "site": 1233,
                "team": "Green",
                "agent": 12330013,
                "date": "26/10/2020",
                "ScanSpeed": 11,
                "TotalSales": 63,
                "AverageLinesPerSale": 8,
                "TotalLines": 504,
                "LineVoidRate": 0.0657,
                "ReceiptVoidRate": 0.0323,
                "LinesVoided": 34,
                "ReceiptsVoided": 3,
                "RefundValue": 4642,
                "AverageReceiptCost": 386,
                "AverageLineCost": 305,
                "TotalSaleAmount": 24318
            },
            {
                "site": 1233,
                "team": "Green",
                "agent": 12330013,
                "date": "02/11/2020",
                "ScanSpeed": 69,
                "TotalSales": 44,
                "AverageLinesPerSale": 5,
                "TotalLines": 220,
                "LineVoidRate": 0.0614,
                "ReceiptVoidRate": 0.0342,
                "LinesVoided": 14,
                "ReceiptsVoided": 2,
                "RefundValue": 4414,
                "AverageReceiptCost": 4229,
                "AverageLineCost": 271,
                "TotalSaleAmount": 186076
            },
            {
                "site": 1233,
                "team": "Green",
                "agent": 12330013,
                "date": "09/11/2020",
                "ScanSpeed": 70,
                "TotalSales": 144,
                "AverageLinesPerSale": 8,
                "TotalLines": 1152,
                "LineVoidRate": 0.03,
                "ReceiptVoidRate": 0.0339,
                "LinesVoided": 35,
                "ReceiptsVoided": 5,
                "RefundValue": 6167,
                "AverageReceiptCost": 2137,
                "AverageLineCost": 185,
                "TotalSaleAmount": 307728
            },
            {
                "site": 1233,
                "team": "Green",
                "agent": 12330013,
                "date": "16/11/2020",
                "ScanSpeed": 34,
                "TotalSales": 40,
                "AverageLinesPerSale": 10,
                "TotalLines": 400,
                "LineVoidRate": 0.0523,
                "ReceiptVoidRate": 0.0381,
                "LinesVoided": 21,
                "ReceiptsVoided": 2,
                "RefundValue": 8719,
                "AverageReceiptCost": 3795,
                "AverageLineCost": 456,
                "TotalSaleAmount": 151800
            },
            {
                "site": 1233,
                "team": "Green",
                "agent": 12330013,
                "date": "23/11/2020",
                "ScanSpeed": 51,
                "TotalSales": 32,
                "AverageLinesPerSale": 7,
                "TotalLines": 224,
                "LineVoidRate": 0.0342,
                "ReceiptVoidRate": 0.003,
                "LinesVoided": 8,
                "ReceiptsVoided": 1,
                "RefundValue": 4094,
                "AverageReceiptCost": 1203,
                "AverageLineCost": 140,
                "TotalSaleAmount": 38496
            },
            {
                "site": 1233,
                "team": "Green",
                "agent": 12330013,
                "date": "30/11/2020",
                "ScanSpeed": 39,
                "TotalSales": 155,
                "AverageLinesPerSale": 11,
                "TotalLines": 1705,
                "LineVoidRate": 0.0109,
                "ReceiptVoidRate": 0.0392,
                "LinesVoided": 19,
                "ReceiptsVoided": 7,
                "RefundValue": 16972,
                "AverageReceiptCost": 4003,
                "AverageLineCost": 185,
                "TotalSaleAmount": 620465
            },
            {
                "site": 1233,
                "team": "Green",
                "agent": 12330013,
                "date": "07/12/2020",
                "ScanSpeed": 86,
                "TotalSales": 77,
                "AverageLinesPerSale": 5,
                "TotalLines": 385,
                "LineVoidRate": 0.0564,
                "ReceiptVoidRate": 0.0366,
                "LinesVoided": 22,
                "ReceiptsVoided": 3,
                "RefundValue": 8688,
                "AverageReceiptCost": 4468,
                "AverageLineCost": 490,
                "TotalSaleAmount": 344036
            },
            {
                "site": 1233,
                "team": "Green",
                "agent": 12330013,
                "date": "14/12/2020",
                "ScanSpeed": 23,
                "TotalSales": 16,
                "AverageLinesPerSale": 7,
                "TotalLines": 112,
                "LineVoidRate": 0.0177,
                "ReceiptVoidRate": 0.0282,
                "LinesVoided": 2,
                "ReceiptsVoided": 1,
                "RefundValue": 11186,
                "AverageReceiptCost": 3209,
                "AverageLineCost": 198,
                "TotalSaleAmount": 51344
            },
            {
                "site": 1233,
                "team": "Green",
                "agent": 12330013,
                "date": "21/12/2020",
                "ScanSpeed": 68,
                "TotalSales": 73,
                "AverageLinesPerSale": 10,
                "TotalLines": 730,
                "LineVoidRate": 0.0096,
                "ReceiptVoidRate": 0.0353,
                "LinesVoided": 8,
                "ReceiptsVoided": 3,
                "RefundValue": 12708,
                "AverageReceiptCost": 443,
                "AverageLineCost": 122,
                "TotalSaleAmount": 32339
            },
            {
                "site": 1233,
                "team": "Green",
                "agent": 12330013,
                "date": "28/12/2020",
                "ScanSpeed": 90,
                "TotalSales": 108,
                "AverageLinesPerSale": 11,
                "TotalLines": 1188,
                "LineVoidRate": 0.0676,
                "ReceiptVoidRate": 0.0071,
                "LinesVoided": 81,
                "ReceiptsVoided": 1,
                "RefundValue": 4630,
                "AverageReceiptCost": 2465,
                "AverageLineCost": 313,
                "TotalSaleAmount": 266220
            },
            {
                "site": 1233,
                "team": "Green",
                "agent": 12330014,
                "date": "26/10/2020",
                "ScanSpeed": 58,
                "TotalSales": 163,
                "AverageLinesPerSale": 5,
                "TotalLines": 815,
                "LineVoidRate": 0.0326,
                "ReceiptVoidRate": 0.0149,
                "LinesVoided": 27,
                "ReceiptsVoided": 3,
                "RefundValue": 9018,
                "AverageReceiptCost": 2964,
                "AverageLineCost": 294,
                "TotalSaleAmount": 483132
            },
            {
                "site": 1233,
                "team": "Green",
                "agent": 12330014,
                "date": "02/11/2020",
                "ScanSpeed": 56,
                "TotalSales": 74,
                "AverageLinesPerSale": 9,
                "TotalLines": 666,
                "LineVoidRate": 0.0208,
                "ReceiptVoidRate": 0.0278,
                "LinesVoided": 14,
                "ReceiptsVoided": 3,
                "RefundValue": 17788,
                "AverageReceiptCost": 4163,
                "AverageLineCost": 370,
                "TotalSaleAmount": 308062
            },
            {
                "site": 1233,
                "team": "Green",
                "agent": 12330014,
                "date": "09/11/2020",
                "ScanSpeed": 23,
                "TotalSales": 168,
                "AverageLinesPerSale": 3,
                "TotalLines": 504,
                "LineVoidRate": 0.0232,
                "ReceiptVoidRate": 0.0148,
                "LinesVoided": 12,
                "ReceiptsVoided": 3,
                "RefundValue": 2974,
                "AverageReceiptCost": 3129,
                "AverageLineCost": 69,
                "TotalSaleAmount": 525672
            },
            {
                "site": 1233,
                "team": "Green",
                "agent": 12330014,
                "date": "16/11/2020",
                "ScanSpeed": 76,
                "TotalSales": 170,
                "AverageLinesPerSale": 6,
                "TotalLines": 1020,
                "LineVoidRate": 0.0059,
                "ReceiptVoidRate": 0.0062,
                "LinesVoided": 7,
                "ReceiptsVoided": 2,
                "RefundValue": 40169,
                "AverageReceiptCost": 3021,
                "AverageLineCost": 237,
                "TotalSaleAmount": 513570
            },
            {
                "site": 1233,
                "team": "Green",
                "agent": 12330014,
                "date": "23/11/2020",
                "ScanSpeed": 40,
                "TotalSales": 90,
                "AverageLinesPerSale": 10,
                "TotalLines": 900,
                "LineVoidRate": 0.0363,
                "ReceiptVoidRate": 0.0382,
                "LinesVoided": 33,
                "ReceiptsVoided": 4,
                "RefundValue": 13609,
                "AverageReceiptCost": 3199,
                "AverageLineCost": 494,
                "TotalSaleAmount": 287910
            },
            {
                "site": 1233,
                "team": "Green",
                "agent": 12330014,
                "date": "30/11/2020",
                "ScanSpeed": 90,
                "TotalSales": 33,
                "AverageLinesPerSale": 7,
                "TotalLines": 231,
                "LineVoidRate": 0.0141,
                "ReceiptVoidRate": 0.0254,
                "LinesVoided": 4,
                "ReceiptsVoided": 1,
                "RefundValue": 12057,
                "AverageReceiptCost": 1117,
                "AverageLineCost": 170,
                "TotalSaleAmount": 36861
            },
            {
                "site": 1233,
                "team": "Green",
                "agent": 12330014,
                "date": "07/12/2020",
                "ScanSpeed": 78,
                "TotalSales": 36,
                "AverageLinesPerSale": 4,
                "TotalLines": 144,
                "LineVoidRate": 0.0488,
                "ReceiptVoidRate": 0.0018,
                "LinesVoided": 8,
                "ReceiptsVoided": 1,
                "RefundValue": 4160,
                "AverageReceiptCost": 1999,
                "AverageLineCost": 203,
                "TotalSaleAmount": 71964
            },
            {
                "site": 1233,
                "team": "Green",
                "agent": 12330014,
                "date": "14/12/2020",
                "ScanSpeed": 69,
                "TotalSales": 73,
                "AverageLinesPerSale": 9,
                "TotalLines": 657,
                "LineVoidRate": 0.0168,
                "ReceiptVoidRate": 0.0263,
                "LinesVoided": 12,
                "ReceiptsVoided": 2,
                "RefundValue": 10595,
                "AverageReceiptCost": 2946,
                "AverageLineCost": 178,
                "TotalSaleAmount": 215058
            },
            {
                "site": 1233,
                "team": "Green",
                "agent": 12330014,
                "date": "21/12/2020",
                "ScanSpeed": 35,
                "TotalSales": 57,
                "AverageLinesPerSale": 5,
                "TotalLines": 285,
                "LineVoidRate": 0.0678,
                "ReceiptVoidRate": 0.0178,
                "LinesVoided": 20,
                "ReceiptsVoided": 2,
                "RefundValue": 6681,
                "AverageReceiptCost": 1877,
                "AverageLineCost": 453,
                "TotalSaleAmount": 106989
            },
            {
                "site": 1233,
                "team": "Green",
                "agent": 12330014,
                "date": "28/12/2020",
                "ScanSpeed": 33,
                "TotalSales": 50,
                "AverageLinesPerSale": 3,
                "TotalLines": 150,
                "LineVoidRate": 0.0495,
                "ReceiptVoidRate": 0.029,
                "LinesVoided": 8,
                "ReceiptsVoided": 2,
                "RefundValue": 3232,
                "AverageReceiptCost": 3914,
                "AverageLineCost": 160,
                "TotalSaleAmount": 195700
            },
            {
                "site": 1233,
                "team": "Green",
                "agent": 12330015,
                "date": "26/10/2020",
                "ScanSpeed": 70,
                "TotalSales": 163,
                "AverageLinesPerSale": 6,
                "TotalLines": 978,
                "LineVoidRate": 0.0353,
                "ReceiptVoidRate": 0.004,
                "LinesVoided": 35,
                "ReceiptsVoided": 1,
                "RefundValue": 8612,
                "AverageReceiptCost": 2851,
                "AverageLineCost": 304,
                "TotalSaleAmount": 464713
            },
            {
                "site": 1233,
                "team": "Green",
                "agent": 12330015,
                "date": "02/11/2020",
                "ScanSpeed": 55,
                "TotalSales": 117,
                "AverageLinesPerSale": 7,
                "TotalLines": 819,
                "LineVoidRate": 0.0319,
                "ReceiptVoidRate": 0.0019,
                "LinesVoided": 27,
                "ReceiptsVoided": 1,
                "RefundValue": 4671,
                "AverageReceiptCost": 3888,
                "AverageLineCost": 149,
                "TotalSaleAmount": 454896
            },
            {
                "site": 1233,
                "team": "Green",
                "agent": 12330015,
                "date": "09/11/2020",
                "ScanSpeed": 29,
                "TotalSales": 99,
                "AverageLinesPerSale": 11,
                "TotalLines": 1089,
                "LineVoidRate": 0.0262,
                "ReceiptVoidRate": 0.0329,
                "LinesVoided": 29,
                "ReceiptsVoided": 4,
                "RefundValue": 17748,
                "AverageReceiptCost": 339,
                "AverageLineCost": 465,
                "TotalSaleAmount": 33561
            },
            {
                "site": 1233,
                "team": "Green",
                "agent": 12330015,
                "date": "16/11/2020",
                "ScanSpeed": 60,
                "TotalSales": 80,
                "AverageLinesPerSale": 4,
                "TotalLines": 320,
                "LineVoidRate": 0.0597,
                "ReceiptVoidRate": 0.0012,
                "LinesVoided": 20,
                "ReceiptsVoided": 1,
                "RefundValue": 2881,
                "AverageReceiptCost": 1795,
                "AverageLineCost": 172,
                "TotalSaleAmount": 143600
            },
            {
                "site": 1233,
                "team": "Green",
                "agent": 12330015,
                "date": "23/11/2020",
                "ScanSpeed": 68,
                "TotalSales": 45,
                "AverageLinesPerSale": 11,
                "TotalLines": 495,
                "LineVoidRate": 0.0683,
                "ReceiptVoidRate": 0.0175,
                "LinesVoided": 34,
                "ReceiptsVoided": 1,
                "RefundValue": 1537,
                "AverageReceiptCost": 1673,
                "AverageLineCost": 105,
                "TotalSaleAmount": 75285
            },
            {
                "site": 1233,
                "team": "Green",
                "agent": 12330015,
                "date": "30/11/2020",
                "ScanSpeed": 37,
                "TotalSales": 67,
                "AverageLinesPerSale": 9,
                "TotalLines": 603,
                "LineVoidRate": 0.0145,
                "ReceiptVoidRate": 0.0393,
                "LinesVoided": 9,
                "ReceiptsVoided": 3,
                "RefundValue": 13379,
                "AverageReceiptCost": 604,
                "AverageLineCost": 194,
                "TotalSaleAmount": 40468
            },
            {
                "site": 1233,
                "team": "Green",
                "agent": 12330015,
                "date": "07/12/2020",
                "ScanSpeed": 66,
                "TotalSales": 26,
                "AverageLinesPerSale": 3,
                "TotalLines": 78,
                "LineVoidRate": 0.0087,
                "ReceiptVoidRate": 0.0094,
                "LinesVoided": 1,
                "ReceiptsVoided": 1,
                "RefundValue": 34483,
                "AverageReceiptCost": 4106,
                "AverageLineCost": 300,
                "TotalSaleAmount": 106756
            },
            {
                "site": 1233,
                "team": "Green",
                "agent": 12330015,
                "date": "14/12/2020",
                "ScanSpeed": 71,
                "TotalSales": 58,
                "AverageLinesPerSale": 4,
                "TotalLines": 232,
                "LineVoidRate": 0.0603,
                "ReceiptVoidRate": 0.0193,
                "LinesVoided": 14,
                "ReceiptsVoided": 2,
                "RefundValue": 3400,
                "AverageReceiptCost": 410,
                "AverageLineCost": 205,
                "TotalSaleAmount": 23780
            },
            {
                "site": 1233,
                "team": "Green",
                "agent": 12330015,
                "date": "21/12/2020",
                "ScanSpeed": 83,
                "TotalSales": 33,
                "AverageLinesPerSale": 9,
                "TotalLines": 297,
                "LineVoidRate": 0.0574,
                "ReceiptVoidRate": 0.0389,
                "LinesVoided": 18,
                "ReceiptsVoided": 2,
                "RefundValue": 8571,
                "AverageReceiptCost": 1512,
                "AverageLineCost": 492,
                "TotalSaleAmount": 49896
            },
            {
                "site": 1233,
                "team": "Green",
                "agent": 12330015,
                "date": "28/12/2020",
                "ScanSpeed": 24,
                "TotalSales": 50,
                "AverageLinesPerSale": 11,
                "TotalLines": 550,
                "LineVoidRate": 0.0224,
                "ReceiptVoidRate": 0.0033,
                "LinesVoided": 13,
                "ReceiptsVoided": 1,
                "RefundValue": 9330,
                "AverageReceiptCost": 1663,
                "AverageLineCost": 209,
                "TotalSaleAmount": 83150
            },
            {
                "site": 1233,
                "team": "Green",
                "agent": 12330016,
                "date": "26/10/2020",
                "ScanSpeed": 61,
                "TotalSales": 45,
                "AverageLinesPerSale": 11,
                "TotalLines": 495,
                "LineVoidRate": 0.0171,
                "ReceiptVoidRate": 0.0265,
                "LinesVoided": 9,
                "ReceiptsVoided": 2,
                "RefundValue": 18012,
                "AverageReceiptCost": 3310,
                "AverageLineCost": 308,
                "TotalSaleAmount": 148950
            },
            {
                "site": 1233,
                "team": "Green",
                "agent": 12330016,
                "date": "02/11/2020",
                "ScanSpeed": 38,
                "TotalSales": 15,
                "AverageLinesPerSale": 8,
                "TotalLines": 120,
                "LineVoidRate": 0.027,
                "ReceiptVoidRate": 0.0194,
                "LinesVoided": 4,
                "ReceiptsVoided": 1,
                "RefundValue": 2111,
                "AverageReceiptCost": 1041,
                "AverageLineCost": 57,
                "TotalSaleAmount": 15615
            },
            {
                "site": 1233,
                "team": "Green",
                "agent": 12330016,
                "date": "09/11/2020",
                "ScanSpeed": 77,
                "TotalSales": 25,
                "AverageLinesPerSale": 10,
                "TotalLines": 250,
                "LineVoidRate": 0.0133,
                "ReceiptVoidRate": 0.0058,
                "LinesVoided": 4,
                "ReceiptsVoided": 1,
                "RefundValue": 16391,
                "AverageReceiptCost": 3157,
                "AverageLineCost": 218,
                "TotalSaleAmount": 78925
            },
            {
                "site": 1233,
                "team": "Green",
                "agent": 12330016,
                "date": "16/11/2020",
                "ScanSpeed": 45,
                "TotalSales": 139,
                "AverageLinesPerSale": 7,
                "TotalLines": 973,
                "LineVoidRate": 0.0476,
                "ReceiptVoidRate": 0.0082,
                "LinesVoided": 47,
                "ReceiptsVoided": 2,
                "RefundValue": 2227,
                "AverageReceiptCost": 2551,
                "AverageLineCost": 106,
                "TotalSaleAmount": 354589
            },
            {
                "site": 1233,
                "team": "Green",
                "agent": 12330016,
                "date": "23/11/2020",
                "ScanSpeed": 55,
                "TotalSales": 100,
                "AverageLinesPerSale": 4,
                "TotalLines": 400,
                "LineVoidRate": 0.0483,
                "ReceiptVoidRate": 0.0279,
                "LinesVoided": 20,
                "ReceiptsVoided": 3,
                "RefundValue": 8344,
                "AverageReceiptCost": 1041,
                "AverageLineCost": 403,
                "TotalSaleAmount": 104100
            },
            {
                "site": 1233,
                "team": "Green",
                "agent": 12330016,
                "date": "30/11/2020",
                "ScanSpeed": 29,
                "TotalSales": 143,
                "AverageLinesPerSale": 6,
                "TotalLines": 858,
                "LineVoidRate": 0.0666,
                "ReceiptVoidRate": 0.0028,
                "LinesVoided": 58,
                "ReceiptsVoided": 1,
                "RefundValue": 2267,
                "AverageReceiptCost": 1756,
                "AverageLineCost": 151,
                "TotalSaleAmount": 251108
            },
            {
                "site": 1233,
                "team": "Green",
                "agent": 12330016,
                "date": "07/12/2020",
                "ScanSpeed": 68,
                "TotalSales": 159,
                "AverageLinesPerSale": 6,
                "TotalLines": 954,
                "LineVoidRate": 0.0247,
                "ReceiptVoidRate": 0.0214,
                "LinesVoided": 24,
                "ReceiptsVoided": 4,
                "RefundValue": 3401,
                "AverageReceiptCost": 2480,
                "AverageLineCost": 84,
                "TotalSaleAmount": 394320
            },
            {
                "site": 1233,
                "team": "Green",
                "agent": 12330016,
                "date": "14/12/2020",
                "ScanSpeed": 10,
                "TotalSales": 25,
                "AverageLinesPerSale": 11,
                "TotalLines": 275,
                "LineVoidRate": 0.0291,
                "ReceiptVoidRate": 0.0042,
                "LinesVoided": 9,
                "ReceiptsVoided": 1,
                "RefundValue": 5464,
                "AverageReceiptCost": 1551,
                "AverageLineCost": 159,
                "TotalSaleAmount": 38775
            },
            {
                "site": 1233,
                "team": "Green",
                "agent": 12330016,
                "date": "21/12/2020",
                "ScanSpeed": 13,
                "TotalSales": 82,
                "AverageLinesPerSale": 11,
                "TotalLines": 902,
                "LineVoidRate": 0.0636,
                "ReceiptVoidRate": 0.0316,
                "LinesVoided": 58,
                "ReceiptsVoided": 3,
                "RefundValue": 4135,
                "AverageReceiptCost": 1923,
                "AverageLineCost": 263,
                "TotalSaleAmount": 157686
            },
            {
                "site": 1233,
                "team": "Green",
                "agent": 12330016,
                "date": "28/12/2020",
                "ScanSpeed": 69,
                "TotalSales": 70,
                "AverageLinesPerSale": 6,
                "TotalLines": 420,
                "LineVoidRate": 0.0006,
                "ReceiptVoidRate": 0.0302,
                "LinesVoided": 1,
                "ReceiptsVoided": 3,
                "RefundValue": 668333,
                "AverageReceiptCost": 1553,
                "AverageLineCost": 401,
                "TotalSaleAmount": 108710
            },
            {
                "site": 1233,
                "team": "Green",
                "agent": 12330017,
                "date": "26/10/2020",
                "ScanSpeed": 34,
                "TotalSales": 151,
                "AverageLinesPerSale": 6,
                "TotalLines": 906,
                "LineVoidRate": 0.0152,
                "ReceiptVoidRate": 0.0066,
                "LinesVoided": 14,
                "ReceiptsVoided": 1,
                "RefundValue": 31053,
                "AverageReceiptCost": 486,
                "AverageLineCost": 472,
                "TotalSaleAmount": 73386
            },
            {
                "site": 1233,
                "team": "Green",
                "agent": 12330017,
                "date": "02/11/2020",
                "ScanSpeed": 63,
                "TotalSales": 164,
                "AverageLinesPerSale": 8,
                "TotalLines": 1312,
                "LineVoidRate": 0.0002,
                "ReceiptVoidRate": 0.0139,
                "LinesVoided": 1,
                "ReceiptsVoided": 3,
                "RefundValue": 770000,
                "AverageReceiptCost": 1314,
                "AverageLineCost": 154,
                "TotalSaleAmount": 215496
            },
            {
                "site": 1233,
                "team": "Green",
                "agent": 12330017,
                "date": "09/11/2020",
                "ScanSpeed": 54,
                "TotalSales": 167,
                "AverageLinesPerSale": 6,
                "TotalLines": 1002,
                "LineVoidRate": 0.0677,
                "ReceiptVoidRate": 0.0093,
                "LinesVoided": 68,
                "ReceiptsVoided": 2,
                "RefundValue": 2659,
                "AverageReceiptCost": 3620,
                "AverageLineCost": 180,
                "TotalSaleAmount": 604540
            },
            {
                "site": 1233,
                "team": "Green",
                "agent": 12330017,
                "date": "16/11/2020",
                "ScanSpeed": 28,
                "TotalSales": 81,
                "AverageLinesPerSale": 7,
                "TotalLines": 567,
                "LineVoidRate": 0.0691,
                "ReceiptVoidRate": 0.0369,
                "LinesVoided": 40,
                "ReceiptsVoided": 3,
                "RefundValue": 4052,
                "AverageReceiptCost": 1503,
                "AverageLineCost": 280,
                "TotalSaleAmount": 121743
            },
            {
                "site": 1233,
                "team": "Green",
                "agent": 12330017,
                "date": "23/11/2020",
                "ScanSpeed": 26,
                "TotalSales": 66,
                "AverageLinesPerSale": 3,
                "TotalLines": 198,
                "LineVoidRate": 0.056,
                "ReceiptVoidRate": 0.036,
                "LinesVoided": 12,
                "ReceiptsVoided": 3,
                "RefundValue": 7143,
                "AverageReceiptCost": 1161,
                "AverageLineCost": 400,
                "TotalSaleAmount": 76626
            },
            {
                "site": 1233,
                "team": "Green",
                "agent": 12330017,
                "date": "30/11/2020",
                "ScanSpeed": 77,
                "TotalSales": 57,
                "AverageLinesPerSale": 4,
                "TotalLines": 228,
                "LineVoidRate": 0.004,
                "ReceiptVoidRate": 0.0205,
                "LinesVoided": 1,
                "ReceiptsVoided": 2,
                "RefundValue": 121750,
                "AverageReceiptCost": 821,
                "AverageLineCost": 487,
                "TotalSaleAmount": 46797
            },
            {
                "site": 1233,
                "team": "Green",
                "agent": 12330017,
                "date": "07/12/2020",
                "ScanSpeed": 65,
                "TotalSales": 162,
                "AverageLinesPerSale": 10,
                "TotalLines": 1620,
                "LineVoidRate": 0.0064,
                "ReceiptVoidRate": 0.0365,
                "LinesVoided": 11,
                "ReceiptsVoided": 6,
                "RefundValue": 12500,
                "AverageReceiptCost": 2861,
                "AverageLineCost": 80,
                "TotalSaleAmount": 463482
            },
            {
                "site": 1233,
                "team": "Green",
                "agent": 12330017,
                "date": "14/12/2020",
                "ScanSpeed": 50,
                "TotalSales": 58,
                "AverageLinesPerSale": 3,
                "TotalLines": 174,
                "LineVoidRate": 0.0555,
                "ReceiptVoidRate": 0.0205,
                "LinesVoided": 10,
                "ReceiptsVoided": 2,
                "RefundValue": 7459,
                "AverageReceiptCost": 1826,
                "AverageLineCost": 414,
                "TotalSaleAmount": 105908
            },
            {
                "site": 1233,
                "team": "Green",
                "agent": 12330017,
                "date": "21/12/2020",
                "ScanSpeed": 10,
                "TotalSales": 81,
                "AverageLinesPerSale": 5,
                "TotalLines": 405,
                "LineVoidRate": 0.0362,
                "ReceiptVoidRate": 0.0256,
                "LinesVoided": 15,
                "ReceiptsVoided": 3,
                "RefundValue": 8066,
                "AverageReceiptCost": 1268,
                "AverageLineCost": 292,
                "TotalSaleAmount": 102708
            },
            {
                "site": 1233,
                "team": "Green",
                "agent": 12330017,
                "date": "28/12/2020",
                "ScanSpeed": 90,
                "TotalSales": 59,
                "AverageLinesPerSale": 7,
                "TotalLines": 413,
                "LineVoidRate": 0.0662,
                "ReceiptVoidRate": 0.001,
                "LinesVoided": 28,
                "ReceiptsVoided": 1,
                "RefundValue": 1828,
                "AverageReceiptCost": 3030,
                "AverageLineCost": 121,
                "TotalSaleAmount": 178770
            },
            {
                "site": 1468,
                "team": "Green",
                "agent": 14680018,
                "date": "26/10/2020",
                "ScanSpeed": 82,
                "TotalSales": 148,
                "AverageLinesPerSale": 5,
                "TotalLines": 740,
                "LineVoidRate": 0.0628,
                "ReceiptVoidRate": 0.0101,
                "LinesVoided": 47,
                "ReceiptsVoided": 2,
                "RefundValue": 1258,
                "AverageReceiptCost": 1514,
                "AverageLineCost": 79,
                "TotalSaleAmount": 224072
            },
            {
                "site": 1468,
                "team": "Green",
                "agent": 14680018,
                "date": "02/11/2020",
                "ScanSpeed": 42,
                "TotalSales": 107,
                "AverageLinesPerSale": 9,
                "TotalLines": 963,
                "LineVoidRate": 0.0031,
                "ReceiptVoidRate": 0.0292,
                "LinesVoided": 3,
                "ReceiptsVoided": 4,
                "RefundValue": 47742,
                "AverageReceiptCost": 3553,
                "AverageLineCost": 148,
                "TotalSaleAmount": 380171
            },
            {
                "site": 1468,
                "team": "Green",
                "agent": 14680018,
                "date": "09/11/2020",
                "ScanSpeed": 14,
                "TotalSales": 69,
                "AverageLinesPerSale": 8,
                "TotalLines": 552,
                "LineVoidRate": 0.0112,
                "ReceiptVoidRate": 0.0339,
                "LinesVoided": 7,
                "ReceiptsVoided": 3,
                "RefundValue": 23929,
                "AverageReceiptCost": 2412,
                "AverageLineCost": 268,
                "TotalSaleAmount": 166428
            },
            {
                "site": 1468,
                "team": "Green",
                "agent": 14680018,
                "date": "16/11/2020",
                "ScanSpeed": 36,
                "TotalSales": 174,
                "AverageLinesPerSale": 9,
                "TotalLines": 1566,
                "LineVoidRate": 0.0044,
                "ReceiptVoidRate": 0.0372,
                "LinesVoided": 7,
                "ReceiptsVoided": 7,
                "RefundValue": 36364,
                "AverageReceiptCost": 3500,
                "AverageLineCost": 160,
                "TotalSaleAmount": 609000
            },
            {
                "site": 1468,
                "team": "Green",
                "agent": 14680018,
                "date": "23/11/2020",
                "ScanSpeed": 37,
                "TotalSales": 112,
                "AverageLinesPerSale": 7,
                "TotalLines": 784,
                "LineVoidRate": 0.018,
                "ReceiptVoidRate": 0.0029,
                "LinesVoided": 15,
                "ReceiptsVoided": 1,
                "RefundValue": 14111,
                "AverageReceiptCost": 4370,
                "AverageLineCost": 254,
                "TotalSaleAmount": 489440
            },
            {
                "site": 1468,
                "team": "Green",
                "agent": 14680018,
                "date": "30/11/2020",
                "ScanSpeed": 78,
                "TotalSales": 49,
                "AverageLinesPerSale": 6,
                "TotalLines": 294,
                "LineVoidRate": 0.0225,
                "ReceiptVoidRate": 0.0225,
                "LinesVoided": 7,
                "ReceiptsVoided": 2,
                "RefundValue": 11867,
                "AverageReceiptCost": 1592,
                "AverageLineCost": 267,
                "TotalSaleAmount": 78008
            },
            {
                "site": 1468,
                "team": "Green",
                "agent": 14680018,
                "date": "07/12/2020",
                "ScanSpeed": 20,
                "TotalSales": 64,
                "AverageLinesPerSale": 6,
                "TotalLines": 384,
                "LineVoidRate": 0.0566,
                "ReceiptVoidRate": 0.0263,
                "LinesVoided": 22,
                "ReceiptsVoided": 2,
                "RefundValue": 5265,
                "AverageReceiptCost": 602,
                "AverageLineCost": 298,
                "TotalSaleAmount": 38528
            },
            {
                "site": 1468,
                "team": "Green",
                "agent": 14680018,
                "date": "14/12/2020",
                "ScanSpeed": 61,
                "TotalSales": 123,
                "AverageLinesPerSale": 4,
                "TotalLines": 492,
                "LineVoidRate": 0.0677,
                "ReceiptVoidRate": 0.0371,
                "LinesVoided": 34,
                "ReceiptsVoided": 5,
                "RefundValue": 2378,
                "AverageReceiptCost": 3192,
                "AverageLineCost": 161,
                "TotalSaleAmount": 392616
            },
            {
                "site": 1468,
                "team": "Green",
                "agent": 14680018,
                "date": "21/12/2020",
                "ScanSpeed": 80,
                "TotalSales": 100,
                "AverageLinesPerSale": 3,
                "TotalLines": 300,
                "LineVoidRate": 0.0625,
                "ReceiptVoidRate": 0.0051,
                "LinesVoided": 19,
                "ReceiptsVoided": 1,
                "RefundValue": 7360,
                "AverageReceiptCost": 645,
                "AverageLineCost": 460,
                "TotalSaleAmount": 64500
            },
            {
                "site": 1468,
                "team": "Green",
                "agent": 14680018,
                "date": "28/12/2020",
                "ScanSpeed": 44,
                "TotalSales": 45,
                "AverageLinesPerSale": 11,
                "TotalLines": 495,
                "LineVoidRate": 0.0026,
                "ReceiptVoidRate": 0.0183,
                "LinesVoided": 2,
                "ReceiptsVoided": 1,
                "RefundValue": 144615,
                "AverageReceiptCost": 4014,
                "AverageLineCost": 376,
                "TotalSaleAmount": 180630
            },
            {
                "site": 1468,
                "team": "Green",
                "agent": 14680019,
                "date": "26/10/2020",
                "ScanSpeed": 87,
                "TotalSales": 55,
                "AverageLinesPerSale": 5,
                "TotalLines": 275,
                "LineVoidRate": 0.0071,
                "ReceiptVoidRate": 0.0216,
                "LinesVoided": 2,
                "ReceiptsVoided": 2,
                "RefundValue": 20563,
                "AverageReceiptCost": 4389,
                "AverageLineCost": 146,
                "TotalSaleAmount": 241395
            },
            {
                "site": 1468,
                "team": "Green",
                "agent": 14680019,
                "date": "02/11/2020",
                "ScanSpeed": 73,
                "TotalSales": 91,
                "AverageLinesPerSale": 3,
                "TotalLines": 273,
                "LineVoidRate": 0.0659,
                "ReceiptVoidRate": 0.0254,
                "LinesVoided": 18,
                "ReceiptsVoided": 3,
                "RefundValue": 7102,
                "AverageReceiptCost": 883,
                "AverageLineCost": 468,
                "TotalSaleAmount": 80353
            },
            {
                "site": 1468,
                "team": "Green",
                "agent": 14680019,
                "date": "09/11/2020",
                "ScanSpeed": 46,
                "TotalSales": 122,
                "AverageLinesPerSale": 9,
                "TotalLines": 1098,
                "LineVoidRate": 0.0306,
                "ReceiptVoidRate": 0.0311,
                "LinesVoided": 34,
                "ReceiptsVoided": 4,
                "RefundValue": 7614,
                "AverageReceiptCost": 3467,
                "AverageLineCost": 233,
                "TotalSaleAmount": 422974
            },
            {
                "site": 1468,
                "team": "Green",
                "agent": 14680019,
                "date": "16/11/2020",
                "ScanSpeed": 88,
                "TotalSales": 140,
                "AverageLinesPerSale": 8,
                "TotalLines": 1120,
                "LineVoidRate": 0.0653,
                "ReceiptVoidRate": 0.0163,
                "LinesVoided": 74,
                "ReceiptsVoided": 3,
                "RefundValue": 1317,
                "AverageReceiptCost": 3913,
                "AverageLineCost": 86,
                "TotalSaleAmount": 547820
            },
            {
                "site": 1468,
                "team": "Green",
                "agent": 14680019,
                "date": "23/11/2020",
                "ScanSpeed": 83,
                "TotalSales": 96,
                "AverageLinesPerSale": 11,
                "TotalLines": 1056,
                "LineVoidRate": 0.0299,
                "ReceiptVoidRate": 0.0083,
                "LinesVoided": 32,
                "ReceiptsVoided": 1,
                "RefundValue": 6288,
                "AverageReceiptCost": 1072,
                "AverageLineCost": 188,
                "TotalSaleAmount": 102912
            },
            {
                "site": 1468,
                "team": "Green",
                "agent": 14680019,
                "date": "30/11/2020",
                "ScanSpeed": 41,
                "TotalSales": 155,
                "AverageLinesPerSale": 5,
                "TotalLines": 775,
                "LineVoidRate": 0.0236,
                "ReceiptVoidRate": 0.0228,
                "LinesVoided": 19,
                "ReceiptsVoided": 4,
                "RefundValue": 10127,
                "AverageReceiptCost": 1768,
                "AverageLineCost": 239,
                "TotalSaleAmount": 274040
            },
            {
                "site": 1468,
                "team": "Green",
                "agent": 14680019,
                "date": "07/12/2020",
                "ScanSpeed": 50,
                "TotalSales": 16,
                "AverageLinesPerSale": 7,
                "TotalLines": 112,
                "LineVoidRate": 0.0679,
                "ReceiptVoidRate": 0.0365,
                "LinesVoided": 8,
                "ReceiptsVoided": 1,
                "RefundValue": 3608,
                "AverageReceiptCost": 1937,
                "AverageLineCost": 245,
                "TotalSaleAmount": 30992
            },
            {
                "site": 1468,
                "team": "Green",
                "agent": 14680019,
                "date": "14/12/2020",
                "ScanSpeed": 90,
                "TotalSales": 95,
                "AverageLinesPerSale": 8,
                "TotalLines": 760,
                "LineVoidRate": 0.0165,
                "ReceiptVoidRate": 0.0184,
                "LinesVoided": 13,
                "ReceiptsVoided": 2,
                "RefundValue": 13879,
                "AverageReceiptCost": 4096,
                "AverageLineCost": 229,
                "TotalSaleAmount": 389120
            },
            {
                "site": 1468,
                "team": "Green",
                "agent": 14680019,
                "date": "21/12/2020",
                "ScanSpeed": 76,
                "TotalSales": 17,
                "AverageLinesPerSale": 7,
                "TotalLines": 119,
                "LineVoidRate": 0.059,
                "ReceiptVoidRate": 0.0002,
                "LinesVoided": 8,
                "ReceiptsVoided": 1,
                "RefundValue": 1983,
                "AverageReceiptCost": 1840,
                "AverageLineCost": 117,
                "TotalSaleAmount": 31280
            },
            {
                "site": 1468,
                "team": "Green",
                "agent": 14680019,
                "date": "28/12/2020",
                "ScanSpeed": 16,
                "TotalSales": 167,
                "AverageLinesPerSale": 4,
                "TotalLines": 668,
                "LineVoidRate": 0.0505,
                "ReceiptVoidRate": 0.0035,
                "LinesVoided": 34,
                "ReceiptsVoided": 1,
                "RefundValue": 4515,
                "AverageReceiptCost": 3133,
                "AverageLineCost": 228,
                "TotalSaleAmount": 523211
            },
            {
                "site": 1468,
                "team": "Green",
                "agent": 14680020,
                "date": "26/10/2020",
                "ScanSpeed": 23,
                "TotalSales": 94,
                "AverageLinesPerSale": 7,
                "TotalLines": 658,
                "LineVoidRate": 0.0036,
                "ReceiptVoidRate": 0.0117,
                "LinesVoided": 3,
                "ReceiptsVoided": 2,
                "RefundValue": 130000,
                "AverageReceiptCost": 1623,
                "AverageLineCost": 468,
                "TotalSaleAmount": 152562
            },
            {
                "site": 1468,
                "team": "Green",
                "agent": 14680020,
                "date": "02/11/2020",
                "ScanSpeed": 24,
                "TotalSales": 155,
                "AverageLinesPerSale": 3,
                "TotalLines": 465,
                "LineVoidRate": 0.0224,
                "ReceiptVoidRate": 0.0054,
                "LinesVoided": 11,
                "ReceiptsVoided": 1,
                "RefundValue": 15893,
                "AverageReceiptCost": 2930,
                "AverageLineCost": 356,
                "TotalSaleAmount": 454150
            },
            {
                "site": 1468,
                "team": "Green",
                "agent": 14680020,
                "date": "09/11/2020",
                "ScanSpeed": 88,
                "TotalSales": 53,
                "AverageLinesPerSale": 3,
                "TotalLines": 159,
                "LineVoidRate": 0.055,
                "ReceiptVoidRate": 0.0334,
                "LinesVoided": 9,
                "ReceiptsVoided": 2,
                "RefundValue": 5364,
                "AverageReceiptCost": 378,
                "AverageLineCost": 295,
                "TotalSaleAmount": 20034
            },
            {
                "site": 1468,
                "team": "Green",
                "agent": 14680020,
                "date": "16/11/2020",
                "ScanSpeed": 42,
                "TotalSales": 120,
                "AverageLinesPerSale": 11,
                "TotalLines": 1320,
                "LineVoidRate": 0.0647,
                "ReceiptVoidRate": 0.0168,
                "LinesVoided": 86,
                "ReceiptsVoided": 3,
                "RefundValue": 7388,
                "AverageReceiptCost": 531,
                "AverageLineCost": 478,
                "TotalSaleAmount": 63720
            },
            {
                "site": 1468,
                "team": "Green",
                "agent": 14680020,
                "date": "23/11/2020",
                "ScanSpeed": 81,
                "TotalSales": 75,
                "AverageLinesPerSale": 3,
                "TotalLines": 225,
                "LineVoidRate": 0.0373,
                "ReceiptVoidRate": 0.0101,
                "LinesVoided": 9,
                "ReceiptsVoided": 1,
                "RefundValue": 2761,
                "AverageReceiptCost": 2845,
                "AverageLineCost": 103,
                "TotalSaleAmount": 213375
            },
            {
                "site": 1468,
                "team": "Green",
                "agent": 14680020,
                "date": "30/11/2020",
                "ScanSpeed": 26,
                "TotalSales": 49,
                "AverageLinesPerSale": 7,
                "TotalLines": 343,
                "LineVoidRate": 0.0543,
                "ReceiptVoidRate": 0.0204,
                "LinesVoided": 19,
                "ReceiptsVoided": 1,
                "RefundValue": 1934,
                "AverageReceiptCost": 4367,
                "AverageLineCost": 105,
                "TotalSaleAmount": 213983
            },
            {
                "site": 1468,
                "team": "Green",
                "agent": 14680020,
                "date": "07/12/2020",
                "ScanSpeed": 12,
                "TotalSales": 151,
                "AverageLinesPerSale": 11,
                "TotalLines": 1661,
                "LineVoidRate": 0.0303,
                "ReceiptVoidRate": 0.01,
                "LinesVoided": 51,
                "ReceiptsVoided": 2,
                "RefundValue": 16304,
                "AverageReceiptCost": 2878,
                "AverageLineCost": 494,
                "TotalSaleAmount": 434578
            },
            {
                "site": 1468,
                "team": "Green",
                "agent": 14680020,
                "date": "14/12/2020",
                "ScanSpeed": 23,
                "TotalSales": 102,
                "AverageLinesPerSale": 10,
                "TotalLines": 1020,
                "LineVoidRate": 0.0523,
                "ReceiptVoidRate": 0.0006,
                "LinesVoided": 54,
                "ReceiptsVoided": 1,
                "RefundValue": 6692,
                "AverageReceiptCost": 2366,
                "AverageLineCost": 350,
                "TotalSaleAmount": 241332
            },
            {
                "site": 1468,
                "team": "Green",
                "agent": 14680020,
                "date": "21/12/2020",
                "ScanSpeed": 43,
                "TotalSales": 109,
                "AverageLinesPerSale": 7,
                "TotalLines": 763,
                "LineVoidRate": 0.0119,
                "ReceiptVoidRate": 0.016,
                "LinesVoided": 10,
                "ReceiptsVoided": 2,
                "RefundValue": 41008,
                "AverageReceiptCost": 2818,
                "AverageLineCost": 488,
                "TotalSaleAmount": 307162
            },
            {
                "site": 1468,
                "team": "Green",
                "agent": 14680020,
                "date": "28/12/2020",
                "ScanSpeed": 53,
                "TotalSales": 58,
                "AverageLinesPerSale": 7,
                "TotalLines": 406,
                "LineVoidRate": 0.0202,
                "ReceiptVoidRate": 0.0269,
                "LinesVoided": 9,
                "ReceiptsVoided": 2,
                "RefundValue": 19059,
                "AverageReceiptCost": 2131,
                "AverageLineCost": 385,
                "TotalSaleAmount": 123598
            },
            {
                "site": 1468,
                "team": "Green",
                "agent": 14680021,
                "date": "26/10/2020",
                "ScanSpeed": 46,
                "TotalSales": 31,
                "AverageLinesPerSale": 4,
                "TotalLines": 124,
                "LineVoidRate": 0.0189,
                "ReceiptVoidRate": 0.0084,
                "LinesVoided": 3,
                "ReceiptsVoided": 1,
                "RefundValue": 15079,
                "AverageReceiptCost": 2137,
                "AverageLineCost": 285,
                "TotalSaleAmount": 66247
            },
            {
                "site": 1468,
                "team": "Green",
                "agent": 14680021,
                "date": "02/11/2020",
                "ScanSpeed": 25,
                "TotalSales": 138,
                "AverageLinesPerSale": 6,
                "TotalLines": 828,
                "LineVoidRate": 0.0445,
                "ReceiptVoidRate": 0.0211,
                "LinesVoided": 37,
                "ReceiptsVoided": 3,
                "RefundValue": 5438,
                "AverageReceiptCost": 4367,
                "AverageLineCost": 242,
                "TotalSaleAmount": 602646
            },
            {
                "site": 1468,
                "team": "Green",
                "agent": 14680021,
                "date": "09/11/2020",
                "ScanSpeed": 37,
                "TotalSales": 22,
                "AverageLinesPerSale": 6,
                "TotalLines": 132,
                "LineVoidRate": 0.0201,
                "ReceiptVoidRate": 0.0053,
                "LinesVoided": 3,
                "ReceiptsVoided": 1,
                "RefundValue": 23134,
                "AverageReceiptCost": 1601,
                "AverageLineCost": 465,
                "TotalSaleAmount": 35222
            },
            {
                "site": 1468,
                "team": "Green",
                "agent": 14680021,
                "date": "16/11/2020",
                "ScanSpeed": 39,
                "TotalSales": 98,
                "AverageLinesPerSale": 11,
                "TotalLines": 1078,
                "LineVoidRate": 0.007,
                "ReceiptVoidRate": 0.0045,
                "LinesVoided": 8,
                "ReceiptsVoided": 1,
                "RefundValue": 64857,
                "AverageReceiptCost": 1423,
                "AverageLineCost": 454,
                "TotalSaleAmount": 139454
            },
            {
                "site": 1468,
                "team": "Green",
                "agent": 14680021,
                "date": "23/11/2020",
                "ScanSpeed": 77,
                "TotalSales": 49,
                "AverageLinesPerSale": 4,
                "TotalLines": 196,
                "LineVoidRate": 0.0313,
                "ReceiptVoidRate": 0.0138,
                "LinesVoided": 7,
                "ReceiptsVoided": 1,
                "RefundValue": 11629,
                "AverageReceiptCost": 1897,
                "AverageLineCost": 364,
                "TotalSaleAmount": 92953
            },
            {
                "site": 1468,
                "team": "Green",
                "agent": 14680021,
                "date": "30/11/2020",
                "ScanSpeed": 69,
                "TotalSales": 102,
                "AverageLinesPerSale": 5,
                "TotalLines": 510,
                "LineVoidRate": 0.0559,
                "ReceiptVoidRate": 0.0116,
                "LinesVoided": 29,
                "ReceiptsVoided": 2,
                "RefundValue": 6726,
                "AverageReceiptCost": 997,
                "AverageLineCost": 376,
                "TotalSaleAmount": 101694
            },
            {
                "site": 1468,
                "team": "Green",
                "agent": 14680021,
                "date": "07/12/2020",
                "ScanSpeed": 49,
                "TotalSales": 99,
                "AverageLinesPerSale": 6,
                "TotalLines": 594,
                "LineVoidRate": 0.0645,
                "ReceiptVoidRate": 0.0301,
                "LinesVoided": 39,
                "ReceiptsVoided": 3,
                "RefundValue": 7163,
                "AverageReceiptCost": 2873,
                "AverageLineCost": 462,
                "TotalSaleAmount": 284427
            },
            {
                "site": 1468,
                "team": "Green",
                "agent": 14680021,
                "date": "14/12/2020",
                "ScanSpeed": 80,
                "TotalSales": 43,
                "AverageLinesPerSale": 5,
                "TotalLines": 215,
                "LineVoidRate": 0.0314,
                "ReceiptVoidRate": 0.0092,
                "LinesVoided": 7,
                "ReceiptsVoided": 1,
                "RefundValue": 2643,
                "AverageReceiptCost": 2020,
                "AverageLineCost": 83,
                "TotalSaleAmount": 86860
            },
            {
                "site": 1468,
                "team": "Green",
                "agent": 14680021,
                "date": "21/12/2020",
                "ScanSpeed": 63,
                "TotalSales": 143,
                "AverageLinesPerSale": 6,
                "TotalLines": 858,
                "LineVoidRate": 0.0097,
                "ReceiptVoidRate": 0.0049,
                "LinesVoided": 9,
                "ReceiptsVoided": 1,
                "RefundValue": 9278,
                "AverageReceiptCost": 4270,
                "AverageLineCost": 90,
                "TotalSaleAmount": 610610
            },
            {
                "site": 1468,
                "team": "Green",
                "agent": 14680021,
                "date": "28/12/2020",
                "ScanSpeed": 14,
                "TotalSales": 148,
                "AverageLinesPerSale": 8,
                "TotalLines": 1184,
                "LineVoidRate": 0.0682,
                "ReceiptVoidRate": 0.0259,
                "LinesVoided": 81,
                "ReceiptsVoided": 4,
                "RefundValue": 3959,
                "AverageReceiptCost": 3941,
                "AverageLineCost": 270,
                "TotalSaleAmount": 583268
            },
            {
                "site": 1468,
                "team": "Red",
                "agent": 14680001,
                "date": "26/10/2020",
                "ScanSpeed": 61,
                "TotalSales": 108,
                "AverageLinesPerSale": 9,
                "TotalLines": 972,
                "LineVoidRate": 0.0495,
                "ReceiptVoidRate": 0.0255,
                "LinesVoided": 49,
                "ReceiptsVoided": 3,
                "RefundValue": 3111,
                "AverageReceiptCost": 682,
                "AverageLineCost": 154,
                "TotalSaleAmount": 73656
            },
            {
                "site": 1468,
                "team": "Red",
                "agent": 14680001,
                "date": "02/11/2020",
                "ScanSpeed": 61,
                "TotalSales": 104,
                "AverageLinesPerSale": 8,
                "TotalLines": 832,
                "LineVoidRate": 0.0124,
                "ReceiptVoidRate": 0.0351,
                "LinesVoided": 11,
                "ReceiptsVoided": 4,
                "RefundValue": 34113,
                "AverageReceiptCost": 2145,
                "AverageLineCost": 423,
                "TotalSaleAmount": 223080
            },
            {
                "site": 1468,
                "team": "Red",
                "agent": 14680001,
                "date": "09/11/2020",
                "ScanSpeed": 27,
                "TotalSales": 37,
                "AverageLinesPerSale": 4,
                "TotalLines": 148,
                "LineVoidRate": 0.0675,
                "ReceiptVoidRate": 0.0396,
                "LinesVoided": 10,
                "ReceiptsVoided": 2,
                "RefundValue": 1378,
                "AverageReceiptCost": 4465,
                "AverageLineCost": 93,
                "TotalSaleAmount": 165205
            },
            {
                "site": 1468,
                "team": "Red",
                "agent": 14680001,
                "date": "16/11/2020",
                "ScanSpeed": 31,
                "TotalSales": 19,
                "AverageLinesPerSale": 10,
                "TotalLines": 190,
                "LineVoidRate": 0.0372,
                "ReceiptVoidRate": 0.0163,
                "LinesVoided": 8,
                "ReceiptsVoided": 1,
                "RefundValue": 6425,
                "AverageReceiptCost": 3912,
                "AverageLineCost": 239,
                "TotalSaleAmount": 74328
            },
            {
                "site": 1468,
                "team": "Red",
                "agent": 14680001,
                "date": "23/11/2020",
                "ScanSpeed": 31,
                "TotalSales": 65,
                "AverageLinesPerSale": 9,
                "TotalLines": 585,
                "LineVoidRate": 0.0366,
                "ReceiptVoidRate": 0.03,
                "LinesVoided": 22,
                "ReceiptsVoided": 2,
                "RefundValue": 9016,
                "AverageReceiptCost": 1471,
                "AverageLineCost": 330,
                "TotalSaleAmount": 95615
            },
            {
                "site": 1468,
                "team": "Red",
                "agent": 14680001,
                "date": "30/11/2020",
                "ScanSpeed": 64,
                "TotalSales": 53,
                "AverageLinesPerSale": 6,
                "TotalLines": 318,
                "LineVoidRate": 0.0158,
                "ReceiptVoidRate": 0.029,
                "LinesVoided": 6,
                "ReceiptsVoided": 2,
                "RefundValue": 8228,
                "AverageReceiptCost": 3622,
                "AverageLineCost": 130,
                "TotalSaleAmount": 191966
            },
            {
                "site": 1468,
                "team": "Red",
                "agent": 14680001,
                "date": "07/12/2020",
                "ScanSpeed": 74,
                "TotalSales": 92,
                "AverageLinesPerSale": 6,
                "TotalLines": 552,
                "LineVoidRate": 0.0591,
                "ReceiptVoidRate": 0.0286,
                "LinesVoided": 33,
                "ReceiptsVoided": 3,
                "RefundValue": 8173,
                "AverageReceiptCost": 2368,
                "AverageLineCost": 483,
                "TotalSaleAmount": 217856
            },
            {
                "site": 1468,
                "team": "Red",
                "agent": 14680001,
                "date": "14/12/2020",
                "ScanSpeed": 52,
                "TotalSales": 143,
                "AverageLinesPerSale": 7,
                "TotalLines": 1001,
                "LineVoidRate": 0.0633,
                "ReceiptVoidRate": 0.0119,
                "LinesVoided": 64,
                "ReceiptsVoided": 2,
                "RefundValue": 3712,
                "AverageReceiptCost": 1915,
                "AverageLineCost": 235,
                "TotalSaleAmount": 273845
            },
            {
                "site": 1468,
                "team": "Red",
                "agent": 14680001,
                "date": "21/12/2020",
                "ScanSpeed": 55,
                "TotalSales": 173,
                "AverageLinesPerSale": 9,
                "TotalLines": 1557,
                "LineVoidRate": 0.0569,
                "ReceiptVoidRate": 0.0078,
                "LinesVoided": 89,
                "ReceiptsVoided": 2,
                "RefundValue": 1529,
                "AverageReceiptCost": 1050,
                "AverageLineCost": 87,
                "TotalSaleAmount": 181650
            },
            {
                "site": 1468,
                "team": "Red",
                "agent": 14680001,
                "date": "28/12/2020",
                "ScanSpeed": 32,
                "TotalSales": 146,
                "AverageLinesPerSale": 8,
                "TotalLines": 1168,
                "LineVoidRate": 0.0606,
                "ReceiptVoidRate": 0.0308,
                "LinesVoided": 71,
                "ReceiptsVoided": 5,
                "RefundValue": 5957,
                "AverageReceiptCost": 1832,
                "AverageLineCost": 361,
                "TotalSaleAmount": 267472
            },
            {
                "site": 1468,
                "team": "Red",
                "agent": 14680002,
                "date": "26/10/2020",
                "ScanSpeed": 25,
                "TotalSales": 22,
                "AverageLinesPerSale": 6,
                "TotalLines": 132,
                "LineVoidRate": 0.0407,
                "ReceiptVoidRate": 0.0013,
                "LinesVoided": 6,
                "ReceiptsVoided": 1,
                "RefundValue": 6437,
                "AverageReceiptCost": 2684,
                "AverageLineCost": 262,
                "TotalSaleAmount": 59048
            },
            {
                "site": 1468,
                "team": "Red",
                "agent": 14680002,
                "date": "02/11/2020",
                "ScanSpeed": 81,
                "TotalSales": 69,
                "AverageLinesPerSale": 11,
                "TotalLines": 759,
                "LineVoidRate": 0.0005,
                "ReceiptVoidRate": 0.0308,
                "LinesVoided": 1,
                "ReceiptsVoided": 3,
                "RefundValue": 500000,
                "AverageReceiptCost": 1231,
                "AverageLineCost": 250,
                "TotalSaleAmount": 84939
            },
            {
                "site": 1468,
                "team": "Red",
                "agent": 14680002,
                "date": "09/11/2020",
                "ScanSpeed": 76,
                "TotalSales": 51,
                "AverageLinesPerSale": 5,
                "TotalLines": 255,
                "LineVoidRate": 0.0152,
                "ReceiptVoidRate": 0.0118,
                "LinesVoided": 4,
                "ReceiptsVoided": 1,
                "RefundValue": 11513,
                "AverageReceiptCost": 2577,
                "AverageLineCost": 175,
                "TotalSaleAmount": 131427
            },
            {
                "site": 1468,
                "team": "Red",
                "agent": 14680002,
                "date": "16/11/2020",
                "ScanSpeed": 42,
                "TotalSales": 30,
                "AverageLinesPerSale": 9,
                "TotalLines": 270,
                "LineVoidRate": 0.0221,
                "ReceiptVoidRate": 0.035,
                "LinesVoided": 6,
                "ReceiptsVoided": 2,
                "RefundValue": 13937,
                "AverageReceiptCost": 2805,
                "AverageLineCost": 308,
                "TotalSaleAmount": 84150
            },
            {
                "site": 1468,
                "team": "Red",
                "agent": 14680002,
                "date": "23/11/2020",
                "ScanSpeed": 24,
                "TotalSales": 62,
                "AverageLinesPerSale": 6,
                "TotalLines": 372,
                "LineVoidRate": 0.0427,
                "ReceiptVoidRate": 0.0026,
                "LinesVoided": 16,
                "ReceiptsVoided": 1,
                "RefundValue": 2576,
                "AverageReceiptCost": 1696,
                "AverageLineCost": 110,
                "TotalSaleAmount": 105152
            },
            {
                "site": 1468,
                "team": "Red",
                "agent": 14680002,
                "date": "30/11/2020",
                "ScanSpeed": 76,
                "TotalSales": 107,
                "AverageLinesPerSale": 6,
                "TotalLines": 642,
                "LineVoidRate": 0.0113,
                "ReceiptVoidRate": 0.0069,
                "LinesVoided": 8,
                "ReceiptsVoided": 1,
                "RefundValue": 42832,
                "AverageReceiptCost": 2976,
                "AverageLineCost": 484,
                "TotalSaleAmount": 318432
            },
            {
                "site": 1468,
                "team": "Red",
                "agent": 14680002,
                "date": "07/12/2020",
                "ScanSpeed": 56,
                "TotalSales": 104,
                "AverageLinesPerSale": 8,
                "TotalLines": 832,
                "LineVoidRate": 0.069,
                "ReceiptVoidRate": 0.0267,
                "LinesVoided": 58,
                "ReceiptsVoided": 3,
                "RefundValue": 5362,
                "AverageReceiptCost": 652,
                "AverageLineCost": 370,
                "TotalSaleAmount": 67808
            },
            {
                "site": 1468,
                "team": "Red",
                "agent": 14680002,
                "date": "14/12/2020",
                "ScanSpeed": 79,
                "TotalSales": 174,
                "AverageLinesPerSale": 6,
                "TotalLines": 1044,
                "LineVoidRate": 0.0501,
                "ReceiptVoidRate": 0.0356,
                "LinesVoided": 53,
                "ReceiptsVoided": 7,
                "RefundValue": 9441,
                "AverageReceiptCost": 889,
                "AverageLineCost": 473,
                "TotalSaleAmount": 154686
            },
            {
                "site": 1468,
                "team": "Red",
                "agent": 14680002,
                "date": "21/12/2020",
                "ScanSpeed": 12,
                "TotalSales": 71,
                "AverageLinesPerSale": 4,
                "TotalLines": 284,
                "LineVoidRate": 0.0498,
                "ReceiptVoidRate": 0.019,
                "LinesVoided": 15,
                "ReceiptsVoided": 2,
                "RefundValue": 7691,
                "AverageReceiptCost": 4113,
                "AverageLineCost": 383,
                "TotalSaleAmount": 292023
            },
            {
                "site": 1468,
                "team": "Red",
                "agent": 14680002,
                "date": "28/12/2020",
                "ScanSpeed": 82,
                "TotalSales": 63,
                "AverageLinesPerSale": 3,
                "TotalLines": 189,
                "LineVoidRate": 0.0273,
                "ReceiptVoidRate": 0.0198,
                "LinesVoided": 6,
                "ReceiptsVoided": 2,
                "RefundValue": 13736,
                "AverageReceiptCost": 4084,
                "AverageLineCost": 375,
                "TotalSaleAmount": 257292
            },
            {
                "site": 1468,
                "team": "Red",
                "agent": 14680003,
                "date": "26/10/2020",
                "ScanSpeed": 87,
                "TotalSales": 37,
                "AverageLinesPerSale": 5,
                "TotalLines": 185,
                "LineVoidRate": 0.0452,
                "ReceiptVoidRate": 0.0167,
                "LinesVoided": 9,
                "ReceiptsVoided": 1,
                "RefundValue": 7810,
                "AverageReceiptCost": 2064,
                "AverageLineCost": 353,
                "TotalSaleAmount": 76368
            },
            {
                "site": 1468,
                "team": "Red",
                "agent": 14680003,
                "date": "02/11/2020",
                "ScanSpeed": 10,
                "TotalSales": 108,
                "AverageLinesPerSale": 9,
                "TotalLines": 972,
                "LineVoidRate": 0.0167,
                "ReceiptVoidRate": 0.0159,
                "LinesVoided": 17,
                "ReceiptsVoided": 2,
                "RefundValue": 9701,
                "AverageReceiptCost": 1335,
                "AverageLineCost": 162,
                "TotalSaleAmount": 144180
            },
            {
                "site": 1468,
                "team": "Red",
                "agent": 14680003,
                "date": "09/11/2020",
                "ScanSpeed": 26,
                "TotalSales": 155,
                "AverageLinesPerSale": 11,
                "TotalLines": 1705,
                "LineVoidRate": 0.0158,
                "ReceiptVoidRate": 0.0348,
                "LinesVoided": 27,
                "ReceiptsVoided": 6,
                "RefundValue": 19430,
                "AverageReceiptCost": 1304,
                "AverageLineCost": 307,
                "TotalSaleAmount": 202120
            },
            {
                "site": 1468,
                "team": "Red",
                "agent": 14680003,
                "date": "16/11/2020",
                "ScanSpeed": 50,
                "TotalSales": 171,
                "AverageLinesPerSale": 6,
                "TotalLines": 1026,
                "LineVoidRate": 0.0018,
                "ReceiptVoidRate": 0.0303,
                "LinesVoided": 2,
                "ReceiptsVoided": 6,
                "RefundValue": 188333,
                "AverageReceiptCost": 2744,
                "AverageLineCost": 339,
                "TotalSaleAmount": 469224
            },
            {
                "site": 1468,
                "team": "Red",
                "agent": 14680003,
                "date": "23/11/2020",
                "ScanSpeed": 76,
                "TotalSales": 90,
                "AverageLinesPerSale": 8,
                "TotalLines": 720,
                "LineVoidRate": 0.0453,
                "ReceiptVoidRate": 0.0059,
                "LinesVoided": 33,
                "ReceiptsVoided": 1,
                "RefundValue": 1987,
                "AverageReceiptCost": 2173,
                "AverageLineCost": 90,
                "TotalSaleAmount": 195570
            },
            {
                "site": 1468,
                "team": "Red",
                "agent": 14680003,
                "date": "30/11/2020",
                "ScanSpeed": 58,
                "TotalSales": 119,
                "AverageLinesPerSale": 6,
                "TotalLines": 714,
                "LineVoidRate": 0.052,
                "ReceiptVoidRate": 0.0174,
                "LinesVoided": 38,
                "ReceiptsVoided": 3,
                "RefundValue": 8635,
                "AverageReceiptCost": 4463,
                "AverageLineCost": 449,
                "TotalSaleAmount": 531097
            },
            {
                "site": 1468,
                "team": "Red",
                "agent": 14680003,
                "date": "07/12/2020",
                "ScanSpeed": 82,
                "TotalSales": 86,
                "AverageLinesPerSale": 10,
                "TotalLines": 860,
                "LineVoidRate": 0.0448,
                "ReceiptVoidRate": 0.0002,
                "LinesVoided": 39,
                "ReceiptsVoided": 1,
                "RefundValue": 1629,
                "AverageReceiptCost": 4052,
                "AverageLineCost": 73,
                "TotalSaleAmount": 348472
            },
            {
                "site": 1468,
                "team": "Red",
                "agent": 14680003,
                "date": "14/12/2020",
                "ScanSpeed": 77,
                "TotalSales": 40,
                "AverageLinesPerSale": 6,
                "TotalLines": 240,
                "LineVoidRate": 0.0202,
                "ReceiptVoidRate": 0.0172,
                "LinesVoided": 5,
                "ReceiptsVoided": 1,
                "RefundValue": 17871,
                "AverageReceiptCost": 4320,
                "AverageLineCost": 361,
                "TotalSaleAmount": 172800
            },
            {
                "site": 1468,
                "team": "Red",
                "agent": 14680003,
                "date": "21/12/2020",
                "ScanSpeed": 48,
                "TotalSales": 175,
                "AverageLinesPerSale": 9,
                "TotalLines": 1575,
                "LineVoidRate": 0.0314,
                "ReceiptVoidRate": 0.0399,
                "LinesVoided": 50,
                "ReceiptsVoided": 7,
                "RefundValue": 5287,
                "AverageReceiptCost": 430,
                "AverageLineCost": 166,
                "TotalSaleAmount": 75250
            },
            {
                "site": 1468,
                "team": "Red",
                "agent": 14680003,
                "date": "28/12/2020",
                "ScanSpeed": 89,
                "TotalSales": 95,
                "AverageLinesPerSale": 6,
                "TotalLines": 570,
                "LineVoidRate": 0.0566,
                "ReceiptVoidRate": 0.0184,
                "LinesVoided": 33,
                "ReceiptsVoided": 2,
                "RefundValue": 3021,
                "AverageReceiptCost": 1957,
                "AverageLineCost": 171,
                "TotalSaleAmount": 185915
            },
            {
                "site": 1468,
                "team": "Red",
                "agent": 14680004,
                "date": "26/10/2020",
                "ScanSpeed": 62,
                "TotalSales": 79,
                "AverageLinesPerSale": 7,
                "TotalLines": 553,
                "LineVoidRate": 0.0596,
                "ReceiptVoidRate": 0.0259,
                "LinesVoided": 33,
                "ReceiptsVoided": 3,
                "RefundValue": 3339,
                "AverageReceiptCost": 2453,
                "AverageLineCost": 199,
                "TotalSaleAmount": 193787
            },
            {
                "site": 1468,
                "team": "Red",
                "agent": 14680004,
                "date": "02/11/2020",
                "ScanSpeed": 90,
                "TotalSales": 67,
                "AverageLinesPerSale": 4,
                "TotalLines": 268,
                "LineVoidRate": 0.0057,
                "ReceiptVoidRate": 0.0357,
                "LinesVoided": 2,
                "ReceiptsVoided": 3,
                "RefundValue": 25088,
                "AverageReceiptCost": 3393,
                "AverageLineCost": 143,
                "TotalSaleAmount": 227331
            },
            {
                "site": 1468,
                "team": "Red",
                "agent": 14680004,
                "date": "09/11/2020",
                "ScanSpeed": 56,
                "TotalSales": 64,
                "AverageLinesPerSale": 6,
                "TotalLines": 384,
                "LineVoidRate": 0.0375,
                "ReceiptVoidRate": 0.0047,
                "LinesVoided": 15,
                "ReceiptsVoided": 1,
                "RefundValue": 7840,
                "AverageReceiptCost": 328,
                "AverageLineCost": 294,
                "TotalSaleAmount": 20992
            },
            {
                "site": 1468,
                "team": "Red",
                "agent": 14680004,
                "date": "16/11/2020",
                "ScanSpeed": 38,
                "TotalSales": 20,
                "AverageLinesPerSale": 7,
                "TotalLines": 140,
                "LineVoidRate": 0.0427,
                "ReceiptVoidRate": 0.0118,
                "LinesVoided": 6,
                "ReceiptsVoided": 1,
                "RefundValue": 11077,
                "AverageReceiptCost": 2792,
                "AverageLineCost": 473,
                "TotalSaleAmount": 55840
            },
            {
                "site": 1468,
                "team": "Red",
                "agent": 14680004,
                "date": "23/11/2020",
                "ScanSpeed": 60,
                "TotalSales": 29,
                "AverageLinesPerSale": 4,
                "TotalLines": 116,
                "LineVoidRate": 0.0041,
                "ReceiptVoidRate": 0.0223,
                "LinesVoided": 1,
                "ReceiptsVoided": 1,
                "RefundValue": 108049,
                "AverageReceiptCost": 2329,
                "AverageLineCost": 443,
                "TotalSaleAmount": 67541
            },
            {
                "site": 1468,
                "team": "Red",
                "agent": 14680004,
                "date": "30/11/2020",
                "ScanSpeed": 62,
                "TotalSales": 82,
                "AverageLinesPerSale": 3,
                "TotalLines": 246,
                "LineVoidRate": 0.0103,
                "ReceiptVoidRate": 0.0128,
                "LinesVoided": 3,
                "ReceiptsVoided": 2,
                "RefundValue": 7864,
                "AverageReceiptCost": 2148,
                "AverageLineCost": 81,
                "TotalSaleAmount": 176136
            },
            {
                "site": 1468,
                "team": "Red",
                "agent": 14680004,
                "date": "07/12/2020",
                "ScanSpeed": 50,
                "TotalSales": 118,
                "AverageLinesPerSale": 9,
                "TotalLines": 1062,
                "LineVoidRate": 0.0621,
                "ReceiptVoidRate": 0.0029,
                "LinesVoided": 66,
                "ReceiptsVoided": 1,
                "RefundValue": 5395,
                "AverageReceiptCost": 2626,
                "AverageLineCost": 335,
                "TotalSaleAmount": 309868
            },
            {
                "site": 1468,
                "team": "Red",
                "agent": 14680004,
                "date": "14/12/2020",
                "ScanSpeed": 73,
                "TotalSales": 76,
                "AverageLinesPerSale": 8,
                "TotalLines": 608,
                "LineVoidRate": 0.0398,
                "ReceiptVoidRate": 0.0023,
                "LinesVoided": 25,
                "ReceiptsVoided": 1,
                "RefundValue": 7940,
                "AverageReceiptCost": 370,
                "AverageLineCost": 316,
                "TotalSaleAmount": 28120
            },
            {
                "site": 1468,
                "team": "Red",
                "agent": 14680004,
                "date": "21/12/2020",
                "ScanSpeed": 46,
                "TotalSales": 45,
                "AverageLinesPerSale": 10,
                "TotalLines": 450,
                "LineVoidRate": 0.0165,
                "ReceiptVoidRate": 0.0004,
                "LinesVoided": 8,
                "ReceiptsVoided": 1,
                "RefundValue": 29636,
                "AverageReceiptCost": 2545,
                "AverageLineCost": 489,
                "TotalSaleAmount": 114525
            },
            {
                "site": 1468,
                "team": "Red",
                "agent": 14680004,
                "date": "28/12/2020",
                "ScanSpeed": 11,
                "TotalSales": 116,
                "AverageLinesPerSale": 8,
                "TotalLines": 928,
                "LineVoidRate": 0.0423,
                "ReceiptVoidRate": 0.0031,
                "LinesVoided": 40,
                "ReceiptsVoided": 1,
                "RefundValue": 5508,
                "AverageReceiptCost": 1261,
                "AverageLineCost": 233,
                "TotalSaleAmount": 146276
            },
            {
                "site": 1468,
                "team": "Red",
                "agent": 14680005,
                "date": "26/10/2020",
                "ScanSpeed": 70,
                "TotalSales": 157,
                "AverageLinesPerSale": 11,
                "TotalLines": 1727,
                "LineVoidRate": 0.0199,
                "ReceiptVoidRate": 0.0345,
                "LinesVoided": 35,
                "ReceiptsVoided": 6,
                "RefundValue": 9447,
                "AverageReceiptCost": 2332,
                "AverageLineCost": 188,
                "TotalSaleAmount": 366124
            },
            {
                "site": 1468,
                "team": "Red",
                "agent": 14680005,
                "date": "02/11/2020",
                "ScanSpeed": 74,
                "TotalSales": 105,
                "AverageLinesPerSale": 10,
                "TotalLines": 1050,
                "LineVoidRate": 0.0035,
                "ReceiptVoidRate": 0.0293,
                "LinesVoided": 4,
                "ReceiptsVoided": 4,
                "RefundValue": 109143,
                "AverageReceiptCost": 1694,
                "AverageLineCost": 382,
                "TotalSaleAmount": 177870
            },
            {
                "site": 1468,
                "team": "Red",
                "agent": 14680005,
                "date": "09/11/2020",
                "ScanSpeed": 63,
                "TotalSales": 156,
                "AverageLinesPerSale": 8,
                "TotalLines": 1248,
                "LineVoidRate": 0.0206,
                "ReceiptVoidRate": 0.0157,
                "LinesVoided": 26,
                "ReceiptsVoided": 3,
                "RefundValue": 18204,
                "AverageReceiptCost": 1178,
                "AverageLineCost": 375,
                "TotalSaleAmount": 183768
            },
            {
                "site": 1468,
                "team": "Red",
                "agent": 14680005,
                "date": "16/11/2020",
                "ScanSpeed": 72,
                "TotalSales": 165,
                "AverageLinesPerSale": 10,
                "TotalLines": 1650,
                "LineVoidRate": 0.0233,
                "ReceiptVoidRate": 0.0018,
                "LinesVoided": 39,
                "ReceiptsVoided": 1,
                "RefundValue": 2403,
                "AverageReceiptCost": 3871,
                "AverageLineCost": 56,
                "TotalSaleAmount": 638715
            },
            {
                "site": 1468,
                "team": "Red",
                "agent": 14680005,
                "date": "23/11/2020",
                "ScanSpeed": 35,
                "TotalSales": 94,
                "AverageLinesPerSale": 5,
                "TotalLines": 470,
                "LineVoidRate": 0.0149,
                "ReceiptVoidRate": 0.019,
                "LinesVoided": 8,
                "ReceiptsVoided": 2,
                "RefundValue": 15168,
                "AverageReceiptCost": 1744,
                "AverageLineCost": 226,
                "TotalSaleAmount": 163936
            },
            {
                "site": 1468,
                "team": "Red",
                "agent": 14680005,
                "date": "30/11/2020",
                "ScanSpeed": 72,
                "TotalSales": 56,
                "AverageLinesPerSale": 11,
                "TotalLines": 616,
                "LineVoidRate": 0.0358,
                "ReceiptVoidRate": 0.0252,
                "LinesVoided": 23,
                "ReceiptsVoided": 2,
                "RefundValue": 3715,
                "AverageReceiptCost": 2318,
                "AverageLineCost": 133,
                "TotalSaleAmount": 129808
            },
            {
                "site": 1468,
                "team": "Red",
                "agent": 14680005,
                "date": "07/12/2020",
                "ScanSpeed": 52,
                "TotalSales": 115,
                "AverageLinesPerSale": 6,
                "TotalLines": 690,
                "LineVoidRate": 0.0057,
                "ReceiptVoidRate": 0.0206,
                "LinesVoided": 4,
                "ReceiptsVoided": 3,
                "RefundValue": 74035,
                "AverageReceiptCost": 3527,
                "AverageLineCost": 422,
                "TotalSaleAmount": 405605
            },
            {
                "site": 1468,
                "team": "Red",
                "agent": 14680005,
                "date": "14/12/2020",
                "ScanSpeed": 27,
                "TotalSales": 131,
                "AverageLinesPerSale": 7,
                "TotalLines": 917,
                "LineVoidRate": 0.0213,
                "ReceiptVoidRate": 0.0277,
                "LinesVoided": 20,
                "ReceiptsVoided": 4,
                "RefundValue": 5305,
                "AverageReceiptCost": 3054,
                "AverageLineCost": 113,
                "TotalSaleAmount": 400074
            },
            {
                "site": 1468,
                "team": "Red",
                "agent": 14680005,
                "date": "21/12/2020",
                "ScanSpeed": 88,
                "TotalSales": 89,
                "AverageLinesPerSale": 5,
                "TotalLines": 445,
                "LineVoidRate": 0.0638,
                "ReceiptVoidRate": 0.0023,
                "LinesVoided": 29,
                "ReceiptsVoided": 1,
                "RefundValue": 7257,
                "AverageReceiptCost": 2625,
                "AverageLineCost": 463,
                "TotalSaleAmount": 233625
            },
            {
                "site": 1468,
                "team": "Red",
                "agent": 14680005,
                "date": "28/12/2020",
                "ScanSpeed": 57,
                "TotalSales": 119,
                "AverageLinesPerSale": 9,
                "TotalLines": 1071,
                "LineVoidRate": 0.0045,
                "ReceiptVoidRate": 0.0126,
                "LinesVoided": 5,
                "ReceiptsVoided": 2,
                "RefundValue": 92444,
                "AverageReceiptCost": 2278,
                "AverageLineCost": 416,
                "TotalSaleAmount": 271082
            },
            {
                "site": 1468,
                "team": "Blue",
                "agent": 14680006,
                "date": "26/10/2020",
                "ScanSpeed": 87,
                "TotalSales": 140,
                "AverageLinesPerSale": 6,
                "TotalLines": 840,
                "LineVoidRate": 0.0357,
                "ReceiptVoidRate": 0.0321,
                "LinesVoided": 30,
                "ReceiptsVoided": 5,
                "RefundValue": 5882,
                "AverageReceiptCost": 775,
                "AverageLineCost": 210,
                "TotalSaleAmount": 108500
            },
            {
                "site": 1468,
                "team": "Blue",
                "agent": 14680006,
                "date": "02/11/2020",
                "ScanSpeed": 82,
                "TotalSales": 175,
                "AverageLinesPerSale": 11,
                "TotalLines": 1925,
                "LineVoidRate": 0.0622,
                "ReceiptVoidRate": 0.0079,
                "LinesVoided": 120,
                "ReceiptsVoided": 2,
                "RefundValue": 6029,
                "AverageReceiptCost": 3579,
                "AverageLineCost": 375,
                "TotalSaleAmount": 626325
            },
            {
                "site": 1468,
                "team": "Blue",
                "agent": 14680006,
                "date": "09/11/2020",
                "ScanSpeed": 47,
                "TotalSales": 27,
                "AverageLinesPerSale": 9,
                "TotalLines": 243,
                "LineVoidRate": 0.0183,
                "ReceiptVoidRate": 0.0049,
                "LinesVoided": 5,
                "ReceiptsVoided": 1,
                "RefundValue": 13169,
                "AverageReceiptCost": 732,
                "AverageLineCost": 241,
                "TotalSaleAmount": 19764
            },
            {
                "site": 1468,
                "team": "Blue",
                "agent": 14680006,
                "date": "16/11/2020",
                "ScanSpeed": 30,
                "TotalSales": 124,
                "AverageLinesPerSale": 8,
                "TotalLines": 992,
                "LineVoidRate": 0.0378,
                "ReceiptVoidRate": 0.0339,
                "LinesVoided": 38,
                "ReceiptsVoided": 5,
                "RefundValue": 1958,
                "AverageReceiptCost": 1776,
                "AverageLineCost": 74,
                "TotalSaleAmount": 220224
            },
            {
                "site": 1468,
                "team": "Blue",
                "agent": 14680006,
                "date": "23/11/2020",
                "ScanSpeed": 87,
                "TotalSales": 162,
                "AverageLinesPerSale": 9,
                "TotalLines": 1458,
                "LineVoidRate": 0.0025,
                "ReceiptVoidRate": 0.027,
                "LinesVoided": 4,
                "ReceiptsVoided": 5,
                "RefundValue": 189200,
                "AverageReceiptCost": 2286,
                "AverageLineCost": 473,
                "TotalSaleAmount": 370332
            },
            {
                "site": 1468,
                "team": "Blue",
                "agent": 14680006,
                "date": "30/11/2020",
                "ScanSpeed": 78,
                "TotalSales": 17,
                "AverageLinesPerSale": 6,
                "TotalLines": 102,
                "LineVoidRate": 0.0378,
                "ReceiptVoidRate": 0.0123,
                "LinesVoided": 4,
                "ReceiptsVoided": 1,
                "RefundValue": 8201,
                "AverageReceiptCost": 2562,
                "AverageLineCost": 310,
                "TotalSaleAmount": 43554
            },
            {
                "site": 1468,
                "team": "Blue",
                "agent": 14680006,
                "date": "07/12/2020",
                "ScanSpeed": 68,
                "TotalSales": 78,
                "AverageLinesPerSale": 7,
                "TotalLines": 546,
                "LineVoidRate": 0.0229,
                "ReceiptVoidRate": 0.0332,
                "LinesVoided": 13,
                "ReceiptsVoided": 3,
                "RefundValue": 7467,
                "AverageReceiptCost": 426,
                "AverageLineCost": 171,
                "TotalSaleAmount": 33228
            },
            {
                "site": 1468,
                "team": "Blue",
                "agent": 14680006,
                "date": "14/12/2020",
                "ScanSpeed": 14,
                "TotalSales": 20,
                "AverageLinesPerSale": 9,
                "TotalLines": 180,
                "LineVoidRate": 0.0307,
                "ReceiptVoidRate": 0.0074,
                "LinesVoided": 6,
                "ReceiptsVoided": 1,
                "RefundValue": 12932,
                "AverageReceiptCost": 1623,
                "AverageLineCost": 397,
                "TotalSaleAmount": 32460
            },
            {
                "site": 1468,
                "team": "Blue",
                "agent": 14680006,
                "date": "21/12/2020",
                "ScanSpeed": 62,
                "TotalSales": 50,
                "AverageLinesPerSale": 7,
                "TotalLines": 350,
                "LineVoidRate": 0.0655,
                "ReceiptVoidRate": 0.015,
                "LinesVoided": 23,
                "ReceiptsVoided": 1,
                "RefundValue": 2427,
                "AverageReceiptCost": 2487,
                "AverageLineCost": 159,
                "TotalSaleAmount": 124350
            },
            {
                "site": 1468,
                "team": "Blue",
                "agent": 14680006,
                "date": "28/12/2020",
                "ScanSpeed": 25,
                "TotalSales": 52,
                "AverageLinesPerSale": 8,
                "TotalLines": 416,
                "LineVoidRate": 0.0348,
                "ReceiptVoidRate": 0.0324,
                "LinesVoided": 15,
                "ReceiptsVoided": 2,
                "RefundValue": 9282,
                "AverageReceiptCost": 1166,
                "AverageLineCost": 323,
                "TotalSaleAmount": 60632
            },
            {
                "site": 1468,
                "team": "Blue",
                "agent": 14680007,
                "date": "26/10/2020",
                "ScanSpeed": 84,
                "TotalSales": 109,
                "AverageLinesPerSale": 5,
                "TotalLines": 545,
                "LineVoidRate": 0.0529,
                "ReceiptVoidRate": 0.0218,
                "LinesVoided": 29,
                "ReceiptsVoided": 3,
                "RefundValue": 945,
                "AverageReceiptCost": 2754,
                "AverageLineCost": 50,
                "TotalSaleAmount": 300186
            },
            {
                "site": 1468,
                "team": "Blue",
                "agent": 14680007,
                "date": "02/11/2020",
                "ScanSpeed": 17,
                "TotalSales": 141,
                "AverageLinesPerSale": 9,
                "TotalLines": 1269,
                "LineVoidRate": 0.0311,
                "ReceiptVoidRate": 0.004,
                "LinesVoided": 40,
                "ReceiptsVoided": 1,
                "RefundValue": 15788,
                "AverageReceiptCost": 3650,
                "AverageLineCost": 491,
                "TotalSaleAmount": 514650
            },
            {
                "site": 1468,
                "team": "Blue",
                "agent": 14680007,
                "date": "09/11/2020",
                "ScanSpeed": 18,
                "TotalSales": 87,
                "AverageLinesPerSale": 11,
                "TotalLines": 957,
                "LineVoidRate": 0.0083,
                "ReceiptVoidRate": 0.019,
                "LinesVoided": 8,
                "ReceiptsVoided": 2,
                "RefundValue": 56145,
                "AverageReceiptCost": 911,
                "AverageLineCost": 466,
                "TotalSaleAmount": 79257
            },
            {
                "site": 1468,
                "team": "Blue",
                "agent": 14680007,
                "date": "16/11/2020",
                "ScanSpeed": 10,
                "TotalSales": 145,
                "AverageLinesPerSale": 9,
                "TotalLines": 1305,
                "LineVoidRate": 0.0221,
                "ReceiptVoidRate": 0.012,
                "LinesVoided": 29,
                "ReceiptsVoided": 2,
                "RefundValue": 19864,
                "AverageReceiptCost": 2262,
                "AverageLineCost": 439,
                "TotalSaleAmount": 327990
            },
            {
                "site": 1468,
                "team": "Blue",
                "agent": 14680007,
                "date": "23/11/2020",
                "ScanSpeed": 31,
                "TotalSales": 70,
                "AverageLinesPerSale": 11,
                "TotalLines": 770,
                "LineVoidRate": 0.0153,
                "ReceiptVoidRate": 0.0011,
                "LinesVoided": 12,
                "ReceiptsVoided": 1,
                "RefundValue": 11830,
                "AverageReceiptCost": 1072,
                "AverageLineCost": 181,
                "TotalSaleAmount": 75040
            },
            {
                "site": 1468,
                "team": "Blue",
                "agent": 14680007,
                "date": "30/11/2020",
                "ScanSpeed": 10,
                "TotalSales": 85,
                "AverageLinesPerSale": 5,
                "TotalLines": 425,
                "LineVoidRate": 0.0011,
                "ReceiptVoidRate": 0.0372,
                "LinesVoided": 1,
                "ReceiptsVoided": 4,
                "RefundValue": 412727,
                "AverageReceiptCost": 1842,
                "AverageLineCost": 454,
                "TotalSaleAmount": 156570
            },
            {
                "site": 1468,
                "team": "Blue",
                "agent": 14680007,
                "date": "07/12/2020",
                "ScanSpeed": 77,
                "TotalSales": 60,
                "AverageLinesPerSale": 5,
                "TotalLines": 300,
                "LineVoidRate": 0.0331,
                "ReceiptVoidRate": 0.0093,
                "LinesVoided": 10,
                "ReceiptsVoided": 1,
                "RefundValue": 12296,
                "AverageReceiptCost": 3396,
                "AverageLineCost": 407,
                "TotalSaleAmount": 203760
            },
            {
                "site": 1468,
                "team": "Blue",
                "agent": 14680007,
                "date": "14/12/2020",
                "ScanSpeed": 84,
                "TotalSales": 139,
                "AverageLinesPerSale": 3,
                "TotalLines": 417,
                "LineVoidRate": 0.0699,
                "ReceiptVoidRate": 0.0142,
                "LinesVoided": 30,
                "ReceiptsVoided": 2,
                "RefundValue": 2160,
                "AverageReceiptCost": 3399,
                "AverageLineCost": 151,
                "TotalSaleAmount": 472461
            },
            {
                "site": 1468,
                "team": "Blue",
                "agent": 14680007,
                "date": "21/12/2020",
                "ScanSpeed": 59,
                "TotalSales": 46,
                "AverageLinesPerSale": 8,
                "TotalLines": 368,
                "LineVoidRate": 0.026,
                "ReceiptVoidRate": 0.0298,
                "LinesVoided": 10,
                "ReceiptsVoided": 2,
                "RefundValue": 2423,
                "AverageReceiptCost": 1170,
                "AverageLineCost": 63,
                "TotalSaleAmount": 53820
            },
            {
                "site": 1468,
                "team": "Blue",
                "agent": 14680007,
                "date": "28/12/2020",
                "ScanSpeed": 68,
                "TotalSales": 104,
                "AverageLinesPerSale": 8,
                "TotalLines": 832,
                "LineVoidRate": 0.0206,
                "ReceiptVoidRate": 0.0397,
                "LinesVoided": 18,
                "ReceiptsVoided": 5,
                "RefundValue": 19272,
                "AverageReceiptCost": 2014,
                "AverageLineCost": 397,
                "TotalSaleAmount": 209456
            },
            {
                "site": 1468,
                "team": "Blue",
                "agent": 14680008,
                "date": "26/10/2020",
                "ScanSpeed": 14,
                "TotalSales": 136,
                "AverageLinesPerSale": 3,
                "TotalLines": 408,
                "LineVoidRate": 0.0365,
                "ReceiptVoidRate": 0.0217,
                "LinesVoided": 15,
                "ReceiptsVoided": 3,
                "RefundValue": 2411,
                "AverageReceiptCost": 692,
                "AverageLineCost": 88,
                "TotalSaleAmount": 94112
            },
            {
                "site": 1468,
                "team": "Blue",
                "agent": 14680008,
                "date": "02/11/2020",
                "ScanSpeed": 10,
                "TotalSales": 129,
                "AverageLinesPerSale": 3,
                "TotalLines": 387,
                "LineVoidRate": 0.0357,
                "ReceiptVoidRate": 0.0347,
                "LinesVoided": 14,
                "ReceiptsVoided": 5,
                "RefundValue": 12213,
                "AverageReceiptCost": 714,
                "AverageLineCost": 436,
                "TotalSaleAmount": 92106
            },
            {
                "site": 1468,
                "team": "Blue",
                "agent": 14680008,
                "date": "09/11/2020",
                "ScanSpeed": 80,
                "TotalSales": 137,
                "AverageLinesPerSale": 7,
                "TotalLines": 959,
                "LineVoidRate": 0.0075,
                "ReceiptVoidRate": 0.0123,
                "LinesVoided": 8,
                "ReceiptsVoided": 2,
                "RefundValue": 59333,
                "AverageReceiptCost": 1792,
                "AverageLineCost": 445,
                "TotalSaleAmount": 245504
            },
            {
                "site": 1468,
                "team": "Blue",
                "agent": 14680008,
                "date": "16/11/2020",
                "ScanSpeed": 78,
                "TotalSales": 66,
                "AverageLinesPerSale": 10,
                "TotalLines": 660,
                "LineVoidRate": 0.0476,
                "ReceiptVoidRate": 0.0019,
                "LinesVoided": 32,
                "ReceiptsVoided": 1,
                "RefundValue": 9601,
                "AverageReceiptCost": 2288,
                "AverageLineCost": 457,
                "TotalSaleAmount": 151008
            },
            {
                "site": 1468,
                "team": "Blue",
                "agent": 14680008,
                "date": "23/11/2020",
                "ScanSpeed": 49,
                "TotalSales": 25,
                "AverageLinesPerSale": 7,
                "TotalLines": 175,
                "LineVoidRate": 0.0227,
                "ReceiptVoidRate": 0.0329,
                "LinesVoided": 4,
                "ReceiptsVoided": 1,
                "RefundValue": 18458,
                "AverageReceiptCost": 2291,
                "AverageLineCost": 419,
                "TotalSaleAmount": 57275
            },
            {
                "site": 1468,
                "team": "Blue",
                "agent": 14680008,
                "date": "30/11/2020",
                "ScanSpeed": 59,
                "TotalSales": 125,
                "AverageLinesPerSale": 5,
                "TotalLines": 625,
                "LineVoidRate": 0.0596,
                "ReceiptVoidRate": 0.0196,
                "LinesVoided": 38,
                "ReceiptsVoided": 3,
                "RefundValue": 2785,
                "AverageReceiptCost": 4488,
                "AverageLineCost": 166,
                "TotalSaleAmount": 561000
            },
            {
                "site": 1468,
                "team": "Blue",
                "agent": 14680008,
                "date": "07/12/2020",
                "ScanSpeed": 58,
                "TotalSales": 151,
                "AverageLinesPerSale": 3,
                "TotalLines": 453,
                "LineVoidRate": 0.058,
                "ReceiptVoidRate": 0.0115,
                "LinesVoided": 27,
                "ReceiptsVoided": 2,
                "RefundValue": 3966,
                "AverageReceiptCost": 3450,
                "AverageLineCost": 230,
                "TotalSaleAmount": 520950
            },
            {
                "site": 1468,
                "team": "Blue",
                "agent": 14680008,
                "date": "14/12/2020",
                "ScanSpeed": 77,
                "TotalSales": 64,
                "AverageLinesPerSale": 4,
                "TotalLines": 256,
                "LineVoidRate": 0.0666,
                "ReceiptVoidRate": 0.0087,
                "LinesVoided": 18,
                "ReceiptsVoided": 1,
                "RefundValue": 6997,
                "AverageReceiptCost": 2123,
                "AverageLineCost": 466,
                "TotalSaleAmount": 135872
            },
            {
                "site": 1468,
                "team": "Blue",
                "agent": 14680008,
                "date": "21/12/2020",
                "ScanSpeed": 26,
                "TotalSales": 145,
                "AverageLinesPerSale": 4,
                "TotalLines": 580,
                "LineVoidRate": 0.061,
                "ReceiptVoidRate": 0.0118,
                "LinesVoided": 36,
                "ReceiptsVoided": 2,
                "RefundValue": 5967,
                "AverageReceiptCost": 2705,
                "AverageLineCost": 364,
                "TotalSaleAmount": 392225
            },
            {
                "site": 1468,
                "team": "Blue",
                "agent": 14680008,
                "date": "28/12/2020",
                "ScanSpeed": 57,
                "TotalSales": 97,
                "AverageLinesPerSale": 4,
                "TotalLines": 388,
                "LineVoidRate": 0.0246,
                "ReceiptVoidRate": 0.0131,
                "LinesVoided": 10,
                "ReceiptsVoided": 2,
                "RefundValue": 13333,
                "AverageReceiptCost": 3812,
                "AverageLineCost": 328,
                "TotalSaleAmount": 369764
            },
            {
                "site": 1468,
                "team": "Blue",
                "agent": 14680009,
                "date": "26/10/2020",
                "ScanSpeed": 36,
                "TotalSales": 174,
                "AverageLinesPerSale": 3,
                "TotalLines": 522,
                "LineVoidRate": 0.0209,
                "ReceiptVoidRate": 0.038,
                "LinesVoided": 11,
                "ReceiptsVoided": 7,
                "RefundValue": 13684,
                "AverageReceiptCost": 1616,
                "AverageLineCost": 286,
                "TotalSaleAmount": 281184
            },
            {
                "site": 1468,
                "team": "Blue",
                "agent": 14680009,
                "date": "02/11/2020",
                "ScanSpeed": 47,
                "TotalSales": 57,
                "AverageLinesPerSale": 6,
                "TotalLines": 342,
                "LineVoidRate": 0.0262,
                "ReceiptVoidRate": 0.0098,
                "LinesVoided": 9,
                "ReceiptsVoided": 1,
                "RefundValue": 3550,
                "AverageReceiptCost": 3350,
                "AverageLineCost": 93,
                "TotalSaleAmount": 190950
            },
            {
                "site": 1468,
                "team": "Blue",
                "agent": 14680009,
                "date": "09/11/2020",
                "ScanSpeed": 73,
                "TotalSales": 156,
                "AverageLinesPerSale": 10,
                "TotalLines": 1560,
                "LineVoidRate": 0.0405,
                "ReceiptVoidRate": 0.0065,
                "LinesVoided": 64,
                "ReceiptsVoided": 2,
                "RefundValue": 7481,
                "AverageReceiptCost": 1241,
                "AverageLineCost": 303,
                "TotalSaleAmount": 193596
            },
            {
                "site": 1468,
                "team": "Blue",
                "agent": 14680009,
                "date": "16/11/2020",
                "ScanSpeed": 47,
                "TotalSales": 30,
                "AverageLinesPerSale": 11,
                "TotalLines": 330,
                "LineVoidRate": 0.0096,
                "ReceiptVoidRate": 0.0264,
                "LinesVoided": 4,
                "ReceiptsVoided": 1,
                "RefundValue": 31875,
                "AverageReceiptCost": 2585,
                "AverageLineCost": 306,
                "TotalSaleAmount": 77550
            },
            {
                "site": 1468,
                "team": "Blue",
                "agent": 14680009,
                "date": "23/11/2020",
                "ScanSpeed": 28,
                "TotalSales": 82,
                "AverageLinesPerSale": 3,
                "TotalLines": 246,
                "LineVoidRate": 0.0697,
                "ReceiptVoidRate": 0.0002,
                "LinesVoided": 18,
                "ReceiptsVoided": 1,
                "RefundValue": 6944,
                "AverageReceiptCost": 3294,
                "AverageLineCost": 484,
                "TotalSaleAmount": 270108
            },
            {
                "site": 1468,
                "team": "Blue",
                "agent": 14680009,
                "date": "30/11/2020",
                "ScanSpeed": 61,
                "TotalSales": 105,
                "AverageLinesPerSale": 8,
                "TotalLines": 840,
                "LineVoidRate": 0.0007,
                "ReceiptVoidRate": 0.0273,
                "LinesVoided": 1,
                "ReceiptsVoided": 3,
                "RefundValue": 575714,
                "AverageReceiptCost": 1183,
                "AverageLineCost": 403,
                "TotalSaleAmount": 124215
            },
            {
                "site": 1468,
                "team": "Blue",
                "agent": 14680009,
                "date": "07/12/2020",
                "ScanSpeed": 41,
                "TotalSales": 170,
                "AverageLinesPerSale": 7,
                "TotalLines": 1190,
                "LineVoidRate": 0.0595,
                "ReceiptVoidRate": 0.0071,
                "LinesVoided": 71,
                "ReceiptsVoided": 2,
                "RefundValue": 6689,
                "AverageReceiptCost": 814,
                "AverageLineCost": 398,
                "TotalSaleAmount": 138380
            },
            {
                "site": 1468,
                "team": "Blue",
                "agent": 14680009,
                "date": "14/12/2020",
                "ScanSpeed": 48,
                "TotalSales": 92,
                "AverageLinesPerSale": 11,
                "TotalLines": 1012,
                "LineVoidRate": 0.0005,
                "ReceiptVoidRate": 0.0127,
                "LinesVoided": 1,
                "ReceiptsVoided": 2,
                "RefundValue": 562000,
                "AverageReceiptCost": 2046,
                "AverageLineCost": 281,
                "TotalSaleAmount": 188232
            },
            {
                "site": 1468,
                "team": "Blue",
                "agent": 14680009,
                "date": "21/12/2020",
                "ScanSpeed": 25,
                "TotalSales": 40,
                "AverageLinesPerSale": 4,
                "TotalLines": 160,
                "LineVoidRate": 0.0227,
                "ReceiptVoidRate": 0.0214,
                "LinesVoided": 4,
                "ReceiptsVoided": 1,
                "RefundValue": 13040,
                "AverageReceiptCost": 4401,
                "AverageLineCost": 296,
                "TotalSaleAmount": 176040
            },
            {
                "site": 1468,
                "team": "Blue",
                "agent": 14680009,
                "date": "28/12/2020",
                "ScanSpeed": 55,
                "TotalSales": 16,
                "AverageLinesPerSale": 4,
                "TotalLines": 64,
                "LineVoidRate": 0.0605,
                "ReceiptVoidRate": 0.0312,
                "LinesVoided": 4,
                "ReceiptsVoided": 1,
                "RefundValue": 1240,
                "AverageReceiptCost": 2994,
                "AverageLineCost": 75,
                "TotalSaleAmount": 47904
            },
            {
                "site": 1468,
                "team": "Blue",
                "agent": 14680010,
                "date": "26/10/2020",
                "ScanSpeed": 10,
                "TotalSales": 163,
                "AverageLinesPerSale": 7,
                "TotalLines": 1141,
                "LineVoidRate": 0.0163,
                "ReceiptVoidRate": 0.0393,
                "LinesVoided": 19,
                "ReceiptsVoided": 7,
                "RefundValue": 27117,
                "AverageReceiptCost": 4415,
                "AverageLineCost": 442,
                "TotalSaleAmount": 719645
            },
            {
                "site": 1468,
                "team": "Blue",
                "agent": 14680010,
                "date": "02/11/2020",
                "ScanSpeed": 17,
                "TotalSales": 18,
                "AverageLinesPerSale": 4,
                "TotalLines": 72,
                "LineVoidRate": 0.0415,
                "ReceiptVoidRate": 0.019,
                "LinesVoided": 3,
                "ReceiptsVoided": 1,
                "RefundValue": 4096,
                "AverageReceiptCost": 4418,
                "AverageLineCost": 170,
                "TotalSaleAmount": 79524
            },
            {
                "site": 1468,
                "team": "Blue",
                "agent": 14680010,
                "date": "09/11/2020",
                "ScanSpeed": 77,
                "TotalSales": 16,
                "AverageLinesPerSale": 6,
                "TotalLines": 96,
                "LineVoidRate": 0.0314,
                "ReceiptVoidRate": 0.0349,
                "LinesVoided": 4,
                "ReceiptsVoided": 1,
                "RefundValue": 8408,
                "AverageReceiptCost": 1879,
                "AverageLineCost": 264,
                "TotalSaleAmount": 30064
            },
            {
                "site": 1468,
                "team": "Blue",
                "agent": 14680010,
                "date": "16/11/2020",
                "ScanSpeed": 15,
                "TotalSales": 110,
                "AverageLinesPerSale": 10,
                "TotalLines": 1100,
                "LineVoidRate": 0.0209,
                "ReceiptVoidRate": 0.004,
                "LinesVoided": 23,
                "ReceiptsVoided": 1,
                "RefundValue": 14593,
                "AverageReceiptCost": 1710,
                "AverageLineCost": 305,
                "TotalSaleAmount": 188100
            },
            {
                "site": 1468,
                "team": "Blue",
                "agent": 14680010,
                "date": "23/11/2020",
                "ScanSpeed": 60,
                "TotalSales": 153,
                "AverageLinesPerSale": 3,
                "TotalLines": 459,
                "LineVoidRate": 0.0661,
                "ReceiptVoidRate": 0.0328,
                "LinesVoided": 31,
                "ReceiptsVoided": 6,
                "RefundValue": 2950,
                "AverageReceiptCost": 1607,
                "AverageLineCost": 195,
                "TotalSaleAmount": 245871
            },
            {
                "site": 1468,
                "team": "Blue",
                "agent": 14680010,
                "date": "30/11/2020",
                "ScanSpeed": 50,
                "TotalSales": 100,
                "AverageLinesPerSale": 6,
                "TotalLines": 600,
                "LineVoidRate": 0.022,
                "ReceiptVoidRate": 0.0205,
                "LinesVoided": 14,
                "ReceiptsVoided": 3,
                "RefundValue": 12545,
                "AverageReceiptCost": 1425,
                "AverageLineCost": 276,
                "TotalSaleAmount": 142500
            },
            {
                "site": 1468,
                "team": "Blue",
                "agent": 14680010,
                "date": "07/12/2020",
                "ScanSpeed": 88,
                "TotalSales": 165,
                "AverageLinesPerSale": 8,
                "TotalLines": 1320,
                "LineVoidRate": 0.0268,
                "ReceiptVoidRate": 0.038,
                "LinesVoided": 36,
                "ReceiptsVoided": 7,
                "RefundValue": 2313,
                "AverageReceiptCost": 4369,
                "AverageLineCost": 62,
                "TotalSaleAmount": 720885
            },
            {
                "site": 1468,
                "team": "Blue",
                "agent": 14680010,
                "date": "14/12/2020",
                "ScanSpeed": 15,
                "TotalSales": 72,
                "AverageLinesPerSale": 5,
                "TotalLines": 360,
                "LineVoidRate": 0.0654,
                "ReceiptVoidRate": 0.0368,
                "LinesVoided": 24,
                "ReceiptsVoided": 3,
                "RefundValue": 5321,
                "AverageReceiptCost": 3291,
                "AverageLineCost": 348,
                "TotalSaleAmount": 236952
            },
            {
                "site": 1468,
                "team": "Blue",
                "agent": 14680010,
                "date": "21/12/2020",
                "ScanSpeed": 58,
                "TotalSales": 77,
                "AverageLinesPerSale": 10,
                "TotalLines": 770,
                "LineVoidRate": 0.056,
                "ReceiptVoidRate": 0.0227,
                "LinesVoided": 44,
                "ReceiptsVoided": 2,
                "RefundValue": 6018,
                "AverageReceiptCost": 2126,
                "AverageLineCost": 337,
                "TotalSaleAmount": 163702
            },
            {
                "site": 1468,
                "team": "Blue",
                "agent": 14680010,
                "date": "28/12/2020",
                "ScanSpeed": 83,
                "TotalSales": 166,
                "AverageLinesPerSale": 4,
                "TotalLines": 664,
                "LineVoidRate": 0.0238,
                "ReceiptVoidRate": 0.0319,
                "LinesVoided": 16,
                "ReceiptsVoided": 6,
                "RefundValue": 8697,
                "AverageReceiptCost": 2711,
                "AverageLineCost": 207,
                "TotalSaleAmount": 450026
            },
            {
                "site": 1468,
                "team": "Blue",
                "agent": 14680011,
                "date": "26/10/2020",
                "ScanSpeed": 12,
                "TotalSales": 19,
                "AverageLinesPerSale": 10,
                "TotalLines": 190,
                "LineVoidRate": 0.0252,
                "ReceiptVoidRate": 0.0149,
                "LinesVoided": 5,
                "ReceiptsVoided": 1,
                "RefundValue": 2143,
                "AverageReceiptCost": 3038,
                "AverageLineCost": 54,
                "TotalSaleAmount": 57722
            },
            {
                "site": 1468,
                "team": "Blue",
                "agent": 14680011,
                "date": "02/11/2020",
                "ScanSpeed": 16,
                "TotalSales": 23,
                "AverageLinesPerSale": 8,
                "TotalLines": 184,
                "LineVoidRate": 0.0265,
                "ReceiptVoidRate": 0.0331,
                "LinesVoided": 5,
                "ReceiptsVoided": 1,
                "RefundValue": 4830,
                "AverageReceiptCost": 3876,
                "AverageLineCost": 128,
                "TotalSaleAmount": 89148
            },
            {
                "site": 1468,
                "team": "Blue",
                "agent": 14680011,
                "date": "09/11/2020",
                "ScanSpeed": 64,
                "TotalSales": 93,
                "AverageLinesPerSale": 9,
                "TotalLines": 837,
                "LineVoidRate": 0.0215,
                "ReceiptVoidRate": 0.0001,
                "LinesVoided": 18,
                "ReceiptsVoided": 1,
                "RefundValue": 4512,
                "AverageReceiptCost": 2373,
                "AverageLineCost": 97,
                "TotalSaleAmount": 220689
            },
            {
                "site": 1468,
                "team": "Blue",
                "agent": 14680011,
                "date": "16/11/2020",
                "ScanSpeed": 87,
                "TotalSales": 57,
                "AverageLinesPerSale": 10,
                "TotalLines": 570,
                "LineVoidRate": 0.0243,
                "ReceiptVoidRate": 0.0261,
                "LinesVoided": 14,
                "ReceiptsVoided": 2,
                "RefundValue": 19095,
                "AverageReceiptCost": 2766,
                "AverageLineCost": 464,
                "TotalSaleAmount": 157662
            },
            {
                "site": 1468,
                "team": "Blue",
                "agent": 14680011,
                "date": "23/11/2020",
                "ScanSpeed": 20,
                "TotalSales": 163,
                "AverageLinesPerSale": 4,
                "TotalLines": 652,
                "LineVoidRate": 0.0214,
                "ReceiptVoidRate": 0.0167,
                "LinesVoided": 14,
                "ReceiptsVoided": 3,
                "RefundValue": 19907,
                "AverageReceiptCost": 4062,
                "AverageLineCost": 426,
                "TotalSaleAmount": 662106
            },
            {
                "site": 1468,
                "team": "Blue",
                "agent": 14680011,
                "date": "30/11/2020",
                "ScanSpeed": 78,
                "TotalSales": 126,
                "AverageLinesPerSale": 11,
                "TotalLines": 1386,
                "LineVoidRate": 0.0551,
                "ReceiptVoidRate": 0.0215,
                "LinesVoided": 77,
                "ReceiptsVoided": 3,
                "RefundValue": 6624,
                "AverageReceiptCost": 2699,
                "AverageLineCost": 365,
                "TotalSaleAmount": 340074
            },
            {
                "site": 1468,
                "team": "Blue",
                "agent": 14680011,
                "date": "07/12/2020",
                "ScanSpeed": 12,
                "TotalSales": 59,
                "AverageLinesPerSale": 11,
                "TotalLines": 649,
                "LineVoidRate": 0.0272,
                "ReceiptVoidRate": 0.0068,
                "LinesVoided": 18,
                "ReceiptsVoided": 1,
                "RefundValue": 17610,
                "AverageReceiptCost": 2248,
                "AverageLineCost": 479,
                "TotalSaleAmount": 132632
            },
            {
                "site": 1468,
                "team": "Blue",
                "agent": 14680011,
                "date": "14/12/2020",
                "ScanSpeed": 58,
                "TotalSales": 55,
                "AverageLinesPerSale": 9,
                "TotalLines": 495,
                "LineVoidRate": 0.0327,
                "ReceiptVoidRate": 0.0249,
                "LinesVoided": 17,
                "ReceiptsVoided": 2,
                "RefundValue": 2446,
                "AverageReceiptCost": 2304,
                "AverageLineCost": 80,
                "TotalSaleAmount": 126720
            },
            {
                "site": 1468,
                "team": "Blue",
                "agent": 14680011,
                "date": "21/12/2020",
                "ScanSpeed": 56,
                "TotalSales": 61,
                "AverageLinesPerSale": 10,
                "TotalLines": 610,
                "LineVoidRate": 0.0352,
                "ReceiptVoidRate": 0.0165,
                "LinesVoided": 22,
                "ReceiptsVoided": 2,
                "RefundValue": 5966,
                "AverageReceiptCost": 4315,
                "AverageLineCost": 210,
                "TotalSaleAmount": 263215
            },
            {
                "site": 1468,
                "team": "Blue",
                "agent": 14680011,
                "date": "28/12/2020",
                "ScanSpeed": 45,
                "TotalSales": 55,
                "AverageLinesPerSale": 6,
                "TotalLines": 330,
                "LineVoidRate": 0.0693,
                "ReceiptVoidRate": 0.0188,
                "LinesVoided": 23,
                "ReceiptsVoided": 2,
                "RefundValue": 5469,
                "AverageReceiptCost": 2332,
                "AverageLineCost": 379,
                "TotalSaleAmount": 128260
            },
            {
                "site": 1468,
                "team": "Green",
                "agent": 14680012,
                "date": "26/10/2020",
                "ScanSpeed": 79,
                "TotalSales": 37,
                "AverageLinesPerSale": 9,
                "TotalLines": 333,
                "LineVoidRate": 0.0538,
                "ReceiptVoidRate": 0.028,
                "LinesVoided": 18,
                "ReceiptsVoided": 2,
                "RefundValue": 1970,
                "AverageReceiptCost": 307,
                "AverageLineCost": 106,
                "TotalSaleAmount": 11359
            },
            {
                "site": 1468,
                "team": "Green",
                "agent": 14680012,
                "date": "02/11/2020",
                "ScanSpeed": 43,
                "TotalSales": 172,
                "AverageLinesPerSale": 6,
                "TotalLines": 1032,
                "LineVoidRate": 0.0601,
                "ReceiptVoidRate": 0.0318,
                "LinesVoided": 63,
                "ReceiptsVoided": 6,
                "RefundValue": 8087,
                "AverageReceiptCost": 1718,
                "AverageLineCost": 486,
                "TotalSaleAmount": 295496
            },
            {
                "site": 1468,
                "team": "Green",
                "agent": 14680012,
                "date": "09/11/2020",
                "ScanSpeed": 72,
                "TotalSales": 91,
                "AverageLinesPerSale": 9,
                "TotalLines": 819,
                "LineVoidRate": 0.0644,
                "ReceiptVoidRate": 0.0227,
                "LinesVoided": 53,
                "ReceiptsVoided": 3,
                "RefundValue": 4596,
                "AverageReceiptCost": 516,
                "AverageLineCost": 296,
                "TotalSaleAmount": 46956
            },
            {
                "site": 1468,
                "team": "Green",
                "agent": 14680012,
                "date": "16/11/2020",
                "ScanSpeed": 69,
                "TotalSales": 152,
                "AverageLinesPerSale": 10,
                "TotalLines": 1520,
                "LineVoidRate": 0.0284,
                "ReceiptVoidRate": 0.0056,
                "LinesVoided": 44,
                "ReceiptsVoided": 1,
                "RefundValue": 7465,
                "AverageReceiptCost": 312,
                "AverageLineCost": 212,
                "TotalSaleAmount": 47424
            },
            {
                "site": 1468,
                "team": "Green",
                "agent": 14680012,
                "date": "23/11/2020",
                "ScanSpeed": 44,
                "TotalSales": 95,
                "AverageLinesPerSale": 5,
                "TotalLines": 475,
                "LineVoidRate": 0.0556,
                "ReceiptVoidRate": 0.0118,
                "LinesVoided": 27,
                "ReceiptsVoided": 2,
                "RefundValue": 8076,
                "AverageReceiptCost": 1662,
                "AverageLineCost": 449,
                "TotalSaleAmount": 157890
            },
            {
                "site": 1468,
                "team": "Green",
                "agent": 14680012,
                "date": "30/11/2020",
                "ScanSpeed": 18,
                "TotalSales": 94,
                "AverageLinesPerSale": 11,
                "TotalLines": 1034,
                "LineVoidRate": 0.0429,
                "ReceiptVoidRate": 0.0009,
                "LinesVoided": 45,
                "ReceiptsVoided": 1,
                "RefundValue": 8718,
                "AverageReceiptCost": 907,
                "AverageLineCost": 374,
                "TotalSaleAmount": 85258
            },
            {
                "site": 1468,
                "team": "Green",
                "agent": 14680012,
                "date": "07/12/2020",
                "ScanSpeed": 84,
                "TotalSales": 130,
                "AverageLinesPerSale": 9,
                "TotalLines": 1170,
                "LineVoidRate": 0.0591,
                "ReceiptVoidRate": 0.0224,
                "LinesVoided": 70,
                "ReceiptsVoided": 3,
                "RefundValue": 1354,
                "AverageReceiptCost": 621,
                "AverageLineCost": 80,
                "TotalSaleAmount": 80730
            },
            {
                "site": 1468,
                "team": "Green",
                "agent": 14680012,
                "date": "14/12/2020",
                "ScanSpeed": 64,
                "TotalSales": 60,
                "AverageLinesPerSale": 3,
                "TotalLines": 180,
                "LineVoidRate": 0.0356,
                "ReceiptVoidRate": 0.0026,
                "LinesVoided": 7,
                "ReceiptsVoided": 1,
                "RefundValue": 4410,
                "AverageReceiptCost": 2059,
                "AverageLineCost": 157,
                "TotalSaleAmount": 123540
            },
            {
                "site": 1468,
                "team": "Green",
                "agent": 14680012,
                "date": "21/12/2020",
                "ScanSpeed": 54,
                "TotalSales": 142,
                "AverageLinesPerSale": 9,
                "TotalLines": 1278,
                "LineVoidRate": 0.0519,
                "ReceiptVoidRate": 0.0211,
                "LinesVoided": 67,
                "ReceiptsVoided": 3,
                "RefundValue": 4836,
                "AverageReceiptCost": 1595,
                "AverageLineCost": 251,
                "TotalSaleAmount": 226490
            },
            {
                "site": 1468,
                "team": "Green",
                "agent": 14680012,
                "date": "28/12/2020",
                "ScanSpeed": 46,
                "TotalSales": 116,
                "AverageLinesPerSale": 4,
                "TotalLines": 464,
                "LineVoidRate": 0.0435,
                "ReceiptVoidRate": 0.0188,
                "LinesVoided": 21,
                "ReceiptsVoided": 3,
                "RefundValue": 10437,
                "AverageReceiptCost": 3578,
                "AverageLineCost": 454,
                "TotalSaleAmount": 415048
            },
            {
                "site": 1468,
                "team": "Green",
                "agent": 14680013,
                "date": "26/10/2020",
                "ScanSpeed": 12,
                "TotalSales": 101,
                "AverageLinesPerSale": 6,
                "TotalLines": 606,
                "LineVoidRate": 0.0201,
                "ReceiptVoidRate": 0.0206,
                "LinesVoided": 13,
                "ReceiptsVoided": 3,
                "RefundValue": 22338,
                "AverageReceiptCost": 994,
                "AverageLineCost": 449,
                "TotalSaleAmount": 100394
            },
            {
                "site": 1468,
                "team": "Green",
                "agent": 14680013,
                "date": "02/11/2020",
                "ScanSpeed": 53,
                "TotalSales": 173,
                "AverageLinesPerSale": 5,
                "TotalLines": 865,
                "LineVoidRate": 0.0182,
                "ReceiptVoidRate": 0.0287,
                "LinesVoided": 16,
                "ReceiptsVoided": 5,
                "RefundValue": 20385,
                "AverageReceiptCost": 1729,
                "AverageLineCost": 371,
                "TotalSaleAmount": 299117
            },
            {
                "site": 1468,
                "team": "Green",
                "agent": 14680013,
                "date": "09/11/2020",
                "ScanSpeed": 56,
                "TotalSales": 98,
                "AverageLinesPerSale": 7,
                "TotalLines": 686,
                "LineVoidRate": 0.0449,
                "ReceiptVoidRate": 0.0116,
                "LinesVoided": 31,
                "ReceiptsVoided": 2,
                "RefundValue": 6125,
                "AverageReceiptCost": 612,
                "AverageLineCost": 275,
                "TotalSaleAmount": 59976
            },
            {
                "site": 1468,
                "team": "Green",
                "agent": 14680013,
                "date": "16/11/2020",
                "ScanSpeed": 66,
                "TotalSales": 91,
                "AverageLinesPerSale": 6,
                "TotalLines": 546,
                "LineVoidRate": 0.0667,
                "ReceiptVoidRate": 0.0283,
                "LinesVoided": 37,
                "ReceiptsVoided": 3,
                "RefundValue": 4648,
                "AverageReceiptCost": 1304,
                "AverageLineCost": 310,
                "TotalSaleAmount": 118664
            },
            {
                "site": 1468,
                "team": "Green",
                "agent": 14680013,
                "date": "23/11/2020",
                "ScanSpeed": 22,
                "TotalSales": 114,
                "AverageLinesPerSale": 10,
                "TotalLines": 1140,
                "LineVoidRate": 0.0248,
                "ReceiptVoidRate": 0.0053,
                "LinesVoided": 29,
                "ReceiptsVoided": 1,
                "RefundValue": 11048,
                "AverageReceiptCost": 958,
                "AverageLineCost": 274,
                "TotalSaleAmount": 109212
            },
            {
                "site": 1468,
                "team": "Green",
                "agent": 14680013,
                "date": "30/11/2020",
                "ScanSpeed": 88,
                "TotalSales": 153,
                "AverageLinesPerSale": 6,
                "TotalLines": 918,
                "LineVoidRate": 0.0392,
                "ReceiptVoidRate": 0.0065,
                "LinesVoided": 36,
                "ReceiptsVoided": 1,
                "RefundValue": 5561,
                "AverageReceiptCost": 3218,
                "AverageLineCost": 218,
                "TotalSaleAmount": 492354
            },
            {
                "site": 1468,
                "team": "Green",
                "agent": 14680013,
                "date": "07/12/2020",
                "ScanSpeed": 70,
                "TotalSales": 172,
                "AverageLinesPerSale": 11,
                "TotalLines": 1892,
                "LineVoidRate": 0.0193,
                "ReceiptVoidRate": 0.0302,
                "LinesVoided": 37,
                "ReceiptsVoided": 6,
                "RefundValue": 22383,
                "AverageReceiptCost": 2845,
                "AverageLineCost": 432,
                "TotalSaleAmount": 489340
            },
            {
                "site": 1468,
                "team": "Green",
                "agent": 14680013,
                "date": "14/12/2020",
                "ScanSpeed": 57,
                "TotalSales": 163,
                "AverageLinesPerSale": 10,
                "TotalLines": 1630,
                "LineVoidRate": 0.013,
                "ReceiptVoidRate": 0.0199,
                "LinesVoided": 22,
                "ReceiptsVoided": 4,
                "RefundValue": 32231,
                "AverageReceiptCost": 1456,
                "AverageLineCost": 419,
                "TotalSaleAmount": 237328
            },
            {
                "site": 1468,
                "team": "Green",
                "agent": 14680013,
                "date": "21/12/2020",
                "ScanSpeed": 37,
                "TotalSales": 150,
                "AverageLinesPerSale": 3,
                "TotalLines": 450,
                "LineVoidRate": 0.04,
                "ReceiptVoidRate": 0.005,
                "LinesVoided": 18,
                "ReceiptsVoided": 1,
                "RefundValue": 1950,
                "AverageReceiptCost": 528,
                "AverageLineCost": 78,
                "TotalSaleAmount": 79200
            },
            {
                "site": 1468,
                "team": "Green",
                "agent": 14680013,
                "date": "28/12/2020",
                "ScanSpeed": 77,
                "TotalSales": 171,
                "AverageLinesPerSale": 7,
                "TotalLines": 1197,
                "LineVoidRate": 0.0624,
                "ReceiptVoidRate": 0.0247,
                "LinesVoided": 75,
                "ReceiptsVoided": 5,
                "RefundValue": 1474,
                "AverageReceiptCost": 931,
                "AverageLineCost": 92,
                "TotalSaleAmount": 159201
            },
            {
                "site": 1468,
                "team": "Green",
                "agent": 14680014,
                "date": "26/10/2020",
                "ScanSpeed": 35,
                "TotalSales": 25,
                "AverageLinesPerSale": 4,
                "TotalLines": 100,
                "LineVoidRate": 0.0207,
                "ReceiptVoidRate": 0.0196,
                "LinesVoided": 3,
                "ReceiptsVoided": 1,
                "RefundValue": 21401,
                "AverageReceiptCost": 1922,
                "AverageLineCost": 443,
                "TotalSaleAmount": 48050
            },
            {
                "site": 1468,
                "team": "Green",
                "agent": 14680014,
                "date": "02/11/2020",
                "ScanSpeed": 90,
                "TotalSales": 85,
                "AverageLinesPerSale": 6,
                "TotalLines": 510,
                "LineVoidRate": 0.0462,
                "ReceiptVoidRate": 0.033,
                "LinesVoided": 24,
                "ReceiptsVoided": 3,
                "RefundValue": 4242,
                "AverageReceiptCost": 3007,
                "AverageLineCost": 196,
                "TotalSaleAmount": 255595
            },
            {
                "site": 1468,
                "team": "Green",
                "agent": 14680014,
                "date": "09/11/2020",
                "ScanSpeed": 32,
                "TotalSales": 160,
                "AverageLinesPerSale": 7,
                "TotalLines": 1120,
                "LineVoidRate": 0.021,
                "ReceiptVoidRate": 0.0027,
                "LinesVoided": 24,
                "ReceiptsVoided": 1,
                "RefundValue": 14476,
                "AverageReceiptCost": 1485,
                "AverageLineCost": 304,
                "TotalSaleAmount": 237600
            },
            {
                "site": 1468,
                "team": "Green",
                "agent": 14680014,
                "date": "16/11/2020",
                "ScanSpeed": 26,
                "TotalSales": 28,
                "AverageLinesPerSale": 9,
                "TotalLines": 252,
                "LineVoidRate": 0.0027,
                "ReceiptVoidRate": 0.0165,
                "LinesVoided": 1,
                "ReceiptsVoided": 1,
                "RefundValue": 52593,
                "AverageReceiptCost": 3412,
                "AverageLineCost": 142,
                "TotalSaleAmount": 95536
            },
            {
                "site": 1468,
                "team": "Green",
                "agent": 14680014,
                "date": "23/11/2020",
                "ScanSpeed": 62,
                "TotalSales": 59,
                "AverageLinesPerSale": 8,
                "TotalLines": 472,
                "LineVoidRate": 0.0427,
                "ReceiptVoidRate": 0.0204,
                "LinesVoided": 21,
                "ReceiptsVoided": 2,
                "RefundValue": 2623,
                "AverageReceiptCost": 2896,
                "AverageLineCost": 112,
                "TotalSaleAmount": 170864
            },
            {
                "site": 1468,
                "team": "Green",
                "agent": 14680014,
                "date": "30/11/2020",
                "ScanSpeed": 68,
                "TotalSales": 65,
                "AverageLinesPerSale": 3,
                "TotalLines": 195,
                "LineVoidRate": 0.033,
                "ReceiptVoidRate": 0.003,
                "LinesVoided": 7,
                "ReceiptsVoided": 1,
                "RefundValue": 11545,
                "AverageReceiptCost": 3623,
                "AverageLineCost": 381,
                "TotalSaleAmount": 235495
            },
            {
                "site": 1468,
                "team": "Green",
                "agent": 14680014,
                "date": "07/12/2020",
                "ScanSpeed": 17,
                "TotalSales": 175,
                "AverageLinesPerSale": 5,
                "TotalLines": 875,
                "LineVoidRate": 0.0385,
                "ReceiptVoidRate": 0.0363,
                "LinesVoided": 34,
                "ReceiptsVoided": 7,
                "RefundValue": 3896,
                "AverageReceiptCost": 1605,
                "AverageLineCost": 150,
                "TotalSaleAmount": 280875
            },
            {
                "site": 1468,
                "team": "Green",
                "agent": 14680014,
                "date": "14/12/2020",
                "ScanSpeed": 26,
                "TotalSales": 134,
                "AverageLinesPerSale": 11,
                "TotalLines": 1474,
                "LineVoidRate": 0.0519,
                "ReceiptVoidRate": 0.0228,
                "LinesVoided": 77,
                "ReceiptsVoided": 4,
                "RefundValue": 6724,
                "AverageReceiptCost": 3505,
                "AverageLineCost": 349,
                "TotalSaleAmount": 469670
            },
            {
                "site": 1468,
                "team": "Green",
                "agent": 14680014,
                "date": "21/12/2020",
                "ScanSpeed": 35,
                "TotalSales": 16,
                "AverageLinesPerSale": 11,
                "TotalLines": 176,
                "LineVoidRate": 0.0667,
                "ReceiptVoidRate": 0.0194,
                "LinesVoided": 12,
                "ReceiptsVoided": 1,
                "RefundValue": 1574,
                "AverageReceiptCost": 2256,
                "AverageLineCost": 105,
                "TotalSaleAmount": 36096
            },
            {
                "site": 1468,
                "team": "Green",
                "agent": 14680014,
                "date": "28/12/2020",
                "ScanSpeed": 13,
                "TotalSales": 150,
                "AverageLinesPerSale": 8,
                "TotalLines": 1200,
                "LineVoidRate": 0.019,
                "ReceiptVoidRate": 0.0111,
                "LinesVoided": 23,
                "ReceiptsVoided": 2,
                "RefundValue": 3895,
                "AverageReceiptCost": 3115,
                "AverageLineCost": 74,
                "TotalSaleAmount": 467250
            },
            {
                "site": 1468,
                "team": "Green",
                "agent": 14680015,
                "date": "26/10/2020",
                "ScanSpeed": 76,
                "TotalSales": 77,
                "AverageLinesPerSale": 4,
                "TotalLines": 308,
                "LineVoidRate": 0.0287,
                "ReceiptVoidRate": 0.0149,
                "LinesVoided": 9,
                "ReceiptsVoided": 2,
                "RefundValue": 14948,
                "AverageReceiptCost": 416,
                "AverageLineCost": 429,
                "TotalSaleAmount": 32032
            },
            {
                "site": 1468,
                "team": "Green",
                "agent": 14680015,
                "date": "02/11/2020",
                "ScanSpeed": 90,
                "TotalSales": 35,
                "AverageLinesPerSale": 8,
                "TotalLines": 280,
                "LineVoidRate": 0.0344,
                "ReceiptVoidRate": 0.0117,
                "LinesVoided": 10,
                "ReceiptsVoided": 1,
                "RefundValue": 8779,
                "AverageReceiptCost": 3665,
                "AverageLineCost": 302,
                "TotalSaleAmount": 128275
            },
            {
                "site": 1468,
                "team": "Green",
                "agent": 14680015,
                "date": "09/11/2020",
                "ScanSpeed": 42,
                "TotalSales": 30,
                "AverageLinesPerSale": 7,
                "TotalLines": 210,
                "LineVoidRate": 0.0078,
                "ReceiptVoidRate": 0.0031,
                "LinesVoided": 2,
                "ReceiptsVoided": 1,
                "RefundValue": 61154,
                "AverageReceiptCost": 1385,
                "AverageLineCost": 477,
                "TotalSaleAmount": 41550
            },
            {
                "site": 1468,
                "team": "Green",
                "agent": 14680015,
                "date": "16/11/2020",
                "ScanSpeed": 70,
                "TotalSales": 89,
                "AverageLinesPerSale": 7,
                "TotalLines": 623,
                "LineVoidRate": 0.0559,
                "ReceiptVoidRate": 0.0251,
                "LinesVoided": 35,
                "ReceiptsVoided": 3,
                "RefundValue": 3560,
                "AverageReceiptCost": 3163,
                "AverageLineCost": 199,
                "TotalSaleAmount": 281507
            },
            {
                "site": 1468,
                "team": "Green",
                "agent": 14680015,
                "date": "23/11/2020",
                "ScanSpeed": 15,
                "TotalSales": 172,
                "AverageLinesPerSale": 3,
                "TotalLines": 516,
                "LineVoidRate": 0.0539,
                "ReceiptVoidRate": 0.019,
                "LinesVoided": 28,
                "ReceiptsVoided": 4,
                "RefundValue": 2375,
                "AverageReceiptCost": 1612,
                "AverageLineCost": 128,
                "TotalSaleAmount": 277264
            },
            {
                "site": 1468,
                "team": "Green",
                "agent": 14680015,
                "date": "30/11/2020",
                "ScanSpeed": 25,
                "TotalSales": 151,
                "AverageLinesPerSale": 4,
                "TotalLines": 604,
                "LineVoidRate": 0.0144,
                "ReceiptVoidRate": 0.0362,
                "LinesVoided": 9,
                "ReceiptsVoided": 6,
                "RefundValue": 22986,
                "AverageReceiptCost": 2960,
                "AverageLineCost": 331,
                "TotalSaleAmount": 446960
            },
            {
                "site": 1468,
                "team": "Green",
                "agent": 14680015,
                "date": "07/12/2020",
                "ScanSpeed": 82,
                "TotalSales": 128,
                "AverageLinesPerSale": 4,
                "TotalLines": 512,
                "LineVoidRate": 0.0244,
                "ReceiptVoidRate": 0.0045,
                "LinesVoided": 13,
                "ReceiptsVoided": 1,
                "RefundValue": 14713,
                "AverageReceiptCost": 2898,
                "AverageLineCost": 359,
                "TotalSaleAmount": 370944
            },
            {
                "site": 1468,
                "team": "Green",
                "agent": 14680015,
                "date": "14/12/2020",
                "ScanSpeed": 18,
                "TotalSales": 152,
                "AverageLinesPerSale": 4,
                "TotalLines": 608,
                "LineVoidRate": 0.0354,
                "ReceiptVoidRate": 0.0237,
                "LinesVoided": 22,
                "ReceiptsVoided": 4,
                "RefundValue": 5847,
                "AverageReceiptCost": 1827,
                "AverageLineCost": 207,
                "TotalSaleAmount": 277704
            },
            {
                "site": 1468,
                "team": "Green",
                "agent": 14680015,
                "date": "21/12/2020",
                "ScanSpeed": 73,
                "TotalSales": 151,
                "AverageLinesPerSale": 6,
                "TotalLines": 906,
                "LineVoidRate": 0.053,
                "ReceiptVoidRate": 0.027,
                "LinesVoided": 49,
                "ReceiptsVoided": 5,
                "RefundValue": 6623,
                "AverageReceiptCost": 3412,
                "AverageLineCost": 351,
                "TotalSaleAmount": 515212
            },
            {
                "site": 1468,
                "team": "Green",
                "agent": 14680015,
                "date": "28/12/2020",
                "ScanSpeed": 33,
                "TotalSales": 120,
                "AverageLinesPerSale": 8,
                "TotalLines": 960,
                "LineVoidRate": 0.0319,
                "ReceiptVoidRate": 0.0235,
                "LinesVoided": 31,
                "ReceiptsVoided": 3,
                "RefundValue": 2727,
                "AverageReceiptCost": 2044,
                "AverageLineCost": 87,
                "TotalSaleAmount": 245280
            },
            {
                "site": 1468,
                "team": "Green",
                "agent": 14680016,
                "date": "26/10/2020",
                "ScanSpeed": 36,
                "TotalSales": 35,
                "AverageLinesPerSale": 8,
                "TotalLines": 280,
                "LineVoidRate": 0.0388,
                "ReceiptVoidRate": 0.0239,
                "LinesVoided": 11,
                "ReceiptsVoided": 1,
                "RefundValue": 5619,
                "AverageReceiptCost": 1868,
                "AverageLineCost": 218,
                "TotalSaleAmount": 65380
            },
            {
                "site": 1468,
                "team": "Green",
                "agent": 14680016,
                "date": "02/11/2020",
                "ScanSpeed": 48,
                "TotalSales": 43,
                "AverageLinesPerSale": 3,
                "TotalLines": 129,
                "LineVoidRate": 0.0207,
                "ReceiptVoidRate": 0.0265,
                "LinesVoided": 3,
                "ReceiptsVoided": 2,
                "RefundValue": 11594,
                "AverageReceiptCost": 3374,
                "AverageLineCost": 240,
                "TotalSaleAmount": 145082
            },
            {
                "site": 1468,
                "team": "Green",
                "agent": 14680016,
                "date": "09/11/2020",
                "ScanSpeed": 39,
                "TotalSales": 42,
                "AverageLinesPerSale": 5,
                "TotalLines": 210,
                "LineVoidRate": 0.0645,
                "ReceiptVoidRate": 0.0023,
                "LinesVoided": 14,
                "ReceiptsVoided": 1,
                "RefundValue": 7178,
                "AverageReceiptCost": 3439,
                "AverageLineCost": 463,
                "TotalSaleAmount": 144438
            },
            {
                "site": 1468,
                "team": "Green",
                "agent": 14680016,
                "date": "16/11/2020",
                "ScanSpeed": 72,
                "TotalSales": 128,
                "AverageLinesPerSale": 5,
                "TotalLines": 640,
                "LineVoidRate": 0.0531,
                "ReceiptVoidRate": 0.0259,
                "LinesVoided": 34,
                "ReceiptsVoided": 4,
                "RefundValue": 2166,
                "AverageReceiptCost": 2199,
                "AverageLineCost": 115,
                "TotalSaleAmount": 281472
            },
            {
                "site": 1468,
                "team": "Green",
                "agent": 14680016,
                "date": "23/11/2020",
                "ScanSpeed": 79,
                "TotalSales": 160,
                "AverageLinesPerSale": 6,
                "TotalLines": 960,
                "LineVoidRate": 0.0653,
                "ReceiptVoidRate": 0.0217,
                "LinesVoided": 63,
                "ReceiptsVoided": 4,
                "RefundValue": 7228,
                "AverageReceiptCost": 2622,
                "AverageLineCost": 472,
                "TotalSaleAmount": 419520
            },
            {
                "site": 1468,
                "team": "Green",
                "agent": 14680016,
                "date": "30/11/2020",
                "ScanSpeed": 82,
                "TotalSales": 33,
                "AverageLinesPerSale": 5,
                "TotalLines": 165,
                "LineVoidRate": 0.0285,
                "ReceiptVoidRate": 0.0087,
                "LinesVoided": 5,
                "ReceiptsVoided": 1,
                "RefundValue": 4491,
                "AverageReceiptCost": 974,
                "AverageLineCost": 128,
                "TotalSaleAmount": 32142
            },
            {
                "site": 1468,
                "team": "Green",
                "agent": 14680016,
                "date": "07/12/2020",
                "ScanSpeed": 68,
                "TotalSales": 48,
                "AverageLinesPerSale": 4,
                "TotalLines": 192,
                "LineVoidRate": 0.0152,
                "ReceiptVoidRate": 0.0206,
                "LinesVoided": 3,
                "ReceiptsVoided": 1,
                "RefundValue": 27829,
                "AverageReceiptCost": 402,
                "AverageLineCost": 423,
                "TotalSaleAmount": 19296
            },
            {
                "site": 1468,
                "team": "Green",
                "agent": 14680016,
                "date": "14/12/2020",
                "ScanSpeed": 37,
                "TotalSales": 107,
                "AverageLinesPerSale": 4,
                "TotalLines": 428,
                "LineVoidRate": 0.0244,
                "ReceiptVoidRate": 0.03,
                "LinesVoided": 11,
                "ReceiptsVoided": 4,
                "RefundValue": 8156,
                "AverageReceiptCost": 3292,
                "AverageLineCost": 199,
                "TotalSaleAmount": 352244
            },
            {
                "site": 1468,
                "team": "Green",
                "agent": 14680016,
                "date": "21/12/2020",
                "ScanSpeed": 46,
                "TotalSales": 169,
                "AverageLinesPerSale": 3,
                "TotalLines": 507,
                "LineVoidRate": 0.0454,
                "ReceiptVoidRate": 0.0072,
                "LinesVoided": 24,
                "ReceiptsVoided": 2,
                "RefundValue": 5903,
                "AverageReceiptCost": 1787,
                "AverageLineCost": 268,
                "TotalSaleAmount": 302003
            },
            {
                "site": 1468,
                "team": "Green",
                "agent": 14680016,
                "date": "28/12/2020",
                "ScanSpeed": 31,
                "TotalSales": 169,
                "AverageLinesPerSale": 11,
                "TotalLines": 1859,
                "LineVoidRate": 0.0284,
                "ReceiptVoidRate": 0.0387,
                "LinesVoided": 53,
                "ReceiptsVoided": 7,
                "RefundValue": 12852,
                "AverageReceiptCost": 3439,
                "AverageLineCost": 365,
                "TotalSaleAmount": 581191
            },
            {
                "site": 1468,
                "team": "Green",
                "agent": 14680017,
                "date": "26/10/2020",
                "ScanSpeed": 86,
                "TotalSales": 93,
                "AverageLinesPerSale": 8,
                "TotalLines": 744,
                "LineVoidRate": 0.0142,
                "ReceiptVoidRate": 0.0283,
                "LinesVoided": 11,
                "ReceiptsVoided": 3,
                "RefundValue": 15070,
                "AverageReceiptCost": 4208,
                "AverageLineCost": 214,
                "TotalSaleAmount": 391344
            },
            {
                "site": 1468,
                "team": "Green",
                "agent": 14680017,
                "date": "02/11/2020",
                "ScanSpeed": 55,
                "TotalSales": 59,
                "AverageLinesPerSale": 10,
                "TotalLines": 590,
                "LineVoidRate": 0.0475,
                "ReceiptVoidRate": 0.0317,
                "LinesVoided": 29,
                "ReceiptsVoided": 2,
                "RefundValue": 6253,
                "AverageReceiptCost": 3626,
                "AverageLineCost": 297,
                "TotalSaleAmount": 213934
            },
            {
                "site": 1468,
                "team": "Green",
                "agent": 14680017,
                "date": "09/11/2020",
                "ScanSpeed": 14,
                "TotalSales": 59,
                "AverageLinesPerSale": 5,
                "TotalLines": 295,
                "LineVoidRate": 0.0654,
                "ReceiptVoidRate": 0.0226,
                "LinesVoided": 20,
                "ReceiptsVoided": 2,
                "RefundValue": 2905,
                "AverageReceiptCost": 2146,
                "AverageLineCost": 190,
                "TotalSaleAmount": 126614
            },
            {
                "site": 1468,
                "team": "Green",
                "agent": 14680017,
                "date": "16/11/2020",
                "ScanSpeed": 82,
                "TotalSales": 98,
                "AverageLinesPerSale": 5,
                "TotalLines": 490,
                "LineVoidRate": 0.0605,
                "ReceiptVoidRate": 0.0292,
                "LinesVoided": 30,
                "ReceiptsVoided": 3,
                "RefundValue": 7140,
                "AverageReceiptCost": 1976,
                "AverageLineCost": 432,
                "TotalSaleAmount": 193648
            },
            {
                "site": 1468,
                "team": "Green",
                "agent": 14680017,
                "date": "23/11/2020",
                "ScanSpeed": 58,
                "TotalSales": 133,
                "AverageLinesPerSale": 10,
                "TotalLines": 1330,
                "LineVoidRate": 0.0635,
                "ReceiptVoidRate": 0.0349,
                "LinesVoided": 85,
                "ReceiptsVoided": 5,
                "RefundValue": 1449,
                "AverageReceiptCost": 524,
                "AverageLineCost": 92,
                "TotalSaleAmount": 69692
            },
            {
                "site": 1468,
                "team": "Green",
                "agent": 14680017,
                "date": "30/11/2020",
                "ScanSpeed": 40,
                "TotalSales": 26,
                "AverageLinesPerSale": 3,
                "TotalLines": 78,
                "LineVoidRate": 0.0138,
                "ReceiptVoidRate": 0.0067,
                "LinesVoided": 2,
                "ReceiptsVoided": 1,
                "RefundValue": 29638,
                "AverageReceiptCost": 3945,
                "AverageLineCost": 409,
                "TotalSaleAmount": 102570
            },
            {
                "site": 1468,
                "team": "Green",
                "agent": 14680017,
                "date": "07/12/2020",
                "ScanSpeed": 24,
                "TotalSales": 170,
                "AverageLinesPerSale": 7,
                "TotalLines": 1190,
                "LineVoidRate": 0.0125,
                "ReceiptVoidRate": 0.001,
                "LinesVoided": 15,
                "ReceiptsVoided": 1,
                "RefundValue": 34000,
                "AverageReceiptCost": 888,
                "AverageLineCost": 425,
                "TotalSaleAmount": 150960
            },
            {
                "site": 1468,
                "team": "Green",
                "agent": 14680017,
                "date": "14/12/2020",
                "ScanSpeed": 11,
                "TotalSales": 133,
                "AverageLinesPerSale": 4,
                "TotalLines": 532,
                "LineVoidRate": 0.0062,
                "ReceiptVoidRate": 0.0112,
                "LinesVoided": 4,
                "ReceiptsVoided": 2,
                "RefundValue": 71290,
                "AverageReceiptCost": 4192,
                "AverageLineCost": 442,
                "TotalSaleAmount": 557536
            },
            {
                "site": 1468,
                "team": "Green",
                "agent": 14680017,
                "date": "21/12/2020",
                "ScanSpeed": 59,
                "TotalSales": 156,
                "AverageLinesPerSale": 4,
                "TotalLines": 624,
                "LineVoidRate": 0.0103,
                "ReceiptVoidRate": 0.0389,
                "LinesVoided": 7,
                "ReceiptsVoided": 7,
                "RefundValue": 33107,
                "AverageReceiptCost": 855,
                "AverageLineCost": 341,
                "TotalSaleAmount": 133380
            },
            {
                "site": 1468,
                "team": "Green",
                "agent": 14680017,
                "date": "28/12/2020",
                "ScanSpeed": 47,
                "TotalSales": 72,
                "AverageLinesPerSale": 8,
                "TotalLines": 576,
                "LineVoidRate": 0.0055,
                "ReceiptVoidRate": 0.0129,
                "LinesVoided": 4,
                "ReceiptsVoided": 1,
                "RefundValue": 14182,
                "AverageReceiptCost": 957,
                "AverageLineCost": 78,
                "TotalSaleAmount": 68904
            },
            {
                "site": 2259,
                "team": "Red",
                "agent": 22590001,
                "date": "26/10/2020",
                "ScanSpeed": 50,
                "TotalSales": 166,
                "AverageLinesPerSale": 11,
                "TotalLines": 1826,
                "LineVoidRate": 0.0426,
                "ReceiptVoidRate": 0.034,
                "LinesVoided": 78,
                "ReceiptsVoided": 6,
                "RefundValue": 2160,
                "AverageReceiptCost": 1068,
                "AverageLineCost": 92,
                "TotalSaleAmount": 177288
            },
            {
                "site": 2259,
                "team": "Red",
                "agent": 22590001,
                "date": "02/11/2020",
                "ScanSpeed": 57,
                "TotalSales": 134,
                "AverageLinesPerSale": 10,
                "TotalLines": 1340,
                "LineVoidRate": 0.0437,
                "ReceiptVoidRate": 0.0353,
                "LinesVoided": 59,
                "ReceiptsVoided": 5,
                "RefundValue": 2403,
                "AverageReceiptCost": 4418,
                "AverageLineCost": 105,
                "TotalSaleAmount": 592012
            },
            {
                "site": 2259,
                "team": "Red",
                "agent": 22590001,
                "date": "09/11/2020",
                "ScanSpeed": 49,
                "TotalSales": 80,
                "AverageLinesPerSale": 6,
                "TotalLines": 480,
                "LineVoidRate": 0.0176,
                "ReceiptVoidRate": 0.0259,
                "LinesVoided": 9,
                "ReceiptsVoided": 3,
                "RefundValue": 11023,
                "AverageReceiptCost": 3968,
                "AverageLineCost": 194,
                "TotalSaleAmount": 317440
            },
            {
                "site": 2259,
                "team": "Red",
                "agent": 22590001,
                "date": "16/11/2020",
                "ScanSpeed": 90,
                "TotalSales": 104,
                "AverageLinesPerSale": 10,
                "TotalLines": 1040,
                "LineVoidRate": 0.0441,
                "ReceiptVoidRate": 0.0215,
                "LinesVoided": 46,
                "ReceiptsVoided": 3,
                "RefundValue": 9388,
                "AverageReceiptCost": 4101,
                "AverageLineCost": 414,
                "TotalSaleAmount": 426504
            },
            {
                "site": 2259,
                "team": "Red",
                "agent": 22590001,
                "date": "23/11/2020",
                "ScanSpeed": 48,
                "TotalSales": 44,
                "AverageLinesPerSale": 8,
                "TotalLines": 352,
                "LineVoidRate": 0.0633,
                "ReceiptVoidRate": 0.0179,
                "LinesVoided": 23,
                "ReceiptsVoided": 1,
                "RefundValue": 2512,
                "AverageReceiptCost": 4408,
                "AverageLineCost": 159,
                "TotalSaleAmount": 193952
            },
            {
                "site": 2259,
                "team": "Red",
                "agent": 22590001,
                "date": "30/11/2020",
                "ScanSpeed": 35,
                "TotalSales": 15,
                "AverageLinesPerSale": 11,
                "TotalLines": 165,
                "LineVoidRate": 0.0052,
                "ReceiptVoidRate": 0.0281,
                "LinesVoided": 1,
                "ReceiptsVoided": 1,
                "RefundValue": 78846,
                "AverageReceiptCost": 1467,
                "AverageLineCost": 410,
                "TotalSaleAmount": 22005
            },
            {
                "site": 2259,
                "team": "Red",
                "agent": 22590001,
                "date": "07/12/2020",
                "ScanSpeed": 57,
                "TotalSales": 170,
                "AverageLinesPerSale": 6,
                "TotalLines": 1020,
                "LineVoidRate": 0.0156,
                "ReceiptVoidRate": 0.0165,
                "LinesVoided": 16,
                "ReceiptsVoided": 3,
                "RefundValue": 20192,
                "AverageReceiptCost": 3829,
                "AverageLineCost": 315,
                "TotalSaleAmount": 650930
            },
            {
                "site": 2259,
                "team": "Red",
                "agent": 22590001,
                "date": "14/12/2020",
                "ScanSpeed": 22,
                "TotalSales": 115,
                "AverageLinesPerSale": 9,
                "TotalLines": 1035,
                "LineVoidRate": 0.0637,
                "ReceiptVoidRate": 0.0071,
                "LinesVoided": 66,
                "ReceiptsVoided": 1,
                "RefundValue": 3312,
                "AverageReceiptCost": 2506,
                "AverageLineCost": 211,
                "TotalSaleAmount": 288190
            },
            {
                "site": 2259,
                "team": "Red",
                "agent": 22590001,
                "date": "21/12/2020",
                "ScanSpeed": 40,
                "TotalSales": 36,
                "AverageLinesPerSale": 3,
                "TotalLines": 108,
                "LineVoidRate": 0.0051,
                "ReceiptVoidRate": 0.0302,
                "LinesVoided": 1,
                "ReceiptsVoided": 2,
                "RefundValue": 36275,
                "AverageReceiptCost": 4176,
                "AverageLineCost": 185,
                "TotalSaleAmount": 150336
            },
            {
                "site": 2259,
                "team": "Red",
                "agent": 22590001,
                "date": "28/12/2020",
                "ScanSpeed": 72,
                "TotalSales": 101,
                "AverageLinesPerSale": 6,
                "TotalLines": 606,
                "LineVoidRate": 0.0095,
                "ReceiptVoidRate": 0.0199,
                "LinesVoided": 6,
                "ReceiptsVoided": 3,
                "RefundValue": 40000,
                "AverageReceiptCost": 3804,
                "AverageLineCost": 380,
                "TotalSaleAmount": 384204
            },
            {
                "site": 2259,
                "team": "Red",
                "agent": 22590002,
                "date": "26/10/2020",
                "ScanSpeed": 78,
                "TotalSales": 170,
                "AverageLinesPerSale": 9,
                "TotalLines": 1530,
                "LineVoidRate": 0.0411,
                "ReceiptVoidRate": 0.0247,
                "LinesVoided": 63,
                "ReceiptsVoided": 5,
                "RefundValue": 10949,
                "AverageReceiptCost": 2976,
                "AverageLineCost": 450,
                "TotalSaleAmount": 505920
            },
            {
                "site": 2259,
                "team": "Red",
                "agent": 22590002,
                "date": "02/11/2020",
                "ScanSpeed": 46,
                "TotalSales": 168,
                "AverageLinesPerSale": 11,
                "TotalLines": 1848,
                "LineVoidRate": 0.0255,
                "ReceiptVoidRate": 0.0064,
                "LinesVoided": 48,
                "ReceiptsVoided": 2,
                "RefundValue": 17647,
                "AverageReceiptCost": 2658,
                "AverageLineCost": 450,
                "TotalSaleAmount": 446544
            },
            {
                "site": 2259,
                "team": "Red",
                "agent": 22590002,
                "date": "09/11/2020",
                "ScanSpeed": 70,
                "TotalSales": 148,
                "AverageLinesPerSale": 8,
                "TotalLines": 1184,
                "LineVoidRate": 0.0023,
                "ReceiptVoidRate": 0.0046,
                "LinesVoided": 3,
                "ReceiptsVoided": 1,
                "RefundValue": 138261,
                "AverageReceiptCost": 1882,
                "AverageLineCost": 318,
                "TotalSaleAmount": 278536
            },
            {
                "site": 2259,
                "team": "Red",
                "agent": 22590002,
                "date": "16/11/2020",
                "ScanSpeed": 18,
                "TotalSales": 101,
                "AverageLinesPerSale": 4,
                "TotalLines": 404,
                "LineVoidRate": 0.0619,
                "ReceiptVoidRate": 0.0037,
                "LinesVoided": 26,
                "ReceiptsVoided": 1,
                "RefundValue": 1082,
                "AverageReceiptCost": 837,
                "AverageLineCost": 67,
                "TotalSaleAmount": 84537
            },
            {
                "site": 2259,
                "team": "Red",
                "agent": 22590002,
                "date": "23/11/2020",
                "ScanSpeed": 80,
                "TotalSales": 165,
                "AverageLinesPerSale": 10,
                "TotalLines": 1650,
                "LineVoidRate": 0.0005,
                "ReceiptVoidRate": 0.0059,
                "LinesVoided": 1,
                "ReceiptsVoided": 1,
                "RefundValue": 560000,
                "AverageReceiptCost": 1704,
                "AverageLineCost": 280,
                "TotalSaleAmount": 281160
            },
            {
                "site": 2259,
                "team": "Red",
                "agent": 22590002,
                "date": "30/11/2020",
                "ScanSpeed": 56,
                "TotalSales": 122,
                "AverageLinesPerSale": 9,
                "TotalLines": 1098,
                "LineVoidRate": 0.0272,
                "ReceiptVoidRate": 0.0221,
                "LinesVoided": 30,
                "ReceiptsVoided": 3,
                "RefundValue": 2353,
                "AverageReceiptCost": 2013,
                "AverageLineCost": 64,
                "TotalSaleAmount": 245586
            },
            {
                "site": 2259,
                "team": "Red",
                "agent": 22590002,
                "date": "07/12/2020",
                "ScanSpeed": 41,
                "TotalSales": 104,
                "AverageLinesPerSale": 10,
                "TotalLines": 1040,
                "LineVoidRate": 0.0188,
                "ReceiptVoidRate": 0.0344,
                "LinesVoided": 20,
                "ReceiptsVoided": 4,
                "RefundValue": 7340,
                "AverageReceiptCost": 2365,
                "AverageLineCost": 138,
                "TotalSaleAmount": 245960
            },
            {
                "site": 2259,
                "team": "Red",
                "agent": 22590002,
                "date": "14/12/2020",
                "ScanSpeed": 83,
                "TotalSales": 127,
                "AverageLinesPerSale": 6,
                "TotalLines": 762,
                "LineVoidRate": 0.0362,
                "ReceiptVoidRate": 0.0109,
                "LinesVoided": 28,
                "ReceiptsVoided": 2,
                "RefundValue": 10304,
                "AverageReceiptCost": 2552,
                "AverageLineCost": 373,
                "TotalSaleAmount": 324104
            },
            {
                "site": 2259,
                "team": "Red",
                "agent": 22590002,
                "date": "21/12/2020",
                "ScanSpeed": 83,
                "TotalSales": 168,
                "AverageLinesPerSale": 9,
                "TotalLines": 1512,
                "LineVoidRate": 0.0615,
                "ReceiptVoidRate": 0.0395,
                "LinesVoided": 93,
                "ReceiptsVoided": 7,
                "RefundValue": 7593,
                "AverageReceiptCost": 2658,
                "AverageLineCost": 467,
                "TotalSaleAmount": 446544
            },
            {
                "site": 2259,
                "team": "Red",
                "agent": 22590002,
                "date": "28/12/2020",
                "ScanSpeed": 35,
                "TotalSales": 51,
                "AverageLinesPerSale": 8,
                "TotalLines": 408,
                "LineVoidRate": 0.0684,
                "ReceiptVoidRate": 0.0147,
                "LinesVoided": 28,
                "ReceiptsVoided": 1,
                "RefundValue": 2734,
                "AverageReceiptCost": 2066,
                "AverageLineCost": 187,
                "TotalSaleAmount": 105366
            },
            {
                "site": 2259,
                "team": "Red",
                "agent": 22590003,
                "date": "26/10/2020",
                "ScanSpeed": 15,
                "TotalSales": 172,
                "AverageLinesPerSale": 6,
                "TotalLines": 1032,
                "LineVoidRate": 0.0393,
                "ReceiptVoidRate": 0.0275,
                "LinesVoided": 41,
                "ReceiptsVoided": 5,
                "RefundValue": 12290,
                "AverageReceiptCost": 3378,
                "AverageLineCost": 483,
                "TotalSaleAmount": 581016
            },
            {
                "site": 2259,
                "team": "Red",
                "agent": 22590003,
                "date": "02/11/2020",
                "ScanSpeed": 72,
                "TotalSales": 59,
                "AverageLinesPerSale": 9,
                "TotalLines": 531,
                "LineVoidRate": 0.0273,
                "ReceiptVoidRate": 0.0157,
                "LinesVoided": 15,
                "ReceiptsVoided": 1,
                "RefundValue": 8059,
                "AverageReceiptCost": 341,
                "AverageLineCost": 220,
                "TotalSaleAmount": 20119
            },
            {
                "site": 2259,
                "team": "Red",
                "agent": 22590003,
                "date": "09/11/2020",
                "ScanSpeed": 26,
                "TotalSales": 158,
                "AverageLinesPerSale": 11,
                "TotalLines": 1738,
                "LineVoidRate": 0.0183,
                "ReceiptVoidRate": 0.0336,
                "LinesVoided": 32,
                "ReceiptsVoided": 6,
                "RefundValue": 14973,
                "AverageReceiptCost": 573,
                "AverageLineCost": 274,
                "TotalSaleAmount": 90534
            },
            {
                "site": 2259,
                "team": "Red",
                "agent": 22590003,
                "date": "16/11/2020",
                "ScanSpeed": 31,
                "TotalSales": 45,
                "AverageLinesPerSale": 4,
                "TotalLines": 180,
                "LineVoidRate": 0.0261,
                "ReceiptVoidRate": 0.0382,
                "LinesVoided": 5,
                "ReceiptsVoided": 2,
                "RefundValue": 3755,
                "AverageReceiptCost": 989,
                "AverageLineCost": 98,
                "TotalSaleAmount": 44505
            },
            {
                "site": 2259,
                "team": "Red",
                "agent": 22590003,
                "date": "23/11/2020",
                "ScanSpeed": 87,
                "TotalSales": 100,
                "AverageLinesPerSale": 4,
                "TotalLines": 400,
                "LineVoidRate": 0.0255,
                "ReceiptVoidRate": 0.0126,
                "LinesVoided": 11,
                "ReceiptsVoided": 2,
                "RefundValue": 6627,
                "AverageReceiptCost": 1769,
                "AverageLineCost": 169,
                "TotalSaleAmount": 176900
            },
            {
                "site": 2259,
                "team": "Red",
                "agent": 22590003,
                "date": "30/11/2020",
                "ScanSpeed": 52,
                "TotalSales": 121,
                "AverageLinesPerSale": 11,
                "TotalLines": 1331,
                "LineVoidRate": 0.0225,
                "ReceiptVoidRate": 0.0048,
                "LinesVoided": 30,
                "ReceiptsVoided": 1,
                "RefundValue": 4356,
                "AverageReceiptCost": 3701,
                "AverageLineCost": 98,
                "TotalSaleAmount": 447821
            },
            {
                "site": 2259,
                "team": "Red",
                "agent": 22590003,
                "date": "07/12/2020",
                "ScanSpeed": 60,
                "TotalSales": 153,
                "AverageLinesPerSale": 3,
                "TotalLines": 459,
                "LineVoidRate": 0.0063,
                "ReceiptVoidRate": 0.0121,
                "LinesVoided": 3,
                "ReceiptsVoided": 2,
                "RefundValue": 34921,
                "AverageReceiptCost": 2439,
                "AverageLineCost": 220,
                "TotalSaleAmount": 373167
            },
            {
                "site": 2259,
                "team": "Red",
                "agent": 22590003,
                "date": "14/12/2020",
                "ScanSpeed": 71,
                "TotalSales": 107,
                "AverageLinesPerSale": 11,
                "TotalLines": 1177,
                "LineVoidRate": 0.052,
                "ReceiptVoidRate": 0.007,
                "LinesVoided": 62,
                "ReceiptsVoided": 1,
                "RefundValue": 2962,
                "AverageReceiptCost": 662,
                "AverageLineCost": 154,
                "TotalSaleAmount": 70834
            },
            {
                "site": 2259,
                "team": "Red",
                "agent": 22590003,
                "date": "21/12/2020",
                "ScanSpeed": 45,
                "TotalSales": 77,
                "AverageLinesPerSale": 6,
                "TotalLines": 462,
                "LineVoidRate": 0.0436,
                "ReceiptVoidRate": 0.0244,
                "LinesVoided": 21,
                "ReceiptsVoided": 2,
                "RefundValue": 10780,
                "AverageReceiptCost": 1472,
                "AverageLineCost": 470,
                "TotalSaleAmount": 113344
            },
            {
                "site": 2259,
                "team": "Red",
                "agent": 22590003,
                "date": "28/12/2020",
                "ScanSpeed": 31,
                "TotalSales": 131,
                "AverageLinesPerSale": 3,
                "TotalLines": 393,
                "LineVoidRate": 0.0436,
                "ReceiptVoidRate": 0.0143,
                "LinesVoided": 18,
                "ReceiptsVoided": 2,
                "RefundValue": 9289,
                "AverageReceiptCost": 3918,
                "AverageLineCost": 405,
                "TotalSaleAmount": 513258
            },
            {
                "site": 2259,
                "team": "Blue",
                "agent": 22590004,
                "date": "26/10/2020",
                "ScanSpeed": 56,
                "TotalSales": 91,
                "AverageLinesPerSale": 5,
                "TotalLines": 455,
                "LineVoidRate": 0.0284,
                "ReceiptVoidRate": 0.0208,
                "LinesVoided": 13,
                "ReceiptsVoided": 2,
                "RefundValue": 1866,
                "AverageReceiptCost": 2412,
                "AverageLineCost": 53,
                "TotalSaleAmount": 219492
            },
            {
                "site": 2259,
                "team": "Blue",
                "agent": 22590004,
                "date": "02/11/2020",
                "ScanSpeed": 66,
                "TotalSales": 175,
                "AverageLinesPerSale": 3,
                "TotalLines": 525,
                "LineVoidRate": 0.058,
                "ReceiptVoidRate": 0.0193,
                "LinesVoided": 31,
                "ReceiptsVoided": 4,
                "RefundValue": 6207,
                "AverageReceiptCost": 3113,
                "AverageLineCost": 360,
                "TotalSaleAmount": 544775
            },
            {
                "site": 2259,
                "team": "Blue",
                "agent": 22590004,
                "date": "09/11/2020",
                "ScanSpeed": 39,
                "TotalSales": 47,
                "AverageLinesPerSale": 3,
                "TotalLines": 141,
                "LineVoidRate": 0.0635,
                "ReceiptVoidRate": 0.0074,
                "LinesVoided": 9,
                "ReceiptsVoided": 1,
                "RefundValue": 6299,
                "AverageReceiptCost": 2797,
                "AverageLineCost": 400,
                "TotalSaleAmount": 131459
            },
            {
                "site": 2259,
                "team": "Blue",
                "agent": 22590004,
                "date": "16/11/2020",
                "ScanSpeed": 24,
                "TotalSales": 16,
                "AverageLinesPerSale": 11,
                "TotalLines": 176,
                "LineVoidRate": 0.0685,
                "ReceiptVoidRate": 0.0387,
                "LinesVoided": 13,
                "ReceiptsVoided": 1,
                "RefundValue": 1022,
                "AverageReceiptCost": 1732,
                "AverageLineCost": 70,
                "TotalSaleAmount": 27712
            },
            {
                "site": 2259,
                "team": "Blue",
                "agent": 22590004,
                "date": "23/11/2020",
                "ScanSpeed": 12,
                "TotalSales": 29,
                "AverageLinesPerSale": 7,
                "TotalLines": 203,
                "LineVoidRate": 0.0308,
                "ReceiptVoidRate": 0.0395,
                "LinesVoided": 7,
                "ReceiptsVoided": 2,
                "RefundValue": 3409,
                "AverageReceiptCost": 4071,
                "AverageLineCost": 105,
                "TotalSaleAmount": 118059
            },
            {
                "site": 2259,
                "team": "Blue",
                "agent": 22590004,
                "date": "30/11/2020",
                "ScanSpeed": 38,
                "TotalSales": 131,
                "AverageLinesPerSale": 6,
                "TotalLines": 786,
                "LineVoidRate": 0.0685,
                "ReceiptVoidRate": 0.0316,
                "LinesVoided": 54,
                "ReceiptsVoided": 5,
                "RefundValue": 2350,
                "AverageReceiptCost": 2527,
                "AverageLineCost": 161,
                "TotalSaleAmount": 331037
            },
            {
                "site": 2259,
                "team": "Blue",
                "agent": 22590004,
                "date": "07/12/2020",
                "ScanSpeed": 23,
                "TotalSales": 137,
                "AverageLinesPerSale": 4,
                "TotalLines": 548,
                "LineVoidRate": 0.06,
                "ReceiptVoidRate": 0.0335,
                "LinesVoided": 33,
                "ReceiptsVoided": 5,
                "RefundValue": 8033,
                "AverageReceiptCost": 2414,
                "AverageLineCost": 482,
                "TotalSaleAmount": 330718
            },
            {
                "site": 2259,
                "team": "Blue",
                "agent": 22590004,
                "date": "14/12/2020",
                "ScanSpeed": 46,
                "TotalSales": 81,
                "AverageLinesPerSale": 10,
                "TotalLines": 810,
                "LineVoidRate": 0.0671,
                "ReceiptVoidRate": 0.0074,
                "LinesVoided": 55,
                "ReceiptsVoided": 1,
                "RefundValue": 2340,
                "AverageReceiptCost": 2868,
                "AverageLineCost": 157,
                "TotalSaleAmount": 232308
            },
            {
                "site": 2259,
                "team": "Blue",
                "agent": 22590004,
                "date": "21/12/2020",
                "ScanSpeed": 74,
                "TotalSales": 160,
                "AverageLinesPerSale": 9,
                "TotalLines": 1440,
                "LineVoidRate": 0.0484,
                "ReceiptVoidRate": 0.0125,
                "LinesVoided": 70,
                "ReceiptsVoided": 2,
                "RefundValue": 2975,
                "AverageReceiptCost": 2161,
                "AverageLineCost": 144,
                "TotalSaleAmount": 345760
            },
            {
                "site": 2259,
                "team": "Blue",
                "agent": 22590004,
                "date": "28/12/2020",
                "ScanSpeed": 17,
                "TotalSales": 121,
                "AverageLinesPerSale": 3,
                "TotalLines": 363,
                "LineVoidRate": 0.0331,
                "ReceiptVoidRate": 0.007,
                "LinesVoided": 13,
                "ReceiptsVoided": 1,
                "RefundValue": 13958,
                "AverageReceiptCost": 1046,
                "AverageLineCost": 462,
                "TotalSaleAmount": 126566
            },
            {
                "site": 2259,
                "team": "Blue",
                "agent": 22590005,
                "date": "26/10/2020",
                "ScanSpeed": 41,
                "TotalSales": 114,
                "AverageLinesPerSale": 3,
                "TotalLines": 342,
                "LineVoidRate": 0.0137,
                "ReceiptVoidRate": 0.0124,
                "LinesVoided": 5,
                "ReceiptsVoided": 2,
                "RefundValue": 25547,
                "AverageReceiptCost": 1950,
                "AverageLineCost": 350,
                "TotalSaleAmount": 222300
            },
            {
                "site": 2259,
                "team": "Blue",
                "agent": 22590005,
                "date": "02/11/2020",
                "ScanSpeed": 82,
                "TotalSales": 103,
                "AverageLinesPerSale": 3,
                "TotalLines": 309,
                "LineVoidRate": 0.0275,
                "ReceiptVoidRate": 0.0116,
                "LinesVoided": 9,
                "ReceiptsVoided": 2,
                "RefundValue": 9491,
                "AverageReceiptCost": 1507,
                "AverageLineCost": 261,
                "TotalSaleAmount": 155221
            },
            {
                "site": 2259,
                "team": "Blue",
                "agent": 22590005,
                "date": "09/11/2020",
                "ScanSpeed": 84,
                "TotalSales": 100,
                "AverageLinesPerSale": 10,
                "TotalLines": 1000,
                "LineVoidRate": 0.039,
                "ReceiptVoidRate": 0.0202,
                "LinesVoided": 39,
                "ReceiptsVoided": 3,
                "RefundValue": 9231,
                "AverageReceiptCost": 2445,
                "AverageLineCost": 360,
                "TotalSaleAmount": 244500
            },
            {
                "site": 2259,
                "team": "Blue",
                "agent": 22590005,
                "date": "16/11/2020",
                "ScanSpeed": 70,
                "TotalSales": 122,
                "AverageLinesPerSale": 11,
                "TotalLines": 1342,
                "LineVoidRate": 0.0485,
                "ReceiptVoidRate": 0.0068,
                "LinesVoided": 66,
                "ReceiptsVoided": 1,
                "RefundValue": 3608,
                "AverageReceiptCost": 419,
                "AverageLineCost": 175,
                "TotalSaleAmount": 51118
            },
            {
                "site": 2259,
                "team": "Blue",
                "agent": 22590005,
                "date": "23/11/2020",
                "ScanSpeed": 14,
                "TotalSales": 153,
                "AverageLinesPerSale": 9,
                "TotalLines": 1377,
                "LineVoidRate": 0.0482,
                "ReceiptVoidRate": 0.0201,
                "LinesVoided": 67,
                "ReceiptsVoided": 4,
                "RefundValue": 9896,
                "AverageReceiptCost": 2819,
                "AverageLineCost": 477,
                "TotalSaleAmount": 431307
            },
            {
                "site": 2259,
                "team": "Blue",
                "agent": 22590005,
                "date": "30/11/2020",
                "ScanSpeed": 74,
                "TotalSales": 64,
                "AverageLinesPerSale": 8,
                "TotalLines": 512,
                "LineVoidRate": 0.0492,
                "ReceiptVoidRate": 0.0104,
                "LinesVoided": 26,
                "ReceiptsVoided": 1,
                "RefundValue": 4309,
                "AverageReceiptCost": 2655,
                "AverageLineCost": 212,
                "TotalSaleAmount": 169920
            },
            {
                "site": 2259,
                "team": "Blue",
                "agent": 22590005,
                "date": "07/12/2020",
                "ScanSpeed": 32,
                "TotalSales": 128,
                "AverageLinesPerSale": 10,
                "TotalLines": 1280,
                "LineVoidRate": 0.0644,
                "ReceiptVoidRate": 0.0227,
                "LinesVoided": 83,
                "ReceiptsVoided": 3,
                "RefundValue": 2158,
                "AverageReceiptCost": 2890,
                "AverageLineCost": 139,
                "TotalSaleAmount": 369920
            },
            {
                "site": 2259,
                "team": "Blue",
                "agent": 22590005,
                "date": "14/12/2020",
                "ScanSpeed": 81,
                "TotalSales": 43,
                "AverageLinesPerSale": 5,
                "TotalLines": 215,
                "LineVoidRate": 0.0478,
                "ReceiptVoidRate": 0.0367,
                "LinesVoided": 11,
                "ReceiptsVoided": 2,
                "RefundValue": 1423,
                "AverageReceiptCost": 1319,
                "AverageLineCost": 68,
                "TotalSaleAmount": 56717
            },
            {
                "site": 2259,
                "team": "Blue",
                "agent": 22590005,
                "date": "21/12/2020",
                "ScanSpeed": 41,
                "TotalSales": 104,
                "AverageLinesPerSale": 4,
                "TotalLines": 416,
                "LineVoidRate": 0.0345,
                "ReceiptVoidRate": 0.0055,
                "LinesVoided": 15,
                "ReceiptsVoided": 1,
                "RefundValue": 1652,
                "AverageReceiptCost": 4412,
                "AverageLineCost": 57,
                "TotalSaleAmount": 458848
            },
            {
                "site": 2259,
                "team": "Blue",
                "agent": 22590005,
                "date": "28/12/2020",
                "ScanSpeed": 85,
                "TotalSales": 72,
                "AverageLinesPerSale": 8,
                "TotalLines": 576,
                "LineVoidRate": 0.0367,
                "ReceiptVoidRate": 0.0197,
                "LinesVoided": 22,
                "ReceiptsVoided": 2,
                "RefundValue": 12507,
                "AverageReceiptCost": 478,
                "AverageLineCost": 459,
                "TotalSaleAmount": 34416
            },
            {
                "site": 2259,
                "team": "Blue",
                "agent": 22590006,
                "date": "26/10/2020",
                "ScanSpeed": 15,
                "TotalSales": 110,
                "AverageLinesPerSale": 11,
                "TotalLines": 1210,
                "LineVoidRate": 0.0246,
                "ReceiptVoidRate": 0,
                "LinesVoided": 30,
                "ReceiptsVoided": 0,
                "RefundValue": 19431,
                "AverageReceiptCost": 1328,
                "AverageLineCost": 478,
                "TotalSaleAmount": 146080
            },
            {
                "site": 2259,
                "team": "Blue",
                "agent": 22590006,
                "date": "02/11/2020",
                "ScanSpeed": 21,
                "TotalSales": 131,
                "AverageLinesPerSale": 7,
                "TotalLines": 917,
                "LineVoidRate": 0.0469,
                "ReceiptVoidRate": 0.0059,
                "LinesVoided": 44,
                "ReceiptsVoided": 1,
                "RefundValue": 6461,
                "AverageReceiptCost": 2157,
                "AverageLineCost": 303,
                "TotalSaleAmount": 282567
            },
            {
                "site": 2259,
                "team": "Blue",
                "agent": 22590006,
                "date": "09/11/2020",
                "ScanSpeed": 24,
                "TotalSales": 100,
                "AverageLinesPerSale": 6,
                "TotalLines": 600,
                "LineVoidRate": 0.0427,
                "ReceiptVoidRate": 0.032,
                "LinesVoided": 26,
                "ReceiptsVoided": 4,
                "RefundValue": 11054,
                "AverageReceiptCost": 1346,
                "AverageLineCost": 472,
                "TotalSaleAmount": 134600
            },
            {
                "site": 2259,
                "team": "Blue",
                "agent": 22590006,
                "date": "16/11/2020",
                "ScanSpeed": 30,
                "TotalSales": 170,
                "AverageLinesPerSale": 3,
                "TotalLines": 510,
                "LineVoidRate": 0.008,
                "ReceiptVoidRate": 0.0196,
                "LinesVoided": 5,
                "ReceiptsVoided": 4,
                "RefundValue": 35250,
                "AverageReceiptCost": 1821,
                "AverageLineCost": 282,
                "TotalSaleAmount": 309570
            },
            {
                "site": 2259,
                "team": "Blue",
                "agent": 22590006,
                "date": "23/11/2020",
                "ScanSpeed": 89,
                "TotalSales": 100,
                "AverageLinesPerSale": 4,
                "TotalLines": 400,
                "LineVoidRate": 0.0021,
                "ReceiptVoidRate": 0.0088,
                "LinesVoided": 1,
                "ReceiptsVoided": 1,
                "RefundValue": 101429,
                "AverageReceiptCost": 3600,
                "AverageLineCost": 213,
                "TotalSaleAmount": 360000
            },
            {
                "site": 2259,
                "team": "Blue",
                "agent": 22590006,
                "date": "30/11/2020",
                "ScanSpeed": 76,
                "TotalSales": 99,
                "AverageLinesPerSale": 3,
                "TotalLines": 297,
                "LineVoidRate": 0.0447,
                "ReceiptVoidRate": 0.0026,
                "LinesVoided": 14,
                "ReceiptsVoided": 1,
                "RefundValue": 2573,
                "AverageReceiptCost": 3623,
                "AverageLineCost": 115,
                "TotalSaleAmount": 358677
            },
            {
                "site": 2259,
                "team": "Blue",
                "agent": 22590006,
                "date": "07/12/2020",
                "ScanSpeed": 31,
                "TotalSales": 32,
                "AverageLinesPerSale": 6,
                "TotalLines": 192,
                "LineVoidRate": 0.0563,
                "ReceiptVoidRate": 0.0368,
                "LinesVoided": 11,
                "ReceiptsVoided": 2,
                "RefundValue": 7513,
                "AverageReceiptCost": 384,
                "AverageLineCost": 423,
                "TotalSaleAmount": 12288
            },
            {
                "site": 2259,
                "team": "Blue",
                "agent": 22590006,
                "date": "14/12/2020",
                "ScanSpeed": 63,
                "TotalSales": 44,
                "AverageLinesPerSale": 11,
                "TotalLines": 484,
                "LineVoidRate": 0.0111,
                "ReceiptVoidRate": 0.0384,
                "LinesVoided": 6,
                "ReceiptsVoided": 2,
                "RefundValue": 31892,
                "AverageReceiptCost": 1987,
                "AverageLineCost": 354,
                "TotalSaleAmount": 87428
            },
            {
                "site": 2259,
                "team": "Blue",
                "agent": 22590006,
                "date": "21/12/2020",
                "ScanSpeed": 29,
                "TotalSales": 131,
                "AverageLinesPerSale": 9,
                "TotalLines": 1179,
                "LineVoidRate": 0.0395,
                "ReceiptVoidRate": 0.0063,
                "LinesVoided": 47,
                "ReceiptsVoided": 1,
                "RefundValue": 2152,
                "AverageReceiptCost": 627,
                "AverageLineCost": 85,
                "TotalSaleAmount": 82137
            },
            {
                "site": 2259,
                "team": "Blue",
                "agent": 22590006,
                "date": "28/12/2020",
                "ScanSpeed": 63,
                "TotalSales": 56,
                "AverageLinesPerSale": 9,
                "TotalLines": 504,
                "LineVoidRate": 0.0221,
                "ReceiptVoidRate": 0.0077,
                "LinesVoided": 12,
                "ReceiptsVoided": 1,
                "RefundValue": 18688,
                "AverageReceiptCost": 4049,
                "AverageLineCost": 413,
                "TotalSaleAmount": 226744
            },
            {
                "site": 2259,
                "team": "Blue",
                "agent": 22590007,
                "date": "26/10/2020",
                "ScanSpeed": 73,
                "TotalSales": 35,
                "AverageLinesPerSale": 7,
                "TotalLines": 245,
                "LineVoidRate": 0.0354,
                "ReceiptVoidRate": 0.0321,
                "LinesVoided": 9,
                "ReceiptsVoided": 2,
                "RefundValue": 8983,
                "AverageReceiptCost": 1258,
                "AverageLineCost": 318,
                "TotalSaleAmount": 44030
            },
            {
                "site": 2259,
                "team": "Blue",
                "agent": 22590007,
                "date": "02/11/2020",
                "ScanSpeed": 82,
                "TotalSales": 165,
                "AverageLinesPerSale": 10,
                "TotalLines": 1650,
                "LineVoidRate": 0.0494,
                "ReceiptVoidRate": 0.0254,
                "LinesVoided": 82,
                "ReceiptsVoided": 5,
                "RefundValue": 6579,
                "AverageReceiptCost": 1390,
                "AverageLineCost": 325,
                "TotalSaleAmount": 229350
            },
            {
                "site": 2259,
                "team": "Blue",
                "agent": 22590007,
                "date": "09/11/2020",
                "ScanSpeed": 71,
                "TotalSales": 145,
                "AverageLinesPerSale": 3,
                "TotalLines": 435,
                "LineVoidRate": 0.0084,
                "ReceiptVoidRate": 0.0024,
                "LinesVoided": 4,
                "ReceiptsVoided": 1,
                "RefundValue": 39643,
                "AverageReceiptCost": 325,
                "AverageLineCost": 333,
                "TotalSaleAmount": 47125
            },
            {
                "site": 2259,
                "team": "Blue",
                "agent": 22590007,
                "date": "16/11/2020",
                "ScanSpeed": 29,
                "TotalSales": 62,
                "AverageLinesPerSale": 7,
                "TotalLines": 434,
                "LineVoidRate": 0.0036,
                "ReceiptVoidRate": 0.0261,
                "LinesVoided": 2,
                "ReceiptsVoided": 2,
                "RefundValue": 87222,
                "AverageReceiptCost": 3596,
                "AverageLineCost": 314,
                "TotalSaleAmount": 222952
            },
            {
                "site": 2259,
                "team": "Blue",
                "agent": 22590007,
                "date": "23/11/2020",
                "ScanSpeed": 66,
                "TotalSales": 150,
                "AverageLinesPerSale": 4,
                "TotalLines": 600,
                "LineVoidRate": 0.0079,
                "ReceiptVoidRate": 0.0246,
                "LinesVoided": 5,
                "ReceiptsVoided": 4,
                "RefundValue": 45190,
                "AverageReceiptCost": 805,
                "AverageLineCost": 357,
                "TotalSaleAmount": 120750
            },
            {
                "site": 2259,
                "team": "Blue",
                "agent": 22590007,
                "date": "30/11/2020",
                "ScanSpeed": 82,
                "TotalSales": 19,
                "AverageLinesPerSale": 4,
                "TotalLines": 76,
                "LineVoidRate": 0.0441,
                "ReceiptVoidRate": 0.024,
                "LinesVoided": 4,
                "ReceiptsVoided": 1,
                "RefundValue": 9274,
                "AverageReceiptCost": 775,
                "AverageLineCost": 409,
                "TotalSaleAmount": 14725
            },
            {
                "site": 2259,
                "team": "Blue",
                "agent": 22590007,
                "date": "07/12/2020",
                "ScanSpeed": 23,
                "TotalSales": 85,
                "AverageLinesPerSale": 3,
                "TotalLines": 255,
                "LineVoidRate": 0.0653,
                "ReceiptVoidRate": 0.013,
                "LinesVoided": 17,
                "ReceiptsVoided": 2,
                "RefundValue": 1593,
                "AverageReceiptCost": 2274,
                "AverageLineCost": 104,
                "TotalSaleAmount": 193290
            },
            {
                "site": 2259,
                "team": "Blue",
                "agent": 22590007,
                "date": "14/12/2020",
                "ScanSpeed": 90,
                "TotalSales": 19,
                "AverageLinesPerSale": 4,
                "TotalLines": 76,
                "LineVoidRate": 0.0045,
                "ReceiptVoidRate": 0.0362,
                "LinesVoided": 1,
                "ReceiptsVoided": 1,
                "RefundValue": 45111,
                "AverageReceiptCost": 1994,
                "AverageLineCost": 203,
                "TotalSaleAmount": 37886
            },
            {
                "site": 2259,
                "team": "Blue",
                "agent": 22590007,
                "date": "21/12/2020",
                "ScanSpeed": 88,
                "TotalSales": 170,
                "AverageLinesPerSale": 6,
                "TotalLines": 1020,
                "LineVoidRate": 0.0054,
                "ReceiptVoidRate": 0.0389,
                "LinesVoided": 6,
                "ReceiptsVoided": 7,
                "RefundValue": 82037,
                "AverageReceiptCost": 4346,
                "AverageLineCost": 443,
                "TotalSaleAmount": 738820
            },
            {
                "site": 2259,
                "team": "Blue",
                "agent": 22590007,
                "date": "28/12/2020",
                "ScanSpeed": 69,
                "TotalSales": 115,
                "AverageLinesPerSale": 8,
                "TotalLines": 920,
                "LineVoidRate": 0.0383,
                "ReceiptVoidRate": 0.0161,
                "LinesVoided": 36,
                "ReceiptsVoided": 2,
                "RefundValue": 7520,
                "AverageReceiptCost": 3450,
                "AverageLineCost": 288,
                "TotalSaleAmount": 396750
            },
            {
                "site": 2259,
                "team": "Blue",
                "agent": 22590008,
                "date": "26/10/2020",
                "ScanSpeed": 14,
                "TotalSales": 30,
                "AverageLinesPerSale": 7,
                "TotalLines": 210,
                "LineVoidRate": 0.043,
                "ReceiptVoidRate": 0.0129,
                "LinesVoided": 10,
                "ReceiptsVoided": 1,
                "RefundValue": 2163,
                "AverageReceiptCost": 859,
                "AverageLineCost": 93,
                "TotalSaleAmount": 25770
            },
            {
                "site": 2259,
                "team": "Blue",
                "agent": 22590008,
                "date": "02/11/2020",
                "ScanSpeed": 77,
                "TotalSales": 20,
                "AverageLinesPerSale": 10,
                "TotalLines": 200,
                "LineVoidRate": 0.0186,
                "ReceiptVoidRate": 0.036,
                "LinesVoided": 4,
                "ReceiptsVoided": 1,
                "RefundValue": 17043,
                "AverageReceiptCost": 4077,
                "AverageLineCost": 317,
                "TotalSaleAmount": 81540
            },
            {
                "site": 2259,
                "team": "Blue",
                "agent": 22590008,
                "date": "09/11/2020",
                "ScanSpeed": 33,
                "TotalSales": 166,
                "AverageLinesPerSale": 8,
                "TotalLines": 1328,
                "LineVoidRate": 0.0131,
                "ReceiptVoidRate": 0.0245,
                "LinesVoided": 18,
                "ReceiptsVoided": 5,
                "RefundValue": 3817,
                "AverageReceiptCost": 3119,
                "AverageLineCost": 50,
                "TotalSaleAmount": 517754
            },
            {
                "site": 2259,
                "team": "Blue",
                "agent": 22590008,
                "date": "16/11/2020",
                "ScanSpeed": 31,
                "TotalSales": 93,
                "AverageLinesPerSale": 11,
                "TotalLines": 1023,
                "LineVoidRate": 0.0164,
                "ReceiptVoidRate": 0.0385,
                "LinesVoided": 17,
                "ReceiptsVoided": 4,
                "RefundValue": 11159,
                "AverageReceiptCost": 528,
                "AverageLineCost": 183,
                "TotalSaleAmount": 49104
            },
            {
                "site": 2259,
                "team": "Blue",
                "agent": 22590008,
                "date": "23/11/2020",
                "ScanSpeed": 79,
                "TotalSales": 92,
                "AverageLinesPerSale": 8,
                "TotalLines": 736,
                "LineVoidRate": 0.0364,
                "ReceiptVoidRate": 0.0125,
                "LinesVoided": 27,
                "ReceiptsVoided": 2,
                "RefundValue": 11071,
                "AverageReceiptCost": 1589,
                "AverageLineCost": 403,
                "TotalSaleAmount": 146188
            },
            {
                "site": 2259,
                "team": "Blue",
                "agent": 22590008,
                "date": "30/11/2020",
                "ScanSpeed": 85,
                "TotalSales": 166,
                "AverageLinesPerSale": 8,
                "TotalLines": 1328,
                "LineVoidRate": 0.0232,
                "ReceiptVoidRate": 0.0176,
                "LinesVoided": 31,
                "ReceiptsVoided": 3,
                "RefundValue": 9267,
                "AverageReceiptCost": 3016,
                "AverageLineCost": 215,
                "TotalSaleAmount": 500656
            },
            {
                "site": 2259,
                "team": "Blue",
                "agent": 22590008,
                "date": "07/12/2020",
                "ScanSpeed": 27,
                "TotalSales": 140,
                "AverageLinesPerSale": 11,
                "TotalLines": 1540,
                "LineVoidRate": 0.0208,
                "ReceiptVoidRate": 0.026,
                "LinesVoided": 33,
                "ReceiptsVoided": 4,
                "RefundValue": 10144,
                "AverageReceiptCost": 1498,
                "AverageLineCost": 211,
                "TotalSaleAmount": 209720
            },
            {
                "site": 2259,
                "team": "Blue",
                "agent": 22590008,
                "date": "14/12/2020",
                "ScanSpeed": 40,
                "TotalSales": 42,
                "AverageLinesPerSale": 11,
                "TotalLines": 462,
                "LineVoidRate": 0.0222,
                "ReceiptVoidRate": 0.0104,
                "LinesVoided": 11,
                "ReceiptsVoided": 1,
                "RefundValue": 8739,
                "AverageReceiptCost": 2724,
                "AverageLineCost": 194,
                "TotalSaleAmount": 114408
            },
            {
                "site": 2259,
                "team": "Blue",
                "agent": 22590008,
                "date": "21/12/2020",
                "ScanSpeed": 44,
                "TotalSales": 134,
                "AverageLinesPerSale": 9,
                "TotalLines": 1206,
                "LineVoidRate": 0.0594,
                "ReceiptVoidRate": 0.0355,
                "LinesVoided": 72,
                "ReceiptsVoided": 5,
                "RefundValue": 6061,
                "AverageReceiptCost": 731,
                "AverageLineCost": 360,
                "TotalSaleAmount": 97954
            },
            {
                "site": 2259,
                "team": "Blue",
                "agent": 22590008,
                "date": "28/12/2020",
                "ScanSpeed": 59,
                "TotalSales": 61,
                "AverageLinesPerSale": 7,
                "TotalLines": 427,
                "LineVoidRate": 0.049,
                "ReceiptVoidRate": 0.0024,
                "LinesVoided": 21,
                "ReceiptsVoided": 1,
                "RefundValue": 4224,
                "AverageReceiptCost": 4024,
                "AverageLineCost": 207,
                "TotalSaleAmount": 245464
            },
            {
                "site": 2259,
                "team": "Blue",
                "agent": 22590009,
                "date": "26/10/2020",
                "ScanSpeed": 27,
                "TotalSales": 70,
                "AverageLinesPerSale": 5,
                "TotalLines": 350,
                "LineVoidRate": 0.0507,
                "ReceiptVoidRate": 0.0269,
                "LinesVoided": 18,
                "ReceiptsVoided": 2,
                "RefundValue": 6864,
                "AverageReceiptCost": 573,
                "AverageLineCost": 348,
                "TotalSaleAmount": 40110
            },
            {
                "site": 2259,
                "team": "Blue",
                "agent": 22590009,
                "date": "02/11/2020",
                "ScanSpeed": 85,
                "TotalSales": 121,
                "AverageLinesPerSale": 10,
                "TotalLines": 1210,
                "LineVoidRate": 0.0674,
                "ReceiptVoidRate": 0.04,
                "LinesVoided": 82,
                "ReceiptsVoided": 5,
                "RefundValue": 2181,
                "AverageReceiptCost": 2308,
                "AverageLineCost": 147,
                "TotalSaleAmount": 279268
            },
            {
                "site": 2259,
                "team": "Blue",
                "agent": 22590009,
                "date": "09/11/2020",
                "ScanSpeed": 56,
                "TotalSales": 129,
                "AverageLinesPerSale": 9,
                "TotalLines": 1161,
                "LineVoidRate": 0.0005,
                "ReceiptVoidRate": 0.0089,
                "LinesVoided": 1,
                "ReceiptsVoided": 2,
                "RefundValue": 306000,
                "AverageReceiptCost": 3222,
                "AverageLineCost": 153,
                "TotalSaleAmount": 415638
            },
            {
                "site": 2259,
                "team": "Blue",
                "agent": 22590009,
                "date": "16/11/2020",
                "ScanSpeed": 67,
                "TotalSales": 87,
                "AverageLinesPerSale": 7,
                "TotalLines": 609,
                "LineVoidRate": 0.0028,
                "ReceiptVoidRate": 0.0081,
                "LinesVoided": 2,
                "ReceiptsVoided": 1,
                "RefundValue": 61071,
                "AverageReceiptCost": 3161,
                "AverageLineCost": 171,
                "TotalSaleAmount": 275007
            },
            {
                "site": 2259,
                "team": "Blue",
                "agent": 22590009,
                "date": "23/11/2020",
                "ScanSpeed": 57,
                "TotalSales": 82,
                "AverageLinesPerSale": 10,
                "TotalLines": 820,
                "LineVoidRate": 0.0522,
                "ReceiptVoidRate": 0.009,
                "LinesVoided": 43,
                "ReceiptsVoided": 1,
                "RefundValue": 4023,
                "AverageReceiptCost": 2108,
                "AverageLineCost": 210,
                "TotalSaleAmount": 172856
            },
            {
                "site": 2259,
                "team": "Blue",
                "agent": 22590009,
                "date": "30/11/2020",
                "ScanSpeed": 47,
                "TotalSales": 73,
                "AverageLinesPerSale": 6,
                "TotalLines": 438,
                "LineVoidRate": 0.0321,
                "ReceiptVoidRate": 0.0322,
                "LinesVoided": 15,
                "ReceiptsVoided": 3,
                "RefundValue": 12399,
                "AverageReceiptCost": 3401,
                "AverageLineCost": 398,
                "TotalSaleAmount": 248273
            },
            {
                "site": 2259,
                "team": "Blue",
                "agent": 22590009,
                "date": "07/12/2020",
                "ScanSpeed": 52,
                "TotalSales": 95,
                "AverageLinesPerSale": 3,
                "TotalLines": 285,
                "LineVoidRate": 0.0484,
                "ReceiptVoidRate": 0.0243,
                "LinesVoided": 14,
                "ReceiptsVoided": 3,
                "RefundValue": 9339,
                "AverageReceiptCost": 1420,
                "AverageLineCost": 452,
                "TotalSaleAmount": 134900
            },
            {
                "site": 2259,
                "team": "Blue",
                "agent": 22590009,
                "date": "14/12/2020",
                "ScanSpeed": 55,
                "TotalSales": 52,
                "AverageLinesPerSale": 4,
                "TotalLines": 208,
                "LineVoidRate": 0.0246,
                "ReceiptVoidRate": 0.0114,
                "LinesVoided": 6,
                "ReceiptsVoided": 1,
                "RefundValue": 13699,
                "AverageReceiptCost": 508,
                "AverageLineCost": 337,
                "TotalSaleAmount": 26416
            },
            {
                "site": 2259,
                "team": "Blue",
                "agent": 22590009,
                "date": "21/12/2020",
                "ScanSpeed": 49,
                "TotalSales": 69,
                "AverageLinesPerSale": 3,
                "TotalLines": 207,
                "LineVoidRate": 0.0167,
                "ReceiptVoidRate": 0.0098,
                "LinesVoided": 4,
                "ReceiptsVoided": 1,
                "RefundValue": 21617,
                "AverageReceiptCost": 2618,
                "AverageLineCost": 361,
                "TotalSaleAmount": 180642
            },
            {
                "site": 2259,
                "team": "Blue",
                "agent": 22590009,
                "date": "28/12/2020",
                "ScanSpeed": 65,
                "TotalSales": 91,
                "AverageLinesPerSale": 6,
                "TotalLines": 546,
                "LineVoidRate": 0.0371,
                "ReceiptVoidRate": 0.0012,
                "LinesVoided": 21,
                "ReceiptsVoided": 1,
                "RefundValue": 3612,
                "AverageReceiptCost": 3429,
                "AverageLineCost": 134,
                "TotalSaleAmount": 312039
            },
            {
                "site": 2259,
                "team": "Green",
                "agent": 22590010,
                "date": "26/10/2020",
                "ScanSpeed": 63,
                "TotalSales": 85,
                "AverageLinesPerSale": 8,
                "TotalLines": 680,
                "LineVoidRate": 0.0187,
                "ReceiptVoidRate": 0.0034,
                "LinesVoided": 13,
                "ReceiptsVoided": 1,
                "RefundValue": 22674,
                "AverageReceiptCost": 4371,
                "AverageLineCost": 424,
                "TotalSaleAmount": 371535
            },
            {
                "site": 2259,
                "team": "Green",
                "agent": 22590010,
                "date": "02/11/2020",
                "ScanSpeed": 75,
                "TotalSales": 70,
                "AverageLinesPerSale": 4,
                "TotalLines": 280,
                "LineVoidRate": 0.0669,
                "ReceiptVoidRate": 0.0022,
                "LinesVoided": 19,
                "ReceiptsVoided": 1,
                "RefundValue": 4828,
                "AverageReceiptCost": 1818,
                "AverageLineCost": 323,
                "TotalSaleAmount": 127260
            },
            {
                "site": 2259,
                "team": "Green",
                "agent": 22590010,
                "date": "09/11/2020",
                "ScanSpeed": 63,
                "TotalSales": 114,
                "AverageLinesPerSale": 8,
                "TotalLines": 912,
                "LineVoidRate": 0.0038,
                "ReceiptVoidRate": 0.0243,
                "LinesVoided": 4,
                "ReceiptsVoided": 3,
                "RefundValue": 120263,
                "AverageReceiptCost": 2128,
                "AverageLineCost": 457,
                "TotalSaleAmount": 242592
            },
            {
                "site": 2259,
                "team": "Green",
                "agent": 22590010,
                "date": "16/11/2020",
                "ScanSpeed": 57,
                "TotalSales": 129,
                "AverageLinesPerSale": 3,
                "TotalLines": 387,
                "LineVoidRate": 0.0448,
                "ReceiptVoidRate": 0.0336,
                "LinesVoided": 18,
                "ReceiptsVoided": 5,
                "RefundValue": 10290,
                "AverageReceiptCost": 1917,
                "AverageLineCost": 461,
                "TotalSaleAmount": 247293
            },
            {
                "site": 2259,
                "team": "Green",
                "agent": 22590010,
                "date": "23/11/2020",
                "ScanSpeed": 20,
                "TotalSales": 166,
                "AverageLinesPerSale": 3,
                "TotalLines": 498,
                "LineVoidRate": 0.0579,
                "ReceiptVoidRate": 0.0297,
                "LinesVoided": 29,
                "ReceiptsVoided": 5,
                "RefundValue": 3972,
                "AverageReceiptCost": 4098,
                "AverageLineCost": 230,
                "TotalSaleAmount": 680268
            },
            {
                "site": 2259,
                "team": "Green",
                "agent": 22590010,
                "date": "30/11/2020",
                "ScanSpeed": 81,
                "TotalSales": 58,
                "AverageLinesPerSale": 9,
                "TotalLines": 522,
                "LineVoidRate": 0.0567,
                "ReceiptVoidRate": 0.0161,
                "LinesVoided": 30,
                "ReceiptsVoided": 1,
                "RefundValue": 7160,
                "AverageReceiptCost": 3540,
                "AverageLineCost": 406,
                "TotalSaleAmount": 205320
            },
            {
                "site": 2259,
                "team": "Green",
                "agent": 22590010,
                "date": "07/12/2020",
                "ScanSpeed": 31,
                "TotalSales": 29,
                "AverageLinesPerSale": 11,
                "TotalLines": 319,
                "LineVoidRate": 0.0671,
                "ReceiptVoidRate": 0.0346,
                "LinesVoided": 22,
                "ReceiptsVoided": 2,
                "RefundValue": 4292,
                "AverageReceiptCost": 738,
                "AverageLineCost": 288,
                "TotalSaleAmount": 21402
            },
            {
                "site": 2259,
                "team": "Green",
                "agent": 22590010,
                "date": "14/12/2020",
                "ScanSpeed": 84,
                "TotalSales": 34,
                "AverageLinesPerSale": 10,
                "TotalLines": 340,
                "LineVoidRate": 0.0176,
                "ReceiptVoidRate": 0.0167,
                "LinesVoided": 6,
                "ReceiptsVoided": 1,
                "RefundValue": 27955,
                "AverageReceiptCost": 2959,
                "AverageLineCost": 492,
                "TotalSaleAmount": 100606
            },
            {
                "site": 2259,
                "team": "Green",
                "agent": 22590010,
                "date": "21/12/2020",
                "ScanSpeed": 31,
                "TotalSales": 117,
                "AverageLinesPerSale": 7,
                "TotalLines": 819,
                "LineVoidRate": 0.062,
                "ReceiptVoidRate": 0.0185,
                "LinesVoided": 51,
                "ReceiptsVoided": 3,
                "RefundValue": 3016,
                "AverageReceiptCost": 3867,
                "AverageLineCost": 187,
                "TotalSaleAmount": 452439
            },
            {
                "site": 2259,
                "team": "Green",
                "agent": 22590010,
                "date": "28/12/2020",
                "ScanSpeed": 82,
                "TotalSales": 78,
                "AverageLinesPerSale": 11,
                "TotalLines": 858,
                "LineVoidRate": 0.0408,
                "ReceiptVoidRate": 0.0194,
                "LinesVoided": 36,
                "ReceiptsVoided": 2,
                "RefundValue": 4069,
                "AverageReceiptCost": 3153,
                "AverageLineCost": 166,
                "TotalSaleAmount": 245934
            },
            {
                "site": 2259,
                "team": "Green",
                "agent": 22590011,
                "date": "26/10/2020",
                "ScanSpeed": 73,
                "TotalSales": 39,
                "AverageLinesPerSale": 11,
                "TotalLines": 429,
                "LineVoidRate": 0.0145,
                "ReceiptVoidRate": 0.0265,
                "LinesVoided": 7,
                "ReceiptsVoided": 2,
                "RefundValue": 33793,
                "AverageReceiptCost": 1208,
                "AverageLineCost": 490,
                "TotalSaleAmount": 47112
            },
            {
                "site": 2259,
                "team": "Green",
                "agent": 22590011,
                "date": "02/11/2020",
                "ScanSpeed": 22,
                "TotalSales": 118,
                "AverageLinesPerSale": 10,
                "TotalLines": 1180,
                "LineVoidRate": 0.0661,
                "ReceiptVoidRate": 0.0281,
                "LinesVoided": 78,
                "ReceiptsVoided": 4,
                "RefundValue": 5794,
                "AverageReceiptCost": 2427,
                "AverageLineCost": 383,
                "TotalSaleAmount": 286386
            },
            {
                "site": 2259,
                "team": "Green",
                "agent": 22590011,
                "date": "09/11/2020",
                "ScanSpeed": 30,
                "TotalSales": 96,
                "AverageLinesPerSale": 9,
                "TotalLines": 864,
                "LineVoidRate": 0.0623,
                "ReceiptVoidRate": 0.0329,
                "LinesVoided": 54,
                "ReceiptsVoided": 4,
                "RefundValue": 3002,
                "AverageReceiptCost": 4030,
                "AverageLineCost": 187,
                "TotalSaleAmount": 386880
            },
            {
                "site": 2259,
                "team": "Green",
                "agent": 22590011,
                "date": "16/11/2020",
                "ScanSpeed": 52,
                "TotalSales": 16,
                "AverageLinesPerSale": 5,
                "TotalLines": 80,
                "LineVoidRate": 0.0365,
                "ReceiptVoidRate": 0.0383,
                "LinesVoided": 3,
                "ReceiptsVoided": 1,
                "RefundValue": 12822,
                "AverageReceiptCost": 1651,
                "AverageLineCost": 468,
                "TotalSaleAmount": 26416
            },
            {
                "site": 2259,
                "team": "Green",
                "agent": 22590011,
                "date": "23/11/2020",
                "ScanSpeed": 77,
                "TotalSales": 88,
                "AverageLinesPerSale": 11,
                "TotalLines": 968,
                "LineVoidRate": 0.0307,
                "ReceiptVoidRate": 0.0066,
                "LinesVoided": 30,
                "ReceiptsVoided": 1,
                "RefundValue": 3257,
                "AverageReceiptCost": 597,
                "AverageLineCost": 100,
                "TotalSaleAmount": 52536
            },
            {
                "site": 2259,
                "team": "Green",
                "agent": 22590011,
                "date": "30/11/2020",
                "ScanSpeed": 24,
                "TotalSales": 43,
                "AverageLinesPerSale": 10,
                "TotalLines": 430,
                "LineVoidRate": 0.0376,
                "ReceiptVoidRate": 0.0025,
                "LinesVoided": 17,
                "ReceiptsVoided": 1,
                "RefundValue": 13218,
                "AverageReceiptCost": 599,
                "AverageLineCost": 497,
                "TotalSaleAmount": 25757
            },
            {
                "site": 2259,
                "team": "Green",
                "agent": 22590011,
                "date": "07/12/2020",
                "ScanSpeed": 54,
                "TotalSales": 88,
                "AverageLinesPerSale": 8,
                "TotalLines": 704,
                "LineVoidRate": 0.0305,
                "ReceiptVoidRate": 0.0286,
                "LinesVoided": 22,
                "ReceiptsVoided": 3,
                "RefundValue": 14721,
                "AverageReceiptCost": 3506,
                "AverageLineCost": 449,
                "TotalSaleAmount": 308528
            },
            {
                "site": 2259,
                "team": "Green",
                "agent": 22590011,
                "date": "14/12/2020",
                "ScanSpeed": 20,
                "TotalSales": 144,
                "AverageLinesPerSale": 3,
                "TotalLines": 432,
                "LineVoidRate": 0.0516,
                "ReceiptVoidRate": 0.0026,
                "LinesVoided": 23,
                "ReceiptsVoided": 1,
                "RefundValue": 3585,
                "AverageReceiptCost": 704,
                "AverageLineCost": 185,
                "TotalSaleAmount": 101376
            },
            {
                "site": 2259,
                "team": "Green",
                "agent": 22590011,
                "date": "21/12/2020",
                "ScanSpeed": 66,
                "TotalSales": 138,
                "AverageLinesPerSale": 9,
                "TotalLines": 1242,
                "LineVoidRate": 0.0696,
                "ReceiptVoidRate": 0.0283,
                "LinesVoided": 87,
                "ReceiptsVoided": 4,
                "RefundValue": 819,
                "AverageReceiptCost": 817,
                "AverageLineCost": 57,
                "TotalSaleAmount": 112746
            },
            {
                "site": 2259,
                "team": "Green",
                "agent": 22590011,
                "date": "28/12/2020",
                "ScanSpeed": 63,
                "TotalSales": 135,
                "AverageLinesPerSale": 3,
                "TotalLines": 405,
                "LineVoidRate": 0.0061,
                "ReceiptVoidRate": 0.0347,
                "LinesVoided": 3,
                "ReceiptsVoided": 5,
                "RefundValue": 60000,
                "AverageReceiptCost": 349,
                "AverageLineCost": 366,
                "TotalSaleAmount": 47115
            },
            {
                "site": 2259,
                "team": "Green",
                "agent": 22590012,
                "date": "26/10/2020",
                "ScanSpeed": 61,
                "TotalSales": 71,
                "AverageLinesPerSale": 8,
                "TotalLines": 568,
                "LineVoidRate": 0.0445,
                "ReceiptVoidRate": 0.0063,
                "LinesVoided": 26,
                "ReceiptsVoided": 1,
                "RefundValue": 5416,
                "AverageReceiptCost": 4400,
                "AverageLineCost": 241,
                "TotalSaleAmount": 312400
            },
            {
                "site": 2259,
                "team": "Green",
                "agent": 22590012,
                "date": "02/11/2020",
                "ScanSpeed": 21,
                "TotalSales": 119,
                "AverageLinesPerSale": 5,
                "TotalLines": 595,
                "LineVoidRate": 0.0162,
                "ReceiptVoidRate": 0.0344,
                "LinesVoided": 10,
                "ReceiptsVoided": 5,
                "RefundValue": 6049,
                "AverageReceiptCost": 1438,
                "AverageLineCost": 98,
                "TotalSaleAmount": 171122
            },
            {
                "site": 2259,
                "team": "Green",
                "agent": 22590012,
                "date": "09/11/2020",
                "ScanSpeed": 72,
                "TotalSales": 29,
                "AverageLinesPerSale": 3,
                "TotalLines": 87,
                "LineVoidRate": 0.053,
                "ReceiptVoidRate": 0.0345,
                "LinesVoided": 5,
                "ReceiptsVoided": 2,
                "RefundValue": 2755,
                "AverageReceiptCost": 3514,
                "AverageLineCost": 146,
                "TotalSaleAmount": 101906
            },
            {
                "site": 2259,
                "team": "Green",
                "agent": 22590012,
                "date": "16/11/2020",
                "ScanSpeed": 85,
                "TotalSales": 16,
                "AverageLinesPerSale": 7,
                "TotalLines": 112,
                "LineVoidRate": 0.0168,
                "ReceiptVoidRate": 0.0373,
                "LinesVoided": 2,
                "ReceiptsVoided": 1,
                "RefundValue": 7381,
                "AverageReceiptCost": 2024,
                "AverageLineCost": 124,
                "TotalSaleAmount": 32384
            },
            {
                "site": 2259,
                "team": "Green",
                "agent": 22590012,
                "date": "23/11/2020",
                "ScanSpeed": 43,
                "TotalSales": 40,
                "AverageLinesPerSale": 9,
                "TotalLines": 360,
                "LineVoidRate": 0.0082,
                "ReceiptVoidRate": 0.0014,
                "LinesVoided": 3,
                "ReceiptsVoided": 1,
                "RefundValue": 38902,
                "AverageReceiptCost": 333,
                "AverageLineCost": 319,
                "TotalSaleAmount": 13320
            },
            {
                "site": 2259,
                "team": "Green",
                "agent": 22590012,
                "date": "30/11/2020",
                "ScanSpeed": 74,
                "TotalSales": 41,
                "AverageLinesPerSale": 6,
                "TotalLines": 246,
                "LineVoidRate": 0.0143,
                "ReceiptVoidRate": 0.0375,
                "LinesVoided": 4,
                "ReceiptsVoided": 2,
                "RefundValue": 15245,
                "AverageReceiptCost": 4251,
                "AverageLineCost": 218,
                "TotalSaleAmount": 174291
            },
            {
                "site": 2259,
                "team": "Green",
                "agent": 22590012,
                "date": "07/12/2020",
                "ScanSpeed": 18,
                "TotalSales": 165,
                "AverageLinesPerSale": 10,
                "TotalLines": 1650,
                "LineVoidRate": 0.0022,
                "ReceiptVoidRate": 0.0272,
                "LinesVoided": 4,
                "ReceiptsVoided": 5,
                "RefundValue": 165455,
                "AverageReceiptCost": 2835,
                "AverageLineCost": 364,
                "TotalSaleAmount": 467775
            },
            {
                "site": 2259,
                "team": "Green",
                "agent": 22590012,
                "date": "14/12/2020",
                "ScanSpeed": 90,
                "TotalSales": 68,
                "AverageLinesPerSale": 5,
                "TotalLines": 340,
                "LineVoidRate": 0.0074,
                "ReceiptVoidRate": 0.037,
                "LinesVoided": 3,
                "ReceiptsVoided": 3,
                "RefundValue": 13243,
                "AverageReceiptCost": 2886,
                "AverageLineCost": 98,
                "TotalSaleAmount": 196248
            },
            {
                "site": 2259,
                "team": "Green",
                "agent": 22590012,
                "date": "21/12/2020",
                "ScanSpeed": 81,
                "TotalSales": 135,
                "AverageLinesPerSale": 4,
                "TotalLines": 540,
                "LineVoidRate": 0.0138,
                "ReceiptVoidRate": 0.002,
                "LinesVoided": 8,
                "ReceiptsVoided": 1,
                "RefundValue": 6522,
                "AverageReceiptCost": 3444,
                "AverageLineCost": 90,
                "TotalSaleAmount": 464940
            },
            {
                "site": 2259,
                "team": "Green",
                "agent": 22590012,
                "date": "28/12/2020",
                "ScanSpeed": 58,
                "TotalSales": 133,
                "AverageLinesPerSale": 5,
                "TotalLines": 665,
                "LineVoidRate": 0.0622,
                "ReceiptVoidRate": 0.0274,
                "LinesVoided": 42,
                "ReceiptsVoided": 4,
                "RefundValue": 6865,
                "AverageReceiptCost": 1970,
                "AverageLineCost": 427,
                "TotalSaleAmount": 262010
            },
            {
                "site": 2259,
                "team": "Green",
                "agent": 22590013,
                "date": "26/10/2020",
                "ScanSpeed": 86,
                "TotalSales": 149,
                "AverageLinesPerSale": 6,
                "TotalLines": 894,
                "LineVoidRate": 0.0209,
                "ReceiptVoidRate": 0.0011,
                "LinesVoided": 19,
                "ReceiptsVoided": 1,
                "RefundValue": 23636,
                "AverageReceiptCost": 2196,
                "AverageLineCost": 494,
                "TotalSaleAmount": 327204
            },
            {
                "site": 2259,
                "team": "Green",
                "agent": 22590013,
                "date": "02/11/2020",
                "ScanSpeed": 19,
                "TotalSales": 52,
                "AverageLinesPerSale": 4,
                "TotalLines": 208,
                "LineVoidRate": 0.0281,
                "ReceiptVoidRate": 0.0151,
                "LinesVoided": 6,
                "ReceiptsVoided": 1,
                "RefundValue": 10925,
                "AverageReceiptCost": 3966,
                "AverageLineCost": 307,
                "TotalSaleAmount": 206232
            },
            {
                "site": 2259,
                "team": "Green",
                "agent": 22590013,
                "date": "09/11/2020",
                "ScanSpeed": 67,
                "TotalSales": 43,
                "AverageLinesPerSale": 8,
                "TotalLines": 344,
                "LineVoidRate": 0.0227,
                "ReceiptVoidRate": 0.0221,
                "LinesVoided": 8,
                "ReceiptsVoided": 1,
                "RefundValue": 8370,
                "AverageReceiptCost": 4469,
                "AverageLineCost": 190,
                "TotalSaleAmount": 192167
            },
            {
                "site": 2259,
                "team": "Green",
                "agent": 22590013,
                "date": "16/11/2020",
                "ScanSpeed": 38,
                "TotalSales": 37,
                "AverageLinesPerSale": 6,
                "TotalLines": 222,
                "LineVoidRate": 0.0518,
                "ReceiptVoidRate": 0.0268,
                "LinesVoided": 12,
                "ReceiptsVoided": 1,
                "RefundValue": 5830,
                "AverageReceiptCost": 308,
                "AverageLineCost": 302,
                "TotalSaleAmount": 11396
            },
            {
                "site": 2259,
                "team": "Green",
                "agent": 22590013,
                "date": "23/11/2020",
                "ScanSpeed": 46,
                "TotalSales": 95,
                "AverageLinesPerSale": 4,
                "TotalLines": 380,
                "LineVoidRate": 0.0351,
                "ReceiptVoidRate": 0.013,
                "LinesVoided": 14,
                "ReceiptsVoided": 2,
                "RefundValue": 9316,
                "AverageReceiptCost": 4245,
                "AverageLineCost": 327,
                "TotalSaleAmount": 403275
            },
            {
                "site": 2259,
                "team": "Green",
                "agent": 22590013,
                "date": "30/11/2020",
                "ScanSpeed": 36,
                "TotalSales": 94,
                "AverageLinesPerSale": 8,
                "TotalLines": 752,
                "LineVoidRate": 0.0249,
                "ReceiptVoidRate": 0.0383,
                "LinesVoided": 19,
                "ReceiptsVoided": 4,
                "RefundValue": 8032,
                "AverageReceiptCost": 3796,
                "AverageLineCost": 200,
                "TotalSaleAmount": 356824
            },
            {
                "site": 2259,
                "team": "Green",
                "agent": 22590013,
                "date": "07/12/2020",
                "ScanSpeed": 66,
                "TotalSales": 59,
                "AverageLinesPerSale": 3,
                "TotalLines": 177,
                "LineVoidRate": 0.0487,
                "ReceiptVoidRate": 0.0241,
                "LinesVoided": 9,
                "ReceiptsVoided": 2,
                "RefundValue": 3101,
                "AverageReceiptCost": 2360,
                "AverageLineCost": 151,
                "TotalSaleAmount": 139240
            },
            {
                "site": 2259,
                "team": "Green",
                "agent": 22590013,
                "date": "14/12/2020",
                "ScanSpeed": 36,
                "TotalSales": 48,
                "AverageLinesPerSale": 5,
                "TotalLines": 240,
                "LineVoidRate": 0.0319,
                "ReceiptVoidRate": 0.0218,
                "LinesVoided": 8,
                "ReceiptsVoided": 2,
                "RefundValue": 10345,
                "AverageReceiptCost": 939,
                "AverageLineCost": 330,
                "TotalSaleAmount": 45072
            },
            {
                "site": 2259,
                "team": "Green",
                "agent": 22590013,
                "date": "21/12/2020",
                "ScanSpeed": 49,
                "TotalSales": 48,
                "AverageLinesPerSale": 10,
                "TotalLines": 480,
                "LineVoidRate": 0.0582,
                "ReceiptVoidRate": 0.0268,
                "LinesVoided": 28,
                "ReceiptsVoided": 2,
                "RefundValue": 3076,
                "AverageReceiptCost": 2223,
                "AverageLineCost": 179,
                "TotalSaleAmount": 106704
            },
            {
                "site": 2259,
                "team": "Green",
                "agent": 22590013,
                "date": "28/12/2020",
                "ScanSpeed": 14,
                "TotalSales": 118,
                "AverageLinesPerSale": 8,
                "TotalLines": 944,
                "LineVoidRate": 0.0222,
                "ReceiptVoidRate": 0.0108,
                "LinesVoided": 21,
                "ReceiptsVoided": 2,
                "RefundValue": 17342,
                "AverageReceiptCost": 1272,
                "AverageLineCost": 385,
                "TotalSaleAmount": 150096
            },
            {
                "site": 2259,
                "team": "Green",
                "agent": 22590014,
                "date": "26/10/2020",
                "ScanSpeed": 88,
                "TotalSales": 95,
                "AverageLinesPerSale": 9,
                "TotalLines": 855,
                "LineVoidRate": 0.0174,
                "ReceiptVoidRate": 0.011,
                "LinesVoided": 15,
                "ReceiptsVoided": 2,
                "RefundValue": 20862,
                "AverageReceiptCost": 1137,
                "AverageLineCost": 363,
                "TotalSaleAmount": 108015
            },
            {
                "site": 2259,
                "team": "Green",
                "agent": 22590014,
                "date": "02/11/2020",
                "ScanSpeed": 88,
                "TotalSales": 38,
                "AverageLinesPerSale": 9,
                "TotalLines": 342,
                "LineVoidRate": 0.0166,
                "ReceiptVoidRate": 0.0173,
                "LinesVoided": 6,
                "ReceiptsVoided": 1,
                "RefundValue": 15663,
                "AverageReceiptCost": 3606,
                "AverageLineCost": 260,
                "TotalSaleAmount": 137028
            },
            {
                "site": 2259,
                "team": "Green",
                "agent": 22590014,
                "date": "09/11/2020",
                "ScanSpeed": 22,
                "TotalSales": 51,
                "AverageLinesPerSale": 11,
                "TotalLines": 561,
                "LineVoidRate": 0.0119,
                "ReceiptVoidRate": 0.0103,
                "LinesVoided": 7,
                "ReceiptsVoided": 1,
                "RefundValue": 38824,
                "AverageReceiptCost": 1827,
                "AverageLineCost": 462,
                "TotalSaleAmount": 93177
            },
            {
                "site": 2259,
                "team": "Green",
                "agent": 22590014,
                "date": "16/11/2020",
                "ScanSpeed": 34,
                "TotalSales": 61,
                "AverageLinesPerSale": 11,
                "TotalLines": 671,
                "LineVoidRate": 0.0325,
                "ReceiptVoidRate": 0.0166,
                "LinesVoided": 22,
                "ReceiptsVoided": 2,
                "RefundValue": 8554,
                "AverageReceiptCost": 2914,
                "AverageLineCost": 278,
                "TotalSaleAmount": 177754
            },
            {
                "site": 2259,
                "team": "Green",
                "agent": 22590014,
                "date": "23/11/2020",
                "ScanSpeed": 78,
                "TotalSales": 108,
                "AverageLinesPerSale": 10,
                "TotalLines": 1080,
                "LineVoidRate": 0.0011,
                "ReceiptVoidRate": 0.0145,
                "LinesVoided": 2,
                "ReceiptsVoided": 2,
                "RefundValue": 330000,
                "AverageReceiptCost": 2610,
                "AverageLineCost": 363,
                "TotalSaleAmount": 281880
            },
            {
                "site": 2259,
                "team": "Green",
                "agent": 22590014,
                "date": "30/11/2020",
                "ScanSpeed": 40,
                "TotalSales": 95,
                "AverageLinesPerSale": 5,
                "TotalLines": 475,
                "LineVoidRate": 0.0522,
                "ReceiptVoidRate": 0.0089,
                "LinesVoided": 25,
                "ReceiptsVoided": 1,
                "RefundValue": 1571,
                "AverageReceiptCost": 4225,
                "AverageLineCost": 82,
                "TotalSaleAmount": 401375
            },
            {
                "site": 2259,
                "team": "Green",
                "agent": 22590014,
                "date": "07/12/2020",
                "ScanSpeed": 44,
                "TotalSales": 109,
                "AverageLinesPerSale": 8,
                "TotalLines": 872,
                "LineVoidRate": 0.0258,
                "ReceiptVoidRate": 0.0137,
                "LinesVoided": 23,
                "ReceiptsVoided": 2,
                "RefundValue": 10116,
                "AverageReceiptCost": 1252,
                "AverageLineCost": 261,
                "TotalSaleAmount": 136468
            },
            {
                "site": 2259,
                "team": "Green",
                "agent": 22590014,
                "date": "14/12/2020",
                "ScanSpeed": 25,
                "TotalSales": 124,
                "AverageLinesPerSale": 7,
                "TotalLines": 868,
                "LineVoidRate": 0.0617,
                "ReceiptVoidRate": 0.0154,
                "LinesVoided": 54,
                "ReceiptsVoided": 2,
                "RefundValue": 4263,
                "AverageReceiptCost": 3598,
                "AverageLineCost": 263,
                "TotalSaleAmount": 446152
            },
            {
                "site": 2259,
                "team": "Green",
                "agent": 22590014,
                "date": "21/12/2020",
                "ScanSpeed": 89,
                "TotalSales": 42,
                "AverageLinesPerSale": 4,
                "TotalLines": 168,
                "LineVoidRate": 0.0201,
                "ReceiptVoidRate": 0.0077,
                "LinesVoided": 4,
                "ReceiptsVoided": 1,
                "RefundValue": 22338,
                "AverageReceiptCost": 618,
                "AverageLineCost": 449,
                "TotalSaleAmount": 25956
            },
            {
                "site": 2259,
                "team": "Green",
                "agent": 22590014,
                "date": "28/12/2020",
                "ScanSpeed": 45,
                "TotalSales": 40,
                "AverageLinesPerSale": 3,
                "TotalLines": 120,
                "LineVoidRate": 0.0555,
                "ReceiptVoidRate": 0.0032,
                "LinesVoided": 7,
                "ReceiptsVoided": 1,
                "RefundValue": 7027,
                "AverageReceiptCost": 3040,
                "AverageLineCost": 390,
                "TotalSaleAmount": 121600
            },
            {
                "site": 2259,
                "team": "Green",
                "agent": 22590015,
                "date": "26/10/2020",
                "ScanSpeed": 62,
                "TotalSales": 105,
                "AverageLinesPerSale": 4,
                "TotalLines": 420,
                "LineVoidRate": 0.0648,
                "ReceiptVoidRate": 0.0032,
                "LinesVoided": 28,
                "ReceiptsVoided": 1,
                "RefundValue": 4460,
                "AverageReceiptCost": 2131,
                "AverageLineCost": 289,
                "TotalSaleAmount": 223755
            },
            {
                "site": 2259,
                "team": "Green",
                "agent": 22590015,
                "date": "02/11/2020",
                "ScanSpeed": 74,
                "TotalSales": 102,
                "AverageLinesPerSale": 8,
                "TotalLines": 816,
                "LineVoidRate": 0.0354,
                "ReceiptVoidRate": 0.0165,
                "LinesVoided": 29,
                "ReceiptsVoided": 2,
                "RefundValue": 4859,
                "AverageReceiptCost": 2924,
                "AverageLineCost": 172,
                "TotalSaleAmount": 298248
            },
            {
                "site": 2259,
                "team": "Green",
                "agent": 22590015,
                "date": "09/11/2020",
                "ScanSpeed": 38,
                "TotalSales": 127,
                "AverageLinesPerSale": 11,
                "TotalLines": 1397,
                "LineVoidRate": 0.0258,
                "ReceiptVoidRate": 0.0011,
                "LinesVoided": 37,
                "ReceiptsVoided": 1,
                "RefundValue": 13256,
                "AverageReceiptCost": 2509,
                "AverageLineCost": 342,
                "TotalSaleAmount": 318643
            },
            {
                "site": 2259,
                "team": "Green",
                "agent": 22590015,
                "date": "16/11/2020",
                "ScanSpeed": 54,
                "TotalSales": 152,
                "AverageLinesPerSale": 11,
                "TotalLines": 1672,
                "LineVoidRate": 0.052,
                "ReceiptVoidRate": 0.0093,
                "LinesVoided": 87,
                "ReceiptsVoided": 2,
                "RefundValue": 5558,
                "AverageReceiptCost": 910,
                "AverageLineCost": 289,
                "TotalSaleAmount": 138320
            },
            {
                "site": 2259,
                "team": "Green",
                "agent": 22590015,
                "date": "23/11/2020",
                "ScanSpeed": 13,
                "TotalSales": 159,
                "AverageLinesPerSale": 5,
                "TotalLines": 795,
                "LineVoidRate": 0.041,
                "ReceiptVoidRate": 0.0065,
                "LinesVoided": 33,
                "ReceiptsVoided": 2,
                "RefundValue": 8732,
                "AverageReceiptCost": 4345,
                "AverageLineCost": 358,
                "TotalSaleAmount": 690855
            },
            {
                "site": 2259,
                "team": "Green",
                "agent": 22590015,
                "date": "30/11/2020",
                "ScanSpeed": 75,
                "TotalSales": 112,
                "AverageLinesPerSale": 5,
                "TotalLines": 560,
                "LineVoidRate": 0.0192,
                "ReceiptVoidRate": 0.0392,
                "LinesVoided": 11,
                "ReceiptsVoided": 5,
                "RefundValue": 7344,
                "AverageReceiptCost": 3811,
                "AverageLineCost": 141,
                "TotalSaleAmount": 426832
            },
            {
                "site": 2259,
                "team": "Green",
                "agent": 22590015,
                "date": "07/12/2020",
                "ScanSpeed": 41,
                "TotalSales": 147,
                "AverageLinesPerSale": 10,
                "TotalLines": 1470,
                "LineVoidRate": 0.0374,
                "ReceiptVoidRate": 0.0336,
                "LinesVoided": 55,
                "ReceiptsVoided": 5,
                "RefundValue": 12620,
                "AverageReceiptCost": 3413,
                "AverageLineCost": 472,
                "TotalSaleAmount": 501711
            },
            {
                "site": 2259,
                "team": "Green",
                "agent": 22590015,
                "date": "14/12/2020",
                "ScanSpeed": 40,
                "TotalSales": 117,
                "AverageLinesPerSale": 5,
                "TotalLines": 585,
                "LineVoidRate": 0.0393,
                "ReceiptVoidRate": 0.011,
                "LinesVoided": 23,
                "ReceiptsVoided": 2,
                "RefundValue": 2926,
                "AverageReceiptCost": 957,
                "AverageLineCost": 115,
                "TotalSaleAmount": 111969
            },
            {
                "site": 2259,
                "team": "Green",
                "agent": 22590015,
                "date": "21/12/2020",
                "ScanSpeed": 28,
                "TotalSales": 142,
                "AverageLinesPerSale": 5,
                "TotalLines": 710,
                "LineVoidRate": 0.0261,
                "ReceiptVoidRate": 0.0093,
                "LinesVoided": 19,
                "ReceiptsVoided": 2,
                "RefundValue": 13065,
                "AverageReceiptCost": 2586,
                "AverageLineCost": 341,
                "TotalSaleAmount": 367212
            },
            {
                "site": 2259,
                "team": "Green",
                "agent": 22590015,
                "date": "28/12/2020",
                "ScanSpeed": 35,
                "TotalSales": 69,
                "AverageLinesPerSale": 7,
                "TotalLines": 483,
                "LineVoidRate": 0.0624,
                "ReceiptVoidRate": 0.0015,
                "LinesVoided": 31,
                "ReceiptsVoided": 1,
                "RefundValue": 1170,
                "AverageReceiptCost": 3715,
                "AverageLineCost": 73,
                "TotalSaleAmount": 256335
            },
            {
                "site": 2259,
                "team": "Green",
                "agent": 22590016,
                "date": "26/10/2020",
                "ScanSpeed": 22,
                "TotalSales": 82,
                "AverageLinesPerSale": 5,
                "TotalLines": 410,
                "LineVoidRate": 0.0613,
                "ReceiptVoidRate": 0.0177,
                "LinesVoided": 26,
                "ReceiptsVoided": 2,
                "RefundValue": 3801,
                "AverageReceiptCost": 1372,
                "AverageLineCost": 233,
                "TotalSaleAmount": 112504
            },
            {
                "site": 2259,
                "team": "Green",
                "agent": 22590016,
                "date": "02/11/2020",
                "ScanSpeed": 64,
                "TotalSales": 84,
                "AverageLinesPerSale": 4,
                "TotalLines": 336,
                "LineVoidRate": 0.0183,
                "ReceiptVoidRate": 0.0186,
                "LinesVoided": 7,
                "ReceiptsVoided": 2,
                "RefundValue": 4590,
                "AverageReceiptCost": 984,
                "AverageLineCost": 84,
                "TotalSaleAmount": 82656
            },
            {
                "site": 2259,
                "team": "Green",
                "agent": 22590016,
                "date": "09/11/2020",
                "ScanSpeed": 72,
                "TotalSales": 27,
                "AverageLinesPerSale": 6,
                "TotalLines": 162,
                "LineVoidRate": 0.0114,
                "ReceiptVoidRate": 0.0214,
                "LinesVoided": 2,
                "ReceiptsVoided": 1,
                "RefundValue": 27368,
                "AverageReceiptCost": 2037,
                "AverageLineCost": 312,
                "TotalSaleAmount": 54999
            },
            {
                "site": 2259,
                "team": "Green",
                "agent": 22590016,
                "date": "16/11/2020",
                "ScanSpeed": 69,
                "TotalSales": 142,
                "AverageLinesPerSale": 10,
                "TotalLines": 1420,
                "LineVoidRate": 0.0344,
                "ReceiptVoidRate": 0.0195,
                "LinesVoided": 49,
                "ReceiptsVoided": 3,
                "RefundValue": 4157,
                "AverageReceiptCost": 2105,
                "AverageLineCost": 143,
                "TotalSaleAmount": 298910
            },
            {
                "site": 2259,
                "team": "Green",
                "agent": 22590016,
                "date": "23/11/2020",
                "ScanSpeed": 46,
                "TotalSales": 131,
                "AverageLinesPerSale": 6,
                "TotalLines": 786,
                "LineVoidRate": 0.0096,
                "ReceiptVoidRate": 0.0256,
                "LinesVoided": 8,
                "ReceiptsVoided": 4,
                "RefundValue": 19792,
                "AverageReceiptCost": 3084,
                "AverageLineCost": 190,
                "TotalSaleAmount": 404004
            },
            {
                "site": 2259,
                "team": "Green",
                "agent": 22590016,
                "date": "30/11/2020",
                "ScanSpeed": 34,
                "TotalSales": 66,
                "AverageLinesPerSale": 11,
                "TotalLines": 726,
                "LineVoidRate": 0.0193,
                "ReceiptVoidRate": 0.0285,
                "LinesVoided": 15,
                "ReceiptsVoided": 2,
                "RefundValue": 14611,
                "AverageReceiptCost": 3365,
                "AverageLineCost": 282,
                "TotalSaleAmount": 222090
            },
            {
                "site": 2259,
                "team": "Green",
                "agent": 22590016,
                "date": "07/12/2020",
                "ScanSpeed": 31,
                "TotalSales": 113,
                "AverageLinesPerSale": 5,
                "TotalLines": 565,
                "LineVoidRate": 0.0475,
                "ReceiptVoidRate": 0.0134,
                "LinesVoided": 27,
                "ReceiptsVoided": 2,
                "RefundValue": 6695,
                "AverageReceiptCost": 513,
                "AverageLineCost": 318,
                "TotalSaleAmount": 57969
            },
            {
                "site": 2259,
                "team": "Green",
                "agent": 22590016,
                "date": "14/12/2020",
                "ScanSpeed": 83,
                "TotalSales": 104,
                "AverageLinesPerSale": 10,
                "TotalLines": 1040,
                "LineVoidRate": 0.0278,
                "ReceiptVoidRate": 0.0112,
                "LinesVoided": 29,
                "ReceiptsVoided": 2,
                "RefundValue": 16295,
                "AverageReceiptCost": 2378,
                "AverageLineCost": 453,
                "TotalSaleAmount": 247312
            },
            {
                "site": 2259,
                "team": "Green",
                "agent": 22590016,
                "date": "21/12/2020",
                "ScanSpeed": 19,
                "TotalSales": 23,
                "AverageLinesPerSale": 4,
                "TotalLines": 92,
                "LineVoidRate": 0.0219,
                "ReceiptVoidRate": 0.0091,
                "LinesVoided": 3,
                "ReceiptsVoided": 1,
                "RefundValue": 2968,
                "AverageReceiptCost": 3033,
                "AverageLineCost": 65,
                "TotalSaleAmount": 69759
            },
            {
                "site": 2259,
                "team": "Green",
                "agent": 22590016,
                "date": "28/12/2020",
                "ScanSpeed": 76,
                "TotalSales": 142,
                "AverageLinesPerSale": 7,
                "TotalLines": 994,
                "LineVoidRate": 0.0356,
                "ReceiptVoidRate": 0.0037,
                "LinesVoided": 36,
                "ReceiptsVoided": 1,
                "RefundValue": 14017,
                "AverageReceiptCost": 1997,
                "AverageLineCost": 499,
                "TotalSaleAmount": 283574
            },
            {
                "site": 2259,
                "team": "Green",
                "agent": 22590017,
                "date": "26/10/2020",
                "ScanSpeed": 79,
                "TotalSales": 34,
                "AverageLinesPerSale": 8,
                "TotalLines": 272,
                "LineVoidRate": 0.0136,
                "ReceiptVoidRate": 0.0084,
                "LinesVoided": 4,
                "ReceiptsVoided": 1,
                "RefundValue": 11544,
                "AverageReceiptCost": 1476,
                "AverageLineCost": 157,
                "TotalSaleAmount": 50184
            },
            {
                "site": 2259,
                "team": "Green",
                "agent": 22590017,
                "date": "02/11/2020",
                "ScanSpeed": 42,
                "TotalSales": 82,
                "AverageLinesPerSale": 6,
                "TotalLines": 492,
                "LineVoidRate": 0.0425,
                "ReceiptVoidRate": 0.0227,
                "LinesVoided": 21,
                "ReceiptsVoided": 2,
                "RefundValue": 3741,
                "AverageReceiptCost": 578,
                "AverageLineCost": 159,
                "TotalSaleAmount": 47396
            },
            {
                "site": 2259,
                "team": "Green",
                "agent": 22590017,
                "date": "09/11/2020",
                "ScanSpeed": 53,
                "TotalSales": 152,
                "AverageLinesPerSale": 9,
                "TotalLines": 1368,
                "LineVoidRate": 0.0135,
                "ReceiptVoidRate": 0.0281,
                "LinesVoided": 19,
                "ReceiptsVoided": 5,
                "RefundValue": 6741,
                "AverageReceiptCost": 3239,
                "AverageLineCost": 91,
                "TotalSaleAmount": 492328
            },
            {
                "site": 2259,
                "team": "Green",
                "agent": 22590017,
                "date": "16/11/2020",
                "ScanSpeed": 78,
                "TotalSales": 56,
                "AverageLinesPerSale": 8,
                "TotalLines": 448,
                "LineVoidRate": 0.0349,
                "ReceiptVoidRate": 0.0037,
                "LinesVoided": 16,
                "ReceiptsVoided": 1,
                "RefundValue": 12178,
                "AverageReceiptCost": 3945,
                "AverageLineCost": 425,
                "TotalSaleAmount": 220920
            },
            {
                "site": 2259,
                "team": "Green",
                "agent": 22590017,
                "date": "23/11/2020",
                "ScanSpeed": 31,
                "TotalSales": 81,
                "AverageLinesPerSale": 9,
                "TotalLines": 729,
                "LineVoidRate": 0.0209,
                "ReceiptVoidRate": 0.0349,
                "LinesVoided": 16,
                "ReceiptsVoided": 3,
                "RefundValue": 6459,
                "AverageReceiptCost": 2388,
                "AverageLineCost": 135,
                "TotalSaleAmount": 193428
            },
            {
                "site": 2259,
                "team": "Green",
                "agent": 22590017,
                "date": "30/11/2020",
                "ScanSpeed": 29,
                "TotalSales": 24,
                "AverageLinesPerSale": 11,
                "TotalLines": 264,
                "LineVoidRate": 0.0623,
                "ReceiptVoidRate": 0.0343,
                "LinesVoided": 17,
                "ReceiptsVoided": 1,
                "RefundValue": 1846,
                "AverageReceiptCost": 4398,
                "AverageLineCost": 115,
                "TotalSaleAmount": 105552
            },
            {
                "site": 2259,
                "team": "Green",
                "agent": 22590017,
                "date": "07/12/2020",
                "ScanSpeed": 47,
                "TotalSales": 69,
                "AverageLinesPerSale": 5,
                "TotalLines": 345,
                "LineVoidRate": 0.0264,
                "ReceiptVoidRate": 0.0227,
                "LinesVoided": 10,
                "ReceiptsVoided": 2,
                "RefundValue": 5492,
                "AverageReceiptCost": 2179,
                "AverageLineCost": 145,
                "TotalSaleAmount": 150351
            },
            {
                "site": 2259,
                "team": "Green",
                "agent": 22590017,
                "date": "14/12/2020",
                "ScanSpeed": 81,
                "TotalSales": 117,
                "AverageLinesPerSale": 4,
                "TotalLines": 468,
                "LineVoidRate": 0.0439,
                "ReceiptVoidRate": 0.0081,
                "LinesVoided": 21,
                "ReceiptsVoided": 1,
                "RefundValue": 2210,
                "AverageReceiptCost": 768,
                "AverageLineCost": 97,
                "TotalSaleAmount": 89856
            },
            {
                "site": 2259,
                "team": "Green",
                "agent": 22590017,
                "date": "21/12/2020",
                "ScanSpeed": 78,
                "TotalSales": 93,
                "AverageLinesPerSale": 6,
                "TotalLines": 558,
                "LineVoidRate": 0.0386,
                "ReceiptVoidRate": 0.0068,
                "LinesVoided": 22,
                "ReceiptsVoided": 1,
                "RefundValue": 1451,
                "AverageReceiptCost": 1750,
                "AverageLineCost": 56,
                "TotalSaleAmount": 162750
            },
            {
                "site": 2259,
                "team": "Green",
                "agent": 22590017,
                "date": "28/12/2020",
                "ScanSpeed": 87,
                "TotalSales": 150,
                "AverageLinesPerSale": 4,
                "TotalLines": 600,
                "LineVoidRate": 0.0022,
                "ReceiptVoidRate": 0.0079,
                "LinesVoided": 2,
                "ReceiptsVoided": 2,
                "RefundValue": 53636,
                "AverageReceiptCost": 2489,
                "AverageLineCost": 118,
                "TotalSaleAmount": 373350
            },
            {
                "site": 2259,
                "team": "Red",
                "agent": 22590018,
                "date": "26/10/2020",
                "ScanSpeed": 11,
                "TotalSales": 71,
                "AverageLinesPerSale": 3,
                "TotalLines": 213,
                "LineVoidRate": 0.0175,
                "ReceiptVoidRate": 0.0118,
                "LinesVoided": 4,
                "ReceiptsVoided": 1,
                "RefundValue": 21714,
                "AverageReceiptCost": 4441,
                "AverageLineCost": 380,
                "TotalSaleAmount": 315311
            },
            {
                "site": 2259,
                "team": "Red",
                "agent": 22590018,
                "date": "02/11/2020",
                "ScanSpeed": 23,
                "TotalSales": 24,
                "AverageLinesPerSale": 5,
                "TotalLines": 120,
                "LineVoidRate": 0.0208,
                "ReceiptVoidRate": 0.0086,
                "LinesVoided": 3,
                "ReceiptsVoided": 1,
                "RefundValue": 10481,
                "AverageReceiptCost": 2233,
                "AverageLineCost": 218,
                "TotalSaleAmount": 53592
            },
            {
                "site": 2259,
                "team": "Red",
                "agent": 22590018,
                "date": "09/11/2020",
                "ScanSpeed": 40,
                "TotalSales": 104,
                "AverageLinesPerSale": 4,
                "TotalLines": 416,
                "LineVoidRate": 0.0353,
                "ReceiptVoidRate": 0.0031,
                "LinesVoided": 15,
                "ReceiptsVoided": 1,
                "RefundValue": 5326,
                "AverageReceiptCost": 4135,
                "AverageLineCost": 188,
                "TotalSaleAmount": 430040
            },
            {
                "site": 2259,
                "team": "Red",
                "agent": 22590018,
                "date": "16/11/2020",
                "ScanSpeed": 25,
                "TotalSales": 125,
                "AverageLinesPerSale": 4,
                "TotalLines": 500,
                "LineVoidRate": 0.0458,
                "ReceiptVoidRate": 0.0143,
                "LinesVoided": 23,
                "ReceiptsVoided": 2,
                "RefundValue": 5590,
                "AverageReceiptCost": 2378,
                "AverageLineCost": 256,
                "TotalSaleAmount": 297250
            },
            {
                "site": 2259,
                "team": "Red",
                "agent": 22590018,
                "date": "23/11/2020",
                "ScanSpeed": 78,
                "TotalSales": 107,
                "AverageLinesPerSale": 4,
                "TotalLines": 428,
                "LineVoidRate": 0.0432,
                "ReceiptVoidRate": 0.0014,
                "LinesVoided": 19,
                "ReceiptsVoided": 1,
                "RefundValue": 4676,
                "AverageReceiptCost": 2053,
                "AverageLineCost": 202,
                "TotalSaleAmount": 219671
            },
            {
                "site": 2259,
                "team": "Red",
                "agent": 22590018,
                "date": "30/11/2020",
                "ScanSpeed": 56,
                "TotalSales": 25,
                "AverageLinesPerSale": 8,
                "TotalLines": 200,
                "LineVoidRate": 0.0135,
                "ReceiptVoidRate": 0.0323,
                "LinesVoided": 3,
                "ReceiptsVoided": 1,
                "RefundValue": 34593,
                "AverageReceiptCost": 1527,
                "AverageLineCost": 467,
                "TotalSaleAmount": 38175
            },
            {
                "site": 2259,
                "team": "Red",
                "agent": 22590018,
                "date": "07/12/2020",
                "ScanSpeed": 89,
                "TotalSales": 108,
                "AverageLinesPerSale": 9,
                "TotalLines": 972,
                "LineVoidRate": 0.0514,
                "ReceiptVoidRate": 0.0371,
                "LinesVoided": 50,
                "ReceiptsVoided": 5,
                "RefundValue": 8988,
                "AverageReceiptCost": 1440,
                "AverageLineCost": 462,
                "TotalSaleAmount": 155520
            },
            {
                "site": 2259,
                "team": "Red",
                "agent": 22590018,
                "date": "14/12/2020",
                "ScanSpeed": 72,
                "TotalSales": 20,
                "AverageLinesPerSale": 11,
                "TotalLines": 220,
                "LineVoidRate": 0.0521,
                "ReceiptVoidRate": 0.0274,
                "LinesVoided": 12,
                "ReceiptsVoided": 1,
                "RefundValue": 9002,
                "AverageReceiptCost": 3792,
                "AverageLineCost": 469,
                "TotalSaleAmount": 75840
            },
            {
                "site": 2259,
                "team": "Red",
                "agent": 22590018,
                "date": "21/12/2020",
                "ScanSpeed": 76,
                "TotalSales": 141,
                "AverageLinesPerSale": 7,
                "TotalLines": 987,
                "LineVoidRate": 0.0645,
                "ReceiptVoidRate": 0.0214,
                "LinesVoided": 64,
                "ReceiptsVoided": 4,
                "RefundValue": 806,
                "AverageReceiptCost": 1752,
                "AverageLineCost": 52,
                "TotalSaleAmount": 247032
            },
            {
                "site": 2259,
                "team": "Red",
                "agent": 22590018,
                "date": "28/12/2020",
                "ScanSpeed": 62,
                "TotalSales": 104,
                "AverageLinesPerSale": 7,
                "TotalLines": 728,
                "LineVoidRate": 0.0367,
                "ReceiptVoidRate": 0.0297,
                "LinesVoided": 27,
                "ReceiptsVoided": 4,
                "RefundValue": 5068,
                "AverageReceiptCost": 1377,
                "AverageLineCost": 186,
                "TotalSaleAmount": 143208
            },
            {
                "site": 2259,
                "team": "Red",
                "agent": 22590019,
                "date": "26/10/2020",
                "ScanSpeed": 69,
                "TotalSales": 150,
                "AverageLinesPerSale": 4,
                "TotalLines": 600,
                "LineVoidRate": 0.0589,
                "ReceiptVoidRate": 0.0131,
                "LinesVoided": 36,
                "ReceiptsVoided": 2,
                "RefundValue": 7759,
                "AverageReceiptCost": 1911,
                "AverageLineCost": 457,
                "TotalSaleAmount": 286650
            },
            {
                "site": 2259,
                "team": "Red",
                "agent": 22590019,
                "date": "02/11/2020",
                "ScanSpeed": 80,
                "TotalSales": 19,
                "AverageLinesPerSale": 11,
                "TotalLines": 209,
                "LineVoidRate": 0.0548,
                "ReceiptVoidRate": 0.0075,
                "LinesVoided": 12,
                "ReceiptsVoided": 1,
                "RefundValue": 7172,
                "AverageReceiptCost": 1842,
                "AverageLineCost": 393,
                "TotalSaleAmount": 34998
            },
            {
                "site": 2259,
                "team": "Red",
                "agent": 22590019,
                "date": "09/11/2020",
                "ScanSpeed": 13,
                "TotalSales": 169,
                "AverageLinesPerSale": 5,
                "TotalLines": 845,
                "LineVoidRate": 0.0667,
                "ReceiptVoidRate": 0.0193,
                "LinesVoided": 57,
                "ReceiptsVoided": 4,
                "RefundValue": 4393,
                "AverageReceiptCost": 3196,
                "AverageLineCost": 293,
                "TotalSaleAmount": 540124
            },
            {
                "site": 2259,
                "team": "Red",
                "agent": 22590019,
                "date": "16/11/2020",
                "ScanSpeed": 81,
                "TotalSales": 174,
                "AverageLinesPerSale": 10,
                "TotalLines": 1740,
                "LineVoidRate": 0.0544,
                "ReceiptVoidRate": 0.0167,
                "LinesVoided": 95,
                "ReceiptsVoided": 3,
                "RefundValue": 1691,
                "AverageReceiptCost": 1017,
                "AverageLineCost": 92,
                "TotalSaleAmount": 176958
            },
            {
                "site": 2259,
                "team": "Red",
                "agent": 22590019,
                "date": "23/11/2020",
                "ScanSpeed": 87,
                "TotalSales": 126,
                "AverageLinesPerSale": 11,
                "TotalLines": 1386,
                "LineVoidRate": 0.0521,
                "ReceiptVoidRate": 0.0011,
                "LinesVoided": 73,
                "ReceiptsVoided": 1,
                "RefundValue": 3512,
                "AverageReceiptCost": 3496,
                "AverageLineCost": 183,
                "TotalSaleAmount": 440496
            },
            {
                "site": 2259,
                "team": "Red",
                "agent": 22590019,
                "date": "30/11/2020",
                "ScanSpeed": 31,
                "TotalSales": 88,
                "AverageLinesPerSale": 6,
                "TotalLines": 528,
                "LineVoidRate": 0.0126,
                "ReceiptVoidRate": 0.0019,
                "LinesVoided": 7,
                "ReceiptsVoided": 1,
                "RefundValue": 3968,
                "AverageReceiptCost": 361,
                "AverageLineCost": 50,
                "TotalSaleAmount": 31768
            },
            {
                "site": 2259,
                "team": "Red",
                "agent": 22590019,
                "date": "07/12/2020",
                "ScanSpeed": 85,
                "TotalSales": 71,
                "AverageLinesPerSale": 9,
                "TotalLines": 639,
                "LineVoidRate": 0.0669,
                "ReceiptVoidRate": 0.0123,
                "LinesVoided": 43,
                "ReceiptsVoided": 1,
                "RefundValue": 5217,
                "AverageReceiptCost": 2021,
                "AverageLineCost": 349,
                "TotalSaleAmount": 143491
            },
            {
                "site": 2259,
                "team": "Red",
                "agent": 22590019,
                "date": "14/12/2020",
                "ScanSpeed": 72,
                "TotalSales": 172,
                "AverageLinesPerSale": 5,
                "TotalLines": 860,
                "LineVoidRate": 0.0182,
                "ReceiptVoidRate": 0.0351,
                "LinesVoided": 16,
                "ReceiptsVoided": 7,
                "RefundValue": 11484,
                "AverageReceiptCost": 723,
                "AverageLineCost": 209,
                "TotalSaleAmount": 124356
            },
            {
                "site": 2259,
                "team": "Red",
                "agent": 22590019,
                "date": "21/12/2020",
                "ScanSpeed": 12,
                "TotalSales": 148,
                "AverageLinesPerSale": 9,
                "TotalLines": 1332,
                "LineVoidRate": 0.0012,
                "ReceiptVoidRate": 0.0389,
                "LinesVoided": 2,
                "ReceiptsVoided": 6,
                "RefundValue": 340833,
                "AverageReceiptCost": 4116,
                "AverageLineCost": 409,
                "TotalSaleAmount": 609168
            },
            {
                "site": 2259,
                "team": "Red",
                "agent": 22590019,
                "date": "28/12/2020",
                "ScanSpeed": 14,
                "TotalSales": 42,
                "AverageLinesPerSale": 10,
                "TotalLines": 420,
                "LineVoidRate": 0.0363,
                "ReceiptVoidRate": 0.0122,
                "LinesVoided": 16,
                "ReceiptsVoided": 1,
                "RefundValue": 9339,
                "AverageReceiptCost": 3920,
                "AverageLineCost": 339,
                "TotalSaleAmount": 164640
            },
            {
                "site": 2259,
                "team": "Red",
                "agent": 22590020,
                "date": "26/10/2020",
                "ScanSpeed": 71,
                "TotalSales": 79,
                "AverageLinesPerSale": 8,
                "TotalLines": 632,
                "LineVoidRate": 0.033,
                "ReceiptVoidRate": 0.0199,
                "LinesVoided": 21,
                "ReceiptsVoided": 2,
                "RefundValue": 12424,
                "AverageReceiptCost": 3386,
                "AverageLineCost": 410,
                "TotalSaleAmount": 267494
            },
            {
                "site": 2259,
                "team": "Red",
                "agent": 22590020,
                "date": "02/11/2020",
                "ScanSpeed": 30,
                "TotalSales": 36,
                "AverageLinesPerSale": 8,
                "TotalLines": 288,
                "LineVoidRate": 0.0027,
                "ReceiptVoidRate": 0.029,
                "LinesVoided": 1,
                "ReceiptsVoided": 2,
                "RefundValue": 40000,
                "AverageReceiptCost": 991,
                "AverageLineCost": 108,
                "TotalSaleAmount": 35676
            },
            {
                "site": 2259,
                "team": "Red",
                "agent": 22590020,
                "date": "09/11/2020",
                "ScanSpeed": 21,
                "TotalSales": 49,
                "AverageLinesPerSale": 11,
                "TotalLines": 539,
                "LineVoidRate": 0.0384,
                "ReceiptVoidRate": 0.0378,
                "LinesVoided": 21,
                "ReceiptsVoided": 2,
                "RefundValue": 5521,
                "AverageReceiptCost": 2365,
                "AverageLineCost": 212,
                "TotalSaleAmount": 115885
            },
            {
                "site": 2259,
                "team": "Red",
                "agent": 22590020,
                "date": "16/11/2020",
                "ScanSpeed": 86,
                "TotalSales": 143,
                "AverageLinesPerSale": 10,
                "TotalLines": 1430,
                "LineVoidRate": 0.0626,
                "ReceiptVoidRate": 0.0356,
                "LinesVoided": 90,
                "ReceiptsVoided": 6,
                "RefundValue": 3419,
                "AverageReceiptCost": 3865,
                "AverageLineCost": 214,
                "TotalSaleAmount": 552695
            },
            {
                "site": 2259,
                "team": "Red",
                "agent": 22590020,
                "date": "23/11/2020",
                "ScanSpeed": 44,
                "TotalSales": 25,
                "AverageLinesPerSale": 10,
                "TotalLines": 250,
                "LineVoidRate": 0.0573,
                "ReceiptVoidRate": 0.0234,
                "LinesVoided": 15,
                "ReceiptsVoided": 1,
                "RefundValue": 2129,
                "AverageReceiptCost": 3721,
                "AverageLineCost": 122,
                "TotalSaleAmount": 93025
            },
            {
                "site": 2259,
                "team": "Red",
                "agent": 22590020,
                "date": "30/11/2020",
                "ScanSpeed": 59,
                "TotalSales": 154,
                "AverageLinesPerSale": 5,
                "TotalLines": 770,
                "LineVoidRate": 0.0153,
                "ReceiptVoidRate": 0.0209,
                "LinesVoided": 12,
                "ReceiptsVoided": 4,
                "RefundValue": 5686,
                "AverageReceiptCost": 3389,
                "AverageLineCost": 87,
                "TotalSaleAmount": 521906
            },
            {
                "site": 2259,
                "team": "Red",
                "agent": 22590020,
                "date": "07/12/2020",
                "ScanSpeed": 15,
                "TotalSales": 34,
                "AverageLinesPerSale": 9,
                "TotalLines": 306,
                "LineVoidRate": 0.0068,
                "ReceiptVoidRate": 0.0358,
                "LinesVoided": 3,
                "ReceiptsVoided": 2,
                "RefundValue": 67647,
                "AverageReceiptCost": 2842,
                "AverageLineCost": 460,
                "TotalSaleAmount": 96628
            },
            {
                "site": 2259,
                "team": "Red",
                "agent": 22590020,
                "date": "14/12/2020",
                "ScanSpeed": 27,
                "TotalSales": 18,
                "AverageLinesPerSale": 4,
                "TotalLines": 72,
                "LineVoidRate": 0.047,
                "ReceiptVoidRate": 0.0302,
                "LinesVoided": 4,
                "ReceiptsVoided": 1,
                "RefundValue": 8532,
                "AverageReceiptCost": 4498,
                "AverageLineCost": 401,
                "TotalSaleAmount": 80964
            },
            {
                "site": 2259,
                "team": "Red",
                "agent": 22590020,
                "date": "21/12/2020",
                "ScanSpeed": 33,
                "TotalSales": 117,
                "AverageLinesPerSale": 7,
                "TotalLines": 819,
                "LineVoidRate": 0.04,
                "ReceiptVoidRate": 0.0144,
                "LinesVoided": 33,
                "ReceiptsVoided": 2,
                "RefundValue": 5075,
                "AverageReceiptCost": 2761,
                "AverageLineCost": 203,
                "TotalSaleAmount": 323037
            },
            {
                "site": 2259,
                "team": "Red",
                "agent": 22590020,
                "date": "28/12/2020",
                "ScanSpeed": 57,
                "TotalSales": 59,
                "AverageLinesPerSale": 9,
                "TotalLines": 531,
                "LineVoidRate": 0.0198,
                "ReceiptVoidRate": 0.0181,
                "LinesVoided": 11,
                "ReceiptsVoided": 2,
                "RefundValue": 24798,
                "AverageReceiptCost": 783,
                "AverageLineCost": 491,
                "TotalSaleAmount": 46197
            }
        ]
        ,
        investigation: null,
        investigations: [],
        notes: {}
    },
    note: {
        author: null,
        note: null,
        dateTime: null,
    },
    mutations: {
        SET_SHOW_TRANSACTION_MODAL(state, value) {
            state.showTransactionModal = value;
        },
        SAVE_INVESTIGATION(state, investigation) {
            state.cases.push(investigation)
        },
        CREATE_CASE_FOR_REVIEW(state, incident) {
            state.caseForReview.incident = incident
        },
        RESET_CASE_FOR_REVIEW(state) {
            state.caseForReview = {
                incident: {},
                caseOwner: null,
                caseInvestigatorLevel: null,
                dateTime: null,
                investigationType: null,
                notes: [{
                    author: null,
                    dateTime: null,
                    note: null
                }],
                responseDueDate: null,
                followUpDate: null,
                status: null,
                processManagement: [
                    {
                        title: 'Review Footage',
                        checked: false,
                        note: ''
                    }, {
                        title: 'Review Transactions',
                        checked: false,
                        note: ''
                    }, {
                        title: 'Fill out Investigation Pack',
                        checked: false,
                        note: ''
                    }, {
                        title: 'Collect Statements (Witnesses)',
                        checked: false,
                        note: ''
                    }, {
                        title: 'Collect Statement (Accused)',
                        checked: false,
                        note: ''
                    }, {
                        title: 'Obtain Other Evidence',
                        checked: false,
                        note: ''
                    }, {
                        title: 'Obtain CCTV',
                        checked: false,
                        note: ''
                    }, {
                        title: 'Formal Investigation',
                        checked: false,
                        note: ''
                    }, {
                        title: 'Contact Police',
                        checked: false,
                        note: ''
                    }, {
                        title: 'Security Policies',
                        checked: false,
                        note: ''
                    },
                ],
            }
        },
        SET_CASE_FOR_REVIEW_PREREQUISITES(state) {
            state.caseForReview.caseOwner = "Paul Banks"
            state.caseForReview.dateTime = new Date().toISOString().slice(0, -8)
            state.caseForReview.responseDueDate = new Date(Date.now() + 7 * 24 * 60 * 60 * 1000).toLocaleDateString('en-CA');
            state.caseForReview.followUpDate = new Date(Date.now() + 7 * 24 * 60 * 60 * 1000).toLocaleDateString('en-CA');
            state.caseForReview.status = "Open";
        },
        CLEAR_INVESTIGATION(state) {
            state.investigation = null
        },
        SET_SITE(state, site) {
            state.dashFilter.site = site
        },
        SET_TEAM(state, team) {
            state.dashFilter.team = team
        },
        SET_AGENT(state, agent) {
            state.dashFilter.agent = agent
        },
        SET_CASE_FOR_REVIEW_INVESTIGATION_TYPE(state, type) {
            state.caseForReview.investigationType = type
        },
        SET_CASE_FOR_REVIEW_DATE_TIME(state, dateTime) {
            state.caseForReview.dateTime = dateTime
        },
        SET_CASE_FOR_REVIEW_INVESTIGATOR_LEVEL(state, level) {
            state.caseForReview.caseInvestigatorLevel = level
        },
        SET_CASE_FOR_REVIEW_STATUS(state, status) {
            state.caseForReview.status = status
        },
        SET_CASE_FOR_REVIEW_REVIEW_DATE(state, datetime) {
            state.caseForReview.responseDueDate = datetime
        },
        SET_CASE_FOR_REVIEW_FOLLOW_UP_DATE(state, datetime) {
            state.caseForReview.followUpDate = datetime
        },
        SET_CASE_FOR_REVIEW_NOTE(state, note) {
            state.caseForReview.notes[0] = note
        },
        SET_CASE_FOR_REVIEW_PROCESS_MANAGEMENT(state, processManagement) {
            state.caseForReview.processManagement = processManagement
        },
        SET_CASE_FILE(state, caseFileIndex) {
            state.caseFile = state.caseFiles[caseFileIndex]
        },
        UPDATE_CASE_FILE_OWNER(state, val) {
            state.caseFile.caseOwner = val
        },
        UPDATE_CASE_FILE_STATUS(state, val) {
            state.caseFile.status = val
        },
        UPDATE_CASE_FILE_FOLLOW_UP_DATE(state, val) {
            state.caseFile.followUpDate = val
        },
        UPDATE_CASE_FILE_RESPONSE_DUE_DATE(state, val) {
            state.caseFile.responseDueDate = val
        },
        ADD_CASE_FILE_NOTE(state, {index, note}) {
            state.caseFiles[index].notes.push(
                {
                    author: "Paul Banks",
                    dateTime: new Date().toISOString().slice(0, -8),
                    note: note
                }
            )
        },
        PUSH_CASE_FILE(state, caseFile) {
            state.caseFiles.push(caseFile)
        },
        UPDATE_PROCESS_MANAGEMENT_NOTE(state, {processIndex, value}) {
            state.caseFile.processManagement[processIndex].note = value
        },
        TOGGLE_PROCESS_MANAGEMENT_CHECK(state, processIndex) {
            state.caseFile.processManagement[processIndex].checked = !state.caseFile.processManagement[processIndex].checked
        }
    },
    actions: {
        // Dash Actions

        updateTeam({commit}, team) {
            commit('SET_TEAM', team)
            commit('SET_AGENT', null)
        },
        updateAgent({commit}, agent) {
            commit('SET_AGENT', agent)
        },
        // incident Actions
        createCaseForReview({commit}, incident) {
            commit('RESET_CASE_FOR_REVIEW')
            commit('SET_CASE_FOR_REVIEW_PREREQUISITES')
            commit('CREATE_CASE_FOR_REVIEW', incident)
        },
        saveInvestigation({commit}, caseForReview) {
            commit('PUSH_CASE_FILE', caseForReview)
            commit('RESET_CASE_FOR_REVIEW')
        },
        updateCaseForReviewDateTime({commit}, dateTime) {
            commit('UPDATE_CASE_FOR_REVIEW_DATE_TIME', dateTime)
        }
    },
    modules: {},
    getters: {
        sites(state) {
            return _.uniq(_.map(state.dash, state => state.site)).sort();
        },
        teams(state) {
            let rows = _.pickBy(state.dash, dash => dash.site === state.dashFilter.site)
            return _.uniq(_.map(rows, state => state.team)).sort()
        },
        agents(state) {
            let rows = _.pickBy(state.dash, dash => dash.team === state.dashFilter.team && dash.site === state.dashFilter.site)
            return _.uniq(_.map(rows, state => state.agent)).sort()
        },
        filteredDash(state) {
            if (state.dashFilter.agent != null) {
                return _.pickBy(state.dash, dash => dash.agent === state.dashFilter.agent)
            } else if (state.dashFilter.team != null) {
                return _.pickBy(state.dash, dash => dash.team === state.dashFilter.team && dash.site === state.dashFilter.site)
            } else if (state.dashFilter.site != null) {
                return _.pickBy(state.dash, dash => dash.site === state.dashFilter.site)
            } else {
                return state.dash
            }
        },
        filteredCount(state, getters) {
            return Object.keys(getters.filteredDash).length;
        },
        dashDates(state) {
            return _.uniq(_.map(state.dash, state => state.date)).sort()
        },
        scanSpeed(state, getters) {
            return _(getters.filteredDash).groupBy("date").map((objs, key) => ([key, _.sumBy(objs, 'ScanSpeed') / Object.keys(objs).length])).unshift(["Dates", "Values"]).value()
        },
        lineVoids(state, getters) {
            return _(getters.filteredDash).groupBy("date").map((objs, key) => ([key, (_.sumBy(objs, 'LineVoidRate') * 100) / Object.keys(objs).length])).unshift(["Dates", "Values"]).value()
        },
        receiptVoids(state, getters) {
            return _(getters.filteredDash).groupBy("date").map((objs, key) => ([key, (_.sumBy(objs, 'ReceiptVoidRate') * 100) / Object.keys(objs).length])).unshift(["Dates", "Values"]).value()
        },
        refundValue(state, getters) {
            return _(getters.filteredDash).groupBy("date").map((objs, key) => ([key, (_.sumBy(objs, 'RefundValue') / 100)])).unshift(["Dates", "Values"]).value()
        },
        transactionCount(state, getters) {
            return _(getters.filteredDash).groupBy("date").map((objs, key) => ([key, _.sumBy(objs, 'TotalSales')])).unshift(["Dates", "Values"]).value()
        },
        refundValueVsTotalValue(state) {
            return _(state.dash).map(obj => [obj.TotalSaleAmount / 100, obj.RefundValue / 100, obj.agent.toString()]).unshift(["Refund Value", "Sale Value", {role: 'tooltip'}]).value();
        }

    }
});
