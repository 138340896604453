<template>
  <div id="incidents">
    <h1 class="page-header">Incident Overview</h1>
    <div class="m-6 grid grid-cols-12 gap-6">
      <div class="lp-card col-span-12">
        <breadcrumbs />
      </div>
      <div class="lp-card col-span-12">
        <Filters></Filters>
      </div>
      <div class="grid col-span-12 grid-cols-12 gap-6 mb-6">

        <button v-for="(filterOption, index) in filterOptions" :key="index"
                @click="TableFilter(index)"
                :class="TableFilterButtonClass(index)"
                class="relative col-span-4 text-left bg-white rounded-lg shadow-md py-6 px-6 border focus:outline-none"
        >
          <span class="absolute top-0 right-0 m-3" v-if="filterValue === index">
            <svg xmlns="http://www.w3.org/2000/svg" class="h-6" fill="none" viewBox="0 0 24 24" stroke="currentColor">
              <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M6 18L18 6M6 6l12 12"/>
            </svg>
          </span>
          <span class="font-semibold text-blueGray-500 text-base">{{ filterOption.option }}</span> <br/>
          <span class="text-4xl font-bold">{{ filterOption.values.length }}</span>
        </button>
      </div>

      <div class="shadow border-b border-gray-200 sm:rounded-lg col-span-12">
        <table class="lp-table">
          <thead class="">
          <tr>
            <th scope="col">Date</th>
            <th scope="col">Region</th>
            <th scope="col">Area</th>
            <th scope="col">Store</th>
            <th scope="col">Employee</th>
            <th scope="col">TX Type</th>
            <th scope="col">Receipt Number</th>
            <th scope="col">Value</th>
            <th scope="col">Probability</th>
            <th scope="col">Proposed Action</th>
            <th scope="col">Probability of Action</th>
            <th scope="col"></th>
          </tr>
          </thead>
          <tbody>
          <tr v-for="(incident, index) in incidents" v-show="showRow(index)" :key="index" :incident="incident">
            <td>{{ incident.date }}</td>
            <td>{{ incident.region }}</td>
            <td>{{ incident.area }}</td>
            <td>{{ incident.store }}</td>
            <td>{{ incident.employee }}</td>
            <td>{{ incident.txType }}</td>
            <td>{{ incident.receiptNumber }}</td>
            <td>£{{ incident.value / 100 }}</td>
            <td class="text-center">
              <span :class="ProbabilityLevel(incident.probability)"
                    class="px-3 py-1 inline-flex text-xs leading-5 font-semibold rounded-full">
                  {{ incident.probability * 100 }}%
                </span>
            </td>
            <td>{{ incident.proposedAction }}</td>
            <td class="text-center">
              <span :class="ProbabilityLevel(incident.actionProbability)"
                    class="px-3 py-1 inline-flex text-xs leading-5 font-semibold rounded-full">
                  {{ incident.actionProbability * 100 }}%
                </span>
            </td>
            <td class="px-4 py-2 text-center">
              <button @click="createInvestigation(incident)"
                      class="inline-flex font-semibold justify-center w-full rounded-md border border-transparent px-4 py-2 bg-blue-600 text-base leading-6 font-medium text-white shadow-sm hover:bg-blue-500 transition ease-in-out duration-150 sm:text-sm sm:leading-5">
                Create
              </button>
            </td>
          </tr>
          </tbody>
        </table>
      </div>
      <div class="text-right" v-show="false">
        <ul class="flex inline pl-0 list-none rounded my-2">
          <li class="relative block py-2 px-3 leading-tight bg-white border border-gray-300 text-blue-700 border-r-0 ml-0 rounded-l hover:bg-gray-200"><a class="page-link" href="#">Previous</a></li>
          <li class="relative block py-2 px-3 leading-tight bg-white border border-gray-300 text-blue-700 border-r-0 hover:bg-gray-200"><a class="page-link" href="#">1</a></li>
          <li class="relative block py-2 px-3 leading-tight bg-white border border-gray-300 text-blue-700 border-r-0 hover:bg-gray-200"><a class="page-link" href="#">2</a></li>
          <li class="relative block py-2 px-3 leading-tight bg-white border border-gray-300 text-blue-700 border-r-0 hover:bg-gray-200"><a class="page-link" href="#">3</a></li>
          <li class="relative block py-2 px-3 leading-tight bg-white border border-gray-300 text-blue-700 rounded-r hover:bg-gray-200"><a class="page-link" href="#">Next</a></li>
        </ul>
      </div>
    </div>

    <IncidentCreate :showCreateInvestigationModal="showCreateInvestigationModal"
                    @close="showCreateInvestigationModal = false"></IncidentCreate>
    <transaction-modal
        :show-modal="showTransactionModal"
        @close="updateShowTransactionModal(false)"></transaction-modal>
  </div>
</template>

<script>
import {mapState} from "vuex";
import Filters from "@/components/Filters";
import IncidentCreate from "@/views/Incident/IncidentCreate";
import TransactionModal from "@/components/TransactionModal";

export default {
  name: "Incidents",
  components: {
    IncidentCreate,
    Filters,
    TransactionModal
  },
  data() {
    return {
      showCreateInvestigationModal: false,
      tableFilter: true,
      filterValue: null,
      filteredValues: [],
      filterOptions: {
        'hvhf': {
          option: 'High Value, High Frequency',
          values: [0, 4, 8, 13]
        },
        'mvhf': {
          option: 'Mid Value, High Frequency',
          values: [2, 6, 11, 14, 15, 16]
        },
        'lvhf': {
          option: 'Low Value, High Frequency',
          values: [1, 7, 9]
        },
        'hvlf': {
          option: 'High Value, Low Frequency',
          values: [17, 19]
        },
        'mvlf': {
          option: 'Mid Value, Low Frequency',
          values: [3, 5, 12, 18]
        },
        'lvlf': {
          option: 'Low Value, Low Frequency',
          values: [10]
        }
      }
    }
  },
  methods: {
    updateShowTransactionModal(val) {
      this.$store.commit('SET_SHOW_TRANSACTION_MODAL', val)
    },
    TableFilterButtonClass(filterTerm) {
      if (filterTerm === this.filterValue) {
        return "bg-blue-100 border-blue-800";
      }
      return "bg-white hover:bg-blue-200 hover:border-blue-800";
    },
    // This will be replaced by value based filtering once we have those.
    showRow(index) {
      // Check if the table is to be filtered
      if (this.tableFilter) {

        // Check if row index is in the table filter
        if (this.filteredValues.includes(index)) {
          return true;
        } else {
          return false;
        }
      }

      return true;

    },
    // An absolute hack in the absence of anything to really filter on.
    // This filter would work depending on what is a high value and what is a high frequency
    TableFilter(filterTerm) {

      // if the selected value is the same as the filtered value
      if (filterTerm === this.filterValue) {

        // reset and turn off the filter
        this.filterValue = null;
        this.filteredValues = [];
        this.tableFilter = true;
      } else {
        this.filterValue = filterTerm;
        this.filteredValues = this.filterOptions[filterTerm].values
        this.tableFilter = true;
      }
    },
    createInvestigation(incident) {
      if (incident.id === this.caseForReview.incident.id) {
        this.showCreateInvestigationModal = true
      } else {
        this.$store.dispatch('createCaseForReview', incident)
        this.showCreateInvestigationModal = true
      }
    },
    ProbabilityLevel(score) {
      if (score >= 0.9) {
        return "text-white bg-red-400"
      }
      if (score >= 0.6) {
        return "text-white bg-amber-400"
      }
      if (score >= 0.4) {
        return "text-white bg-green-400"
      }
      return "";
    },
  },

  computed: {
    ...mapState(['incidents', 'caseForReview', 'showTransactionModal'])
  }
}

</script>

<style scoped>

</style>
