<template>
  <div id="case-management-index">
    <h1 class="page-header">Case Management</h1>
    <div class="m-6 grid grid-cols-12 gap-6">
      <div class="lp-card col-span-12">
        <breadcrumbs />
      </div>
      <div class="lp-card col-span-12">
        <Filters></Filters>
      </div>

      <div class="shadow border-b border-gray-200 sm:rounded-lg col-span-12">
        <table class="lp-table">
          <thead>
          <tr>
            <th scope="col">Date</th>
            <th scope="col">Region</th>
            <th scope="col">Area</th>
            <th scope="col">Store</th>
            <th scope="col">Employee</th>
            <th scope="col">TX Type</th>
            <th scope="col">Probability</th>
            <th scope="col">Case Owner</th>
            <th scope="col">Status</th>
            <th scope="col">Follow-up Date</th>
            <th scope="col">Response Due Date</th>
            <th scope="col">View Notes</th>
          </tr>
          </thead>
          <tbody>
          <tr v-for="(caseFile, index) in caseFiles" :key="index" :caseFile="caseFile">
            <td>{{ caseFile.incident.date }}</td>
            <td>{{ caseFile.incident.region }}</td>
            <td>{{ caseFile.incident.area }}</td>
            <td>{{ caseFile.incident.store }}</td>
            <td>{{ caseFile.incident.employee }}</td>
            <td>{{ caseFile.incident.txType }}</td>
            <td>
              <span :class="ProbabilityLevel(caseFile.incident.probability)"
                    class="px-3 py-1 inline-flex text-xs leading-5 font-semibold rounded-full bg-red-100 text-red-800">
                  {{ caseFile.incident.probability * 100 }}%
                </span>
            </td>
            <td>{{ caseFile.caseOwner }}</td>
            <td>{{ caseFile.status }}</td>
            <td>{{ caseFile.followUpDate }}</td>
            <td>{{ caseFile.responseDueDate }}</td>
            <td>
              <router-link class="inline-flex font-semibold justify-center w-full rounded-md border border-transparent px-4 py-2 bg-blue-600 text-base leading-6 font-medium text-white shadow-sm hover:bg-blue-500 transition ease-in-out duration-150 sm:text-sm sm:leading-5"
                  :to="/case-management/ + index" >View</router-link>
            </td>
          </tr>
          </tbody>
        </table>
      </div>
    </div>
</div>
</template>

<script>
import Filters from "@/components/Filters";
import {mapState} from "vuex";

export default {
  name: "CaseManagementIndex",
  components: {
    Filters
  },
  data() {
    return {
      showCaseManagementIndexModal: false,
      caseFileIndex: null,
    }
  },
  methods: {
    ProbabilityLevel(score) {
      if (score >= 0.9) {
        return "bg-red-200 text-red-900"
      }
      if (score >= 0.6) {
        return "bg-orange-200 text-orange-900"
      }
      if (score >= 0.4) {
        return "bg-green-200 text-green-900"
      }
      return "";
    }
  },
  computed: {
    ...
        mapState(['caseFiles', 'caseFile'])
  }
}
</script>

<style scoped>

</style>
