<template>
  <Modal @close="$emit('close')" @action="$emit('close')"
         v-if="showModal">
    <template #title>
      Transaction Report
    </template>
    <template #content>
      <div id="case-management-index">
        <div class="m-6 grid grid-cols-12 gap-6 max-w-7xl">
          <div class="col-span-4 flex-col">
            <div class="w-full lp-card">
              <div class="grid grid-cols-12 text-base">
                <div class="col-span-12 text-center mb-4 font-semibold text-base">
                  SHOP 1<br>
                  23-26 Charles St<br>
                  TEL: 02890 436633
                </div>
                <div class="col-span-6 mb-1">26/10/2020 11:35</div>
                <div class="col-span-6 text-right mb-1">Order # 004728</div>
                <div class="col-span-12 text-center">Your Store Manager is Benjamin James</div>
                <div class="col-span-12 text-center mb-2">You were served today by Charles</div>


                <div class="col-span-7 font-bold">Product</div>
                <div class="col-span-3 text-center font-bold">Quantity</div>
                <div class="col-span-2 text-right font-bold">£ GBP</div>

                <div class="col-span-7">Penne Pasta</div>
                <div class="col-span-3 text-center">1</div>
                <div class="col-span-2 text-right">1.00</div>

                <div class="col-span-7">Red Wine</div>
                <div class="col-span-3 text-center">2</div>
                <div class="col-span-2 text-right">4.99</div>

                <div class="col-span-7">Tikka Massala</div>
                <div class="col-span-3 text-center">2</div>
                <div class="col-span-2 text-right">1.50</div>

                <div class="col-span-7">LRG Free Rng Eggs</div>
                <div class="col-span-3 text-center">1</div>
                <div class="col-span-2 text-right">1.00</div>

                <div class="col-span-7">Smoked Kipper</div>
                <div class="col-span-3 text-center">1</div>
                <div class="col-span-2 text-right">2.50</div>

                <hr class="my-2 col-span-12">

                <div class="col-span-7">Sub Total</div>
                <div class="col-span-3 text-center"></div>
                <div class="col-span-2 text-right">17.48</div>


                <div class="col-span-7 font-bold">Total</div>
                <div class="col-span-3 text-center font-bold">Cash</div>
                <div class="col-span-2 text-right font-bold">17.48</div>

                <div class="col-span-7">Total Tendered</div>
                <div class="col-span-3 text-center">Cash</div>
                <div class="col-span-2 text-right">20.00</div>

                <div class="col-span-7 mb-4 font-bold">Change</div>
                <div class="col-span-3 mb-4 text-center font-bold">Cash</div>
                <div class="col-span-2 mb-4 text-right font-bold">2.52</div>

                <div class="col-span-6 mb-2">26/10/2020 11:35</div>
                <div class="col-span-6 text-right mb-2">Order # 004728</div>
                <div class="col-span-12">
                  <img class="h-20 mx-auto w-full" src="@/assets/img/img.png" alt="">
                </div>
                <div class="col-span-12 text-center">Vat no: GB 251996821</div>
                <div class="col-span-12 text-center">Thank you for your custom</div>
              </div>
            </div>
          </div>
          <div class="col-span-8">
            <video class="w-full" controls>
              <source src="@/assets/img/cctv-theft.mp4" type="video/mp4">
              Your browser does not support the video tag.
            </video>
          </div>
        </div>
      </div>
    </template>
    <template #action>
      Save
    </template>
  </Modal>

</template>

<script>
import Modal from "@/components/Modal";
import {mapGetters, mapState} from "vuex";

export default {
  name: "TransactionModal",
  components: {
    Modal,
  },
  props: [
    'showModal',
  ],
  data() {
    return {
      receiptNumber: '20201026-1233-004728',
    }
  },
  computed: {
    ...mapState(['dashFilter', 'caseFiles', 'caseFile', 'investigationTypes']),
    ...mapGetters(['sites']),
  },
  methods: {
    saveProcessManagement() {
      this.$emit('close')
    },
    toggleProcessCheck(processIndex) {
      this.$store.commit('TOGGLE_PROCESS_MANAGEMENT_CHECK', processIndex)
    },
    updateProcessManagementNote(val, processIndex) {
      let value = val.target.value
      this.$store.commit('UPDATE_PROCESS_MANAGEMENT_NOTE', {processIndex, value})
    }
  }
}
</script>

<style scoped>

</style>
