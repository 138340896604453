<template>
  <div id="case-management-show">
    <h1 class="page-header">Case File</h1>
    <div class="grid grid-cols-12 gap-6 m-6">
      <div class="lp-card col-span-12">
        <breadcrumbs />
      </div>
      <div class="col-span-3">
        <label class="block text-sm font-medium text-gray-700">Case Owner</label>
        <input type="text" :value="caseFile.caseOwner" @input="updateCaseFileOwner"
               class="shadow-sm mt-1 block w-full sm:text-sm border border-gray-300 rounded-md"/>
      </div>
      <div class="col-span-3">
        <label class="block text-sm font-medium text-gray-700 mb-1">
          Status
        </label>
        <v-select :options="statuses" :value="caseFile.status"
                  @input="updateCaseFileStatus"></v-select>
      </div>
      <div class="col-span-3">
        <label class="block text-sm font-medium text-gray-700">
          Follow Up
        </label>
        <input type="date" :value="caseFile.followUpDate" @change="updateCaseFileFollowUpDate"
               class="shadow-sm mt-1 block w-full sm:text-sm border border-gray-300 rounded-md"/>
      </div>
      <div class="col-span-3">
        <label class="block text-sm font-medium text-gray-700">
          Response Due
        </label>
        <input type="date" :value="caseFile.responseDueDate" @change="updateCaseFileResponseDueDate"
               class="shadow-sm mt-1 block w-full sm:text-sm border border-gray-300 rounded-md"/>
      </div>

      <div class="shadow rounded-t-lg col-span-12 -mb-6">
        <table class="lp-table text-left table-fixed w-full">
          <thead>
          <tr>
            <th scope="col" class="w-1/6">Author</th>
            <th scope="col" class="w-5/6">Note</th>
          </tr>
          </thead>
        </table>
      </div>
      <div class="shadow rounded-b-lg col-span-12 overflow-auto max-h-96">
        <table class="lp-table text-left table-fixed w-full">
          <tbody>
          <tr v-for="(note, index) in caseFile.notes.slice().reverse()" :key="index">
            <td class="align-top text-gray-800 w-1/6">
              <div class="p-2 my-4">
                <span class="font-bold">{{ note.author }}</span><br/>
                {{ convertDT(note.dateTime) }}
              </div>

            </td>
            <td class="text-gray-800 w-5/6">
              <div class="bg-lightBlue-200 -ml-2 p-4 my-2 rounded-lg">
                {{ note.note }}
              </div>
            </td>
          </tr>
          </tbody>
        </table>

      </div>

      <div class="col-span-12">
        <label class="block text-sm font-medium text-gray-700">Case Notes</label>
        <textarea rows=9 v-model="note"
                  class="shadow-sm mt-1 block w-full sm:text-sm border border-gray-300 rounded-md"></textarea>
      </div>
      <div class="col-span-12 mb-6 text-right">
        <button
            class="mr-6 inline-flex font-semibold justify-center rounded-md border-2 px-4 py-2 border-gray-200 text-base leading-6 font-medium text-gray-600 shadow-sm hover:bg-gray-200 transition ease-in-out duration-150 sm:text-sm sm:leading-5">
          Upload Documents
        </button>
        <button @click="showTransactionModal = true"
                class="mr-6 inline-flex font-semibold justify-center rounded-md border-2 px-4 py-2 border-blue-200 text-base leading-6 font-medium text-blue-600 shadow-sm hover:bg-blue-200 transition ease-in-out duration-150 sm:text-sm sm:leading-5">
          View Transaction
        </button><button @click="showProcessManagementModal = true"
                class="mr-6 inline-flex font-semibold justify-center rounded-md border-2 px-4 py-2 border-blue-200 text-base leading-6 font-medium text-blue-600 shadow-sm hover:bg-blue-200 transition ease-in-out duration-150 sm:text-sm sm:leading-5">
          Process Management
        </button>
        <button @click="saveCaseFile()"
                class="inline-flex font-semibold justify-center rounded-md border-2 border-transparent px-4 py-2 bg-blue-600 text-base leading-6 font-medium text-white shadow-sm hover:bg-blue-500 transition ease-in-out duration-150 sm:text-sm sm:leading-5">
          Save
        </button>
      </div>
    </div>
    <ProcessManagementShow :caseFileIndex="this.$route.params.index"
                           :showProcessManagementModal="showProcessManagementModal"
                           @close="showProcessManagementModal = false"></ProcessManagementShow>
    <TransactionModal :showModal="showTransactionModal"
        @close="showTransactionModal = false"></TransactionModal>
  </div>
</template>

<script>
import {mapState} from "vuex";
import ProcessManagementShow from "@/views/CaseManagement/ProcessManagementShow";
import TransactionModal from "@/components/TransactionModal";

export default {
  name: "CaseManagementShow",
  components: {ProcessManagementShow, TransactionModal},
  data() {
    return {
      index: 0,
      dateTimeNow: new Date().toISOString(),
      note: '',
      showProcessManagementModal: false,
      showTransactionModal: false
    }
  },
  computed: {
    ...mapState(['investigationTypes', 'caseInvestigatorLevels', 'statuses', 'caseFile']),
  },
  created() {
    this.index = this.$route.params.index
    this.$store.commit('SET_CASE_FILE', this.index)
    this.caseFileIndex = this.index
  },
  methods: {
    convertDT(dateTime) {
      return new Date(dateTime).toUTCString()
    },
    saveCaseFile() {
      this.$store.commit('ADD_CASE_FILE_NOTE', {index: this.caseFileIndex, note: this.note})
      this.note = ''
    },
    updateCaseStatus(val) {
      console.log(val, this.caseFileIndex)
    },
    updateCaseFileOwner(val) {
      this.$store.commit('UPDATE_CASE_FILE_OWNER', val.target.value)
    },
    updateCaseFileStatus(val) {
      this.$store.commit('UPDATE_CASE_FILE_STATUS', val)
    },
    updateCaseFileFollowUpDate(val) {
      this.$store.commit('UPDATE_CASE_FILE_FOLLOW_UP_DATE', val.target.value)
    },
    updateCaseFileResponseDueDate(val) {
      this.$store.commit('UPDATE_CASE_FILE_RESPONSE_DUE_DATE', val.target.value)
    },
  }
}
</script>

<style scoped>

</style>
