<template>
  <Modal @close="$emit('close')" @action="saveProcessManagement()"
         v-if="showProcessManagementModal">
    <template #title>
      Process Management
    </template>
    <template #content>
      <div class="min-w-5xl max-w-5xl">

        <div class="grid grid-cols-3 mb-6">
          <div class="col-span-1">
            <label class="block text-sm font-medium text-gray-700 mb-1">
              Investigation Type
            </label>
            <v-select :options="investigationTypes" :value="caseFile.investigationType"></v-select>
          </div>
        </div>

        <table class="lp-table text-left table-fixed w-1/2">
          <thead>
          <tr>
            <th scope="col" class="w-1/12 text-center">Stage</th>
            <th scope="col" class="w-3/12">Description</th>
            <th scope="col" class="w-2/12 text-center">Complete?</th>
            <th scope="col" class="w-6/12">Notes</th>
          </tr>
          </thead>
          <tbody class="text-gray-800">
          <tr v-for="(process, index) in caseFiles[caseFileIndex].processManagement" :key="process.title">
            <td class="text-center">{{ index + 1 }}</td>
            <td class="font-semibold">{{ process.title }}</td>
            <td class="">
              <svg @click="toggleProcessCheck(index)" v-if="process.checked"
                   class="block mx-auto h-8 fill-current text-green-600" xmlns="http://www.w3.org/2000/svg"
                   viewBox="0 0 20 20" fill="currentColor">
                <path fill-rule="evenodd"
                      d="M10 18a8 8 0 100-16 8 8 0 000 16zm3.707-9.293a1 1 0 00-1.414-1.414L9 10.586 7.707 9.293a1 1 0 00-1.414 1.414l2 2a1 1 0 001.414 0l4-4z"
                      clip-rule="evenodd"/>
              </svg>
              <svg @click="toggleProcessCheck(index)" v-if="!process.checked"
                   class="block mx-auto h-8 fill-current text-red-600" xmlns="http://www.w3.org/2000/svg"
                   viewBox="0 0 20 20" fill="currentColor">
                <path fill-rule="evenodd"
                      d="M10 18a8 8 0 100-16 8 8 0 000 16zM8.707 7.293a1 1 0 00-1.414 1.414L8.586 10l-1.293 1.293a1 1 0 101.414 1.414L10 11.414l1.293 1.293a1 1 0 001.414-1.414L11.414 10l1.293-1.293a1 1 0 00-1.414-1.414L10 8.586 8.707 7.293z"
                      clip-rule="evenodd"/>
              </svg>
            </td>
            <td class="p-0">
              <input type="text" :value="process.note" @input="updateProcessManagementNote($event, index)"
                     class="h-full block w-full border-0 text-sm"/>
            </td>
          </tr>
          </tbody>
        </table>

      </div>
    </template>
    <template #action>
      Save
    </template>
  </Modal>
</template>

<script>
import Modal from "@/components/Modal";
import {mapState} from "vuex";

export default {
  name: "ProcessManagementShow",
  components: {
    Modal,
  },
  props: [
    'showProcessManagementModal',
    'caseFileIndex'
  ],
  computed: {
    ...mapState(['caseFiles', 'caseFile', 'investigationTypes']),
  },
  methods: {
    saveProcessManagement() {
      this.$emit('close')
    },
    toggleProcessCheck(processIndex) {
      this.$store.commit('TOGGLE_PROCESS_MANAGEMENT_CHECK', processIndex)
    },
    updateProcessManagementNote(val, processIndex) {
      let value = val.target.value
      this.$store.commit('UPDATE_PROCESS_MANAGEMENT_NOTE', {processIndex, value})
    }
  }

}
</script>

<style scoped>

</style>
