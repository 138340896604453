import Vue from "vue";
import App from "./App.vue";
import router from "./router";
import store from "./store";
import "./assets/tailwind.css";

// Vue Select
import vSelect from "vue-select";
Vue.component("v-select", vSelect);
import "vue-select/src/scss/vue-select.scss";

import VCalendar from "v-calendar";
Vue.use(VCalendar);

import VueBreadcrumbs from 'vue-2-breadcrumbs';
Vue.use(VueBreadcrumbs);

Vue.config.productionTip = true;

new Vue({
  router,
  store,
  render: h => h(App)
}).$mount("#app");



