<template>
  <div id="dashboard">
    <h1 class="page-header">Performance Management</h1>
    <div class="m-6 grid grid-cols-12 gap-6">
      <div class="lp-card col-span-12">
        <Filters></Filters>
      </div>
      <div class="lp-card col-span-3">
        <BarChart title="Scan Speed (Items per minute)" :chart-data="scanSpeed"/>
      </div>
      <div class="lp-card col-span-3">
        <BarChart title="Line Voids (% of total lines)" :chart-data="lineVoids"/>
      </div>
      <div class="lp-card col-span-3">
        <BarChart title="Receipt Voids (% of total sales)" :chart-data="receiptVoids"/>
      </div>
      <div class="lp-card col-span-3">
        <BarChart title="Total Refund Value (£ GBP)" :chart-data="refundValue"/>
      </div>
      <div class="lp-card col-span-4">
        <h2 class="block font-medium text-gray-700">Transaction Count</h2>
        <div class="-mr-6">
          <GChart
              type="LineChart"
              :data="transactionCount"
              :options="chartOptions"
              :resizeDebounce="1"
          ></GChart>
        </div>
      </div>
      <div class="lp-card col-span-4">
        <ScatterChart title="Boston Matrix" :chart-data="refundValueVsTotalValue"/>
        <div class="mt-4 flex text-xs">
          <v-select class="w-1/2 mr-1" :options="BostonOptions" v-model="BostonX"></v-select>
          <v-select class="w-1/2" :options="BostonOptions" v-model="BostonY"></v-select>

        </div>
        <!--
        <div class="w-full grid grid-cols-2 gap-6">
          <div class="col-span-1">
            <label class="mr-1 text-sm text-gray-700">X Axis</label>

          </div>
          <div class="col-span-1">Y Axis</div>
        </div> -->
      </div>
      <div class="lp-card col-span-4 relative">
        <div class="">
          <h2 class="block font-medium text-gray-700 mb-1">Areas Going Well:</h2>
          <div class="flex mb-3 flex-wrap">
            <button type="button"
                    class="font-semibold m-1 justify-center rounded-md border border-transparent px-2 py-1 bg-green-600 text-base leading-6 font-medium text-green-100 shadow-sm hover:bg-green-700 transition ease-in-out duration-150 sm:text-sm sm:leading-5">
              Line Voids
            </button>
            <button type="button"
                    class="font-semibold m-1 justify-center rounded-md border border-transparent px-2 py-1 bg-green-600 text-base leading-6 font-medium text-green-100 shadow-sm hover:bg-green-700 transition ease-in-out duration-150 sm:text-sm sm:leading-5">
              Last Line Voids
            </button>
            <button type="button"
                    class="font-semibold m-1 justify-center rounded-md border border-transparent px-2 py-1 bg-green-600 text-base leading-6 font-medium text-green-100 shadow-sm hover:bg-green-700 transition ease-in-out duration-150 sm:text-sm sm:leading-5">
              Active Case Count
            </button>
          </div>
        </div>
        <div class="">
          <h2 class="block font-medium text-gray-700 mb-1">Areas to keep an eye on:</h2>
          <div class="flex mb-3 flex-wrap">
            <button type="button"
                    class="font-semibold m-1 justify-center rounded-md border border-transparent px-2 py-1 bg-orange-600 text-base leading-6 font-medium text-orange-100 shadow-sm hover:bg-orange-700 transition ease-in-out duration-150 sm:text-sm sm:leading-5">
              Scan Speed
            </button>
            <button type="button"
                    class="font-semibold m-1 justify-center rounded-md border border-transparent px-2 py-1 bg-orange-600 text-base leading-6 font-medium text-orange-100 shadow-sm hover:bg-orange-700 transition ease-in-out duration-150 sm:text-sm sm:leading-5">
              Refund Quantity
            </button>
          </div>
        </div>
        <div class="">
          <h2 class="block font-medium text-gray-700 mb-1">Areas to focus on:</h2>
          <div class="flex mb-3 flex-wrap">
            <button type="button"
                    class="font-semibold m-1 justify-center rounded-md border border-transparent px-2 py-1 bg-red-600 text-base leading-6 font-medium text-red-100 shadow-sm hover:bg-red-700 transition ease-in-out duration-150 sm:text-sm sm:leading-5">
              Refund Value
            </button>
            <button type="button"
                    class="font-semibold m-1 justify-center rounded-md border border-transparent px-2 py-1 bg-red-600 text-base leading-6 font-medium text-red-100 shadow-sm hover:bg-red-700 transition ease-in-out duration-150 sm:text-sm sm:leading-5">
              LV Staff Sale
            </button>
          </div>
        </div>
        <!-- <p class="text-3xl font-bold text-center absolute top-1/2 left-1/2 -mr-1/2 transform -translate-x-1/2 -translate-y-1/2">
          Metric Focus Points</p> -->
      </div>
    </div>
  </div>
</template>

<script>
// @ is an alias to /src

import {GChart} from "vue-google-charts";
import BarChart from "@/components/BarChart";
import ScatterChart from "@/components/ScatterChart";
import Filters from "@/components/Filters";
import {mapGetters, mapState} from "vuex";

export default {
  name: "Home",
  components: {
    BarChart,
    ScatterChart,
    Filters,
    GChart
  },
  data() {
    return {
      chartOptions: {
        chart: {
          width: "100%"
        },
        vAxis: {minValue: 4000},
        legend: "none",
        chartArea: {width: "75%"}
      },
      BostonOptions: [
        'Scan Speed',
        'Line Void Rate',
        'Receipt Void Rate',
        'Lines Voided',
        'Receipts Voided',
        'Total Refund Value',
        'Total Sale Value',
      ],
      BostonX: 'Total Sale Value',
      BostonY: 'Total Refund Value',
    }
  },
  computed: {
    ...
        mapState(['dashFilter', 'dash']),
    ...
        mapGetters(['scanSpeed', 'lineVoids', 'receiptVoids', 'refundValue', 'filteredDash', "filteredCount", 'transactionCount', 'refundValueVsTotalValue']),

  }
}
;
</script>
