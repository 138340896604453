<template>
  <div id="app">
    <div class="flex min-h-screen">
      <div class="flex-none bg-blue-900 text-white shadow-2xl">
        <div class="h-14 pl-4 pr-6">
          <router-link class="py-3 block tracking-wider" to="/">
            <img src="@/assets/img/tmac-master.png" alt="TMAC Logo" class="h-12 inline">
            <span v-if="fullNavigation" class="text-lg font-bold ml-3">Loss Prevention</span>
          </router-link>
        </div>
        <nav id="nav">
          <router-link class="nav-menu-link" to="/">
            <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke="currentColor"
                 class="nav-icon">
              <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2"
                    d="M3 12l2-2m0 0l7-7 7 7M5 10v10a1 1 0 001 1h3m10-11l2 2m-2-2v10a1 1 0 01-1 1h-3m-6 0a1 1 0 001-1v-4a1 1 0 011-1h2a1 1 0 011 1v4a1 1 0 001 1m-6 0h6"/>
            </svg>
            <span v-if="fullNavigation" class="nav-menu-text">Performance Management</span>
          </router-link>
          <router-link class="nav-menu-link" to="/incidents">
            <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke="currentColor"
                 class="nav-icon">
              <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2"
                    d="M12 9v2m0 4h.01m-6.938 4h13.856c1.54 0 2.502-1.667 1.732-3L13.732 4c-.77-1.333-2.694-1.333-3.464 0L3.34 16c-.77 1.333.192 3 1.732 3z"/>
            </svg>
            <span v-if="fullNavigation" class="nav-menu-text">Incident Overview</span>
          </router-link>
          <router-link class="nav-menu-link" to="/case-management">
            <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke="currentColor"
                 class="nav-icon">
              <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2"
                    d="M21 13.255A23.931 23.931 0 0112 15c-3.183 0-6.22-.62-9-1.745M16 6V4a2 2 0 00-2-2h-4a2 2 0 00-2 2v2m4 6h.01M5 20h14a2 2 0 002-2V8a2 2 0 00-2-2H5a2 2 0 00-2 2v10a2 2 0 002 2z"/>
            </svg>
            <span v-if="fullNavigation" class="nav-menu-text">Case Management</span>
          </router-link>
          <router-link class="nav-menu-link" to="/transaction-report">
            <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke="currentColor">
              <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2"
                    d="M9 14l6-6m-5.5.5h.01m4.99 5h.01M19 21V5a2 2 0 00-2-2H7a2 2 0 00-2 2v16l3.5-2 3.5 2 3.5-2 3.5 2zM10 8.5a.5.5 0 11-1 0 .5.5 0 011 0zm5 5a.5.5 0 11-1 0 .5.5 0 011 0z"/>
            </svg>
            <span v-if="fullNavigation">Transaction Reports</span>
          </router-link>
          <router-link class="nav-menu-link-disabled" to="/exceptions">
            <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke="currentColor">
              <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2"
                    d="M13.875 18.825A10.05 10.05 0 0112 19c-4.478 0-8.268-2.943-9.543-7a9.97 9.97 0 011.563-3.029m5.858.908a3 3 0 114.243 4.243M9.878 9.878l4.242 4.242M9.88 9.88l-3.29-3.29m7.532 7.532l3.29 3.29M3 3l3.59 3.59m0 0A9.953 9.953 0 0112 5c4.478 0 8.268 2.943 9.543 7a10.025 10.025 0 01-4.132 5.411m0 0L21 21"/>
            </svg>
            <span v-if="fullNavigation">Exception Rules</span>
          </router-link>
          <router-link class="nav-menu-link-disabled" to="/audits">
            <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke="currentColor">
              <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2"
                    d="M10 21h7a2 2 0 002-2V9.414a1 1 0 00-.293-.707l-5.414-5.414A1 1 0 0012.586 3H7a2 2 0 00-2 2v11m0 5l4.879-4.879m0 0a3 3 0 104.243-4.242 3 3 0 00-4.243 4.242z"/>
            </svg>
            <span v-if="fullNavigation">Audits</span>
          </router-link>
          <router-link class="nav-menu-link-disabled" to="/accidents">
            <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke="currentColor">
              <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2"
                    d="M13 17h8m0 0V9m0 8l-8-8-4 4-6-6"/>
            </svg>
            <span v-if="fullNavigation">Accident Cases</span>
          </router-link>
          <router-link class="nav-menu-link-disabled" to="/training">
            <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke="currentColor">
              <path d="M12 14l9-5-9-5-9 5 9 5z"/>
              <path
                  d="M12 14l6.16-3.422a12.083 12.083 0 01.665 6.479A11.952 11.952 0 0012 20.055a11.952 11.952 0 00-6.824-2.998 12.078 12.078 0 01.665-6.479L12 14z"/>
              <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2"
                    d="M12 14l9-5-9-5-9 5 9 5zm0 0l6.16-3.422a12.083 12.083 0 01.665 6.479A11.952 11.952 0 0012 20.055a11.952 11.952 0 00-6.824-2.998 12.078 12.078 0 01.665-6.479L12 14zm-4 6v-7.5l4-2.222"/>

            </svg>
            <span v-if="fullNavigation">Training</span>
          </router-link>
          <router-link class="nav-menu-link-disabled" to="/policies">
            <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke="currentColor">
              <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2"
                    d="M9 12h6m-6 4h6m2 5H7a2 2 0 01-2-2V5a2 2 0 012-2h5.586a1 1 0 01.707.293l5.414 5.414a1 1 0 01.293.707V19a2 2 0 01-2 2z"/>
            </svg>
            <span v-if="fullNavigation">HR Policies</span>
          </router-link>

          <hr class="my-4"/>
          <router-link class="nav-menu-link-disabled" to="/admin">
            <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke="currentColor">
              <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2"
                    d="M8 14v3m4-3v3m4-3v3M3 21h18M3 10h18M3 7l9-4 9 4M4 10h16v11H4V10z"/>
            </svg>
            <span v-if="fullNavigation">Admin Panel</span>
          </router-link>
          <router-link class="nav-menu-link-disabled" to="/logout">
            <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke="currentColor">
              <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2"
                    d="M17 16l4-4m0 0l-4-4m4 4H7m6 4v1a3 3 0 01-3 3H6a3 3 0 01-3-3V7a3 3 0 013-3h4a3 3 0 013 3v1"/>
            </svg>
            <span v-if="fullNavigation">Logout</span>
          </router-link>
          <img v-if="fullNavigation" src="@/assets/img/tmac-long.png" alt="TMAC Logo" class="h-20 fixed bottom-0 mb-6">
        </nav>
      </div>
      <div class="w-full min-h-full bg-blueGray-100">
        <div class="header bg-white h-14 shadow-md">
          <div class="px-6 h-14 flex justify-between">
            <div class="my-auto">
              <button v-on:click="fullNavigation = !fullNavigation">
                <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke="currentColor"
                     class="inline h-6 mr-3" v-if="fullNavigation">
                  <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M15 19l-7-7 7-7"/>
                </svg>
                <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke="currentColor"
                     class="inline h-6 mr-3" v-if="!fullNavigation">
                  <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M9 5l7 7-7 7"/>
                </svg>
              </button>
            </div>
            <div class="my-auto text-blueGray-500">
              <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke="currentColor"
                   class="inline h-6 mr-6"
              >
                <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2"
                      d="M15 17h5l-1.405-1.405A2.032 2.032 0 0118 14.158V11a6.002 6.002 0 00-4-5.659V5a2 2 0 10-4 0v.341C7.67 6.165 6 8.388 6 11v3.159c0 .538-.214 1.055-.595 1.436L4 17h5m6 0v1a3 3 0 11-6 0v-1m6 0H9"
                />
              </svg>
              <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke="currentColor"
                   class="inline h-6 mr-6">
                <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2"
                      d="M10.325 4.317c.426-1.756 2.924-1.756 3.35 0a1.724 1.724 0 002.573 1.066c1.543-.94 3.31.826 2.37 2.37a1.724 1.724 0 001.065 2.572c1.756.426 1.756 2.924 0 3.35a1.724 1.724 0 00-1.066 2.573c.94 1.543-.826 3.31-2.37 2.37a1.724 1.724 0 00-2.572 1.065c-.426 1.756-2.924 1.756-3.35 0a1.724 1.724 0 00-2.573-1.066c-1.543.94-3.31-.826-2.37-2.37a1.724 1.724 0 00-1.065-2.572c-1.756-.426-1.756-2.924 0-3.35a1.724 1.724 0 001.066-2.573c-.94-1.543.826-3.31 2.37-2.37.996.608 2.296.07 2.572-1.065z"/>
                <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2"
                      d="M15 12a3 3 0 11-6 0 3 3 0 016 0z"/>
              </svg>
              <span class="font-semibold mr-1">Paul Banks</span>
              <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke="currentColor"
                   class="inline h-4 mr-3">
                <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M19 9l-7 7-7-7"/>
              </svg>
              <img src="@/assets/img/PaulBanks.png" alt="Paul Banks" class="inline h-10 mr-3"/>

            </div>
          </div>
        </div>
        <div class="my-4">
          <router-view/>
        </div>
        <!-- <div class="lp-card m-6">
          <a href="https://dribbble.com/shots/14113864-Blueberry-CRM-Dashboard-Template/attachments/5736167?mode=media">https://dribbble.com/shots/14113864-Blueberry-CRM-Dashboard-Template/attachments/5736167?mode=media</a><br/>
          <a href="https://dribbble.com/shots/14472838-Blueberry-CRM-Dashboard-Template-Interaction">https://dribbble.com/shots/14472838-Blueberry-CRM-Dashboard-Template-Interaction</a>
        </div> -->
      </div>
    </div>
  </div>
</template>

<script>

export default {
  name: "App",
  data() {
    return {
      fullNavigation: true
    };
  }
};
</script>

<style lang="scss">
#app {
  @apply h-screen text-sm;
}

.logo-icon {
  @apply h-10 inline;
}

#nav {
  @apply pl-6 pr-6 py-4;

  svg {
    @apply h-6 inline;
  }

  span {
    @apply ml-6;
  }

  a {
    @apply py-3 block tracking-wider text-base font-semibold;
  }

  .nav-menu-link {
    @apply text-white;

    &:hover {
      @apply text-teal-400;
    }

    &.router-link-exact-active {
      @apply text-teal-200;
    }
  }

  .nav-menu-link-disabled {
    @apply text-gray-400;
  }
}
</style>
